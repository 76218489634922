import { Box, Card, Typography, styled } from '@mui/material';
import PageTemplate from './PageTemplate';

export default function SoonAvaliable() {
  
  const pageTitle = "Soon Avaliable";
  return (
    <PageTemplate pageTitle={pageTitle}>
      <Container>
        <Typography variant="h2">Soon Avaliable</Typography>
        <Typography variant="h6">We're currently hard at work on an amazing new feature. Please stay tuned, check back periodically, and we'll notify you as soon as it's ready!</Typography>
      </Container>
    </PageTemplate>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));
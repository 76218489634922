import { styled, Box, Typography, Card, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Applicant, RecruitmentCvStatus } from '../../services/gptRecruiterService';
import ApplicantCard from './ApplicantCard';
import ApplicantCardActions from './ApplicantCardActions';
import SearchApplicantCard from './search/SearchApplicantCard';

interface ApplicantListProps {
  applicants: Array<Applicant> | undefined;
  isPending: boolean;
  params: {
    status: RecruitmentCvStatus | null;
    keywords: string[];
    daysSinceUpload: number | null;
    useSmartMatch: boolean;
  };
}

const ApplicantsList = ({ applicants, params, isPending }: ApplicantListProps) => {
  const { t } = useTranslation();

  if (isPending) {
    return (
      <StyledContent sx={{ height: '100%' }}>
        <CircularProgress sx={{ margin: 'auto' }} />
      </StyledContent>
    );
  }

  if (!applicants?.length)
    return (
      <StyledContent sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{t('recruitment.emptyApplicants')}</Typography>
      </StyledContent>
    );

  return (
    <StyledContent>
      {applicants?.map(
        ({
          id,
          applicantName,
          applicantSurname,
          applicantContact,
          answers,
          trinaryAnswers,
          status,
          processingStatus,
          score,
          keywords,
          daysSinceUpload,
        }) => {
          const contactData = applicantContact ? applicantContact.split(',').map((val) => val.trim()) : [];
          const name = `${applicantName} ${applicantSurname}`;
          const hasAnswers = answers?.length;
          const hasTrinaryAnswers = trinaryAnswers?.length;

          if ((!hasAnswers || !hasTrinaryAnswers) && processingStatus === 'Failed') {
            return (
              <StyledApplicantBox
                key={`applicant-box-${id}`}
                sx={{ placeContent: 'flex-start', flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box>
                    <Typography fontWeight={600}>{t('recruitment.notProcessedCv')}</Typography>
                    <Typography>
                      {t('identifier')} : {id}
                    </Typography>
                    <Typography>{t('recruitment.wrongButStillCanProcess')}</Typography>
                  </Box>
                  <ApplicantCardActions id={id} status={status} />
                </Box>
              </StyledApplicantBox>
            );
          }

          if (
            (!hasAnswers || !hasTrinaryAnswers) &&
            (processingStatus === 'Started' || processingStatus === 'Never' || processingStatus === 'Queued')
          ) {
            return (
              <StyledApplicantBox
                key={`applicant-box-${id}`}
                sx={{ placeContent: 'center', flexDirection: 'column', gap: 2 }}
              >
                <Typography fontWeight={600}>{t('recruitment.stillProcessing')}</Typography>
                <CircularProgress />
              </StyledApplicantBox>
            );
          }

          return (
            <SearchApplicantCard
              key={id}
              contactData={contactData}
              name={name}
              id={id}
              answers={answers}
              trinaryAnswers={trinaryAnswers}
              status={status}
              score={score}
              keywords={keywords}
              daysSinceUpload={daysSinceUpload}
              params={params}
            />
          );
        }
      )}
    </StyledContent>
  );
};

export default ApplicantsList;

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

const StyledApplicantBox = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '150px',
}));

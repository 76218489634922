import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

interface DataBoxProps {
    firstMessage: string | undefined;
    dateTime: string | undefined;
    identity: string | undefined;
}

const DataBox = ({ firstMessage, dateTime, identity }: DataBoxProps) => (
    <Stack spacing={1} style={{display: 'flex'}}>
        <Typography
            variant="subtitle2"
            component="span"
        >
            {dateTime ?? '-'}{' '}{identity ?? '-'}
        </Typography>
        <Typography sx={{
            fontSize: 20, 
            overflow: 'hidden',
            textOverflow: 'wrap',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
        }} gutterBottom >
            {firstMessage ?? '-'}
        </Typography>
    </Stack >
);

export default DataBox;

/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axiosInstance';

interface ChatIdentity {
    id: string;
    title: string;
    identity: string;
    temperature: number;
}

interface CreateChatIdentityResponse {
    chatIdentity: ChatIdentity;
  }


async function createChatIdentity(title: string, identity: string, temperature: number): Promise<CreateChatIdentityResponse> {
    try {
        const response = await axiosInstance.post('/Chat/identities', {
            title,
            identity,
            temperature,
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
}

async function updateChatIdentity(chatIdentityId: string, title: string, identity: string, temperature: number): Promise<null> {
    try {
        const response = await axiosInstance.put(`/Chat/identities/${chatIdentityId}`, {
            title,
            identity,
            temperature,
        });
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
}

async function deleteChatIdentity(chatIdentityId: string): Promise<null> {
    try {
        const response = await axiosInstance.delete(`/Chat/identities/${chatIdentityId}`);
        return response.data;
    } catch (error: any) {
        throw new Error(error);
    }
}


export default {
    createChatIdentity,
    updateChatIdentity,
    deleteChatIdentity
};
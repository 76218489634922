/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { OrganizationUsersFormValue } from '../components/organization-settings/UserMenagmentForm';
import axiosInstance from '../axiosInstance';
import { UserRoles } from './userService';
import { CompanyNameDetails, PositionDetails } from './blindCvService';

export interface OrganizationSettingsProps {
  blindCVSettings: BlindCVSettingsProps;
}
export interface BlindCVSettingsProps {
  blindLanguage: string;
  positionDetails: PositionDetails;
  companyNameDetails: CompanyNameDetails;
  includeInBlind: { name: boolean; lastname: boolean; email: boolean };
  padding: { top: number; sides: number; bottom: number };
}
export type User = {
  email: string;
  id: string;
  role: UserRoles;
};

export enum ImageType {
  Logo,
  Background,
}
export interface OrganizationUsersResponse {
  users: Array<User>;
}

async function getOrganizationUsers(): Promise<OrganizationUsersResponse> {
  try {
    const response = await axiosInstance.get(`/organization/users`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function addOrganizationUser({
  email,
  password,
  role,
}: {
  email: string;
  password: string;
  role: UserRoles;
}): Promise<User> {
  try {
    const response = await axiosInstance.post(`/organization/users`, {
      email,
      password,
      role,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function deleteOrganizationUser(id: string): Promise<null> {
  try {
    const response = await axiosInstance.delete(`/organization/users/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function editOrganizationUser(
  id: string,
  user: OrganizationUsersFormValue | Omit<OrganizationUsersFormValue, 'password'>
): Promise<null> {
  try {
    const response = await axiosInstance.put(`/organization/users/${id}`, { ...user });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getIsSubscriptionPaid(): Promise<any> {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axiosInstance.get('/organization/isSubscriptionPaid');
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

async function subscribe(accessPlanId: number): Promise<{ redirectUrl: string }> {
  const response = await axiosInstance.post(`/organization/subscribe`, { accessPlanId });
  return response.data;
}

async function subscribeFreeTrial(): Promise<{ redirectUrl: string }> {
  const response = await axiosInstance.post(`/organization/subscribe-free-trial`);
  return response.data;
}
async function setOrganizationBackground({ file, imageType }: { file: File; imageType: number }): Promise<void> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('imageType', imageType.toString());
  const headers = { 'Content-Type': 'multipart/form-data' };
  const response = await axiosInstance.post(`/organization/image`, formData, { headers });
  return response.data;
}

async function getOrganizationBackground(): Promise<any> {
  const response = await axiosInstance.get(`/organization/image`, {
    responseType: 'blob',
  });
  return response;
}

async function deleteOrganizationBackground(): Promise<void> {
  const response = await axiosInstance.delete(`/organization/image`);
  return response.data;
}

async function setOrganizationSettings({ blindCVSettings }: { blindCVSettings: BlindCVSettingsProps }): Promise<void> {
  const settings: OrganizationSettingsProps = {
    blindCVSettings,
  };
  const settingsAsString = JSON.stringify(settings);
  const response = await axiosInstance.post(`/organization/dynamic-options`, { dynamicOptions: settingsAsString });
  return response.data;
}

async function getOrganizationSettings(): Promise<string> {
  const response = await axiosInstance.get(`/organization/dynamic-options`);
  return response.data.dynamicOptions;
}

export default {
  setOrganizationSettings,
  getOrganizationSettings,
  setOrganizationBackground,
  getOrganizationBackground,
  deleteOrganizationBackground,
  getOrganizationUsers,
  addOrganizationUser,
  deleteOrganizationUser,
  editOrganizationUser,
  getIsSubscriptionPaid,
  subscribe,
  subscribeFreeTrial,
};

import { Navigate } from 'react-router-dom';

interface ProtectedProps {
  isLoggedIn: boolean;
  children: JSX.Element;
}

const Protected = ({ isLoggedIn, children }: ProtectedProps): JSX.Element => {
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default Protected;

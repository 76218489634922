import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { useContext } from 'react';
import { StyledNavItem, StyledNavItemIcon, StyledNavlabel } from './styles';
import { UserContext } from '../../contexts/userContext';

interface NavSectionProps {
  data: Array<NavConfig>;
}

export default function NavSection({ data = [], ...other }: NavSectionProps) {
  const { hasAccess } = useContext(UserContext);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <Box key={item.title}>
            {!item.verbs ? (
              <>
                {item.label && <StyledNavlabel>{item.label}</StyledNavlabel>}
                <NavItem key={item.title} item={item} />
              </>
            ) : (
              hasAccess(item.verbs) && (
                <>
                  {item.label && <StyledNavlabel>{item.label}</StyledNavlabel>}
                  <NavItem key={item.title} item={item} />
                </>
              )
            )}
          </Box>
        ))}
      </List>
    </Box>
  );
}

function NavItem({ item }: { item: NavConfig }) {
  const { title, path, icon } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
}

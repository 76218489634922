import { styled, Box, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BlindCvDataResponse,
  EducationAndQualification,
  KeyInfo,
  PreviousProject,
} from '../../../services/blindCvService';
import BlindTextSectionComponent from './edit/BlindTextSectionComponent';
import BlindEditEmploymentHistory from './edit/employment/BlindEditEmploymentHistory';
import BlindEditEducationHistory from './edit/education/BlindEditEducationHistory';
import BlindSkillsSectionComponent from './edit/skills/BlindSkillsSectionComponent';
import BlindKeyInfoSectionComponent from './edit/BlindKeyInfoSectionComponent';

interface CandidateBlindCvResultsFlexProps {
  blindCvData: BlindCvDataResponse | undefined;
  isPending?: boolean;
  isCompleted: boolean;
  updateBlindCvData: (updatedBlindCvData: BlindCvDataResponse) => Promise<void>;
}

const CandidateBlindCvResultsFlex = ({
  blindCvData,
  isPending,
  isCompleted,
  updateBlindCvData,
}: CandidateBlindCvResultsFlexProps) => {
  const { t } = useTranslation();

  if (!isCompleted && !isPending) {
    return <></>;
  }

  if (isPending) {
    return (
      <StyledContent sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{t('blindCvGenerator.pendingBlindCv')}</Typography>
        <CircularProgress sx={{ margin: 'auto' }} />
      </StyledContent>
    );
  }

  if (isPending === false && !blindCvData)
    return (
      <StyledContent sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{t('blindCvGenerator.blindCvGeneratorError')}</Typography>
      </StyledContent>
    );

  const updateBlindCvDescription = async (updatedText: string) => {
    if (!blindCvData) return;
    const updatedBlindCvData = { ...blindCvData, candidateDescription: updatedText } as BlindCvDataResponse;
    await updateBlindCvData(updatedBlindCvData);
  };

  const updateBlindCvExperience = async (updatedText: string) => {
    if (!blindCvData) return;
    const updatedBlindCvData = { ...blindCvData, experience: updatedText } as BlindCvDataResponse;
    await updateBlindCvData(updatedBlindCvData);
  };

  const updateBlindCvSkills = async (updatedValues: string[]) => {
    if (!blindCvData) return;
    const updatedBlindCvData = { ...blindCvData, keySkills: updatedValues } as BlindCvDataResponse;
    await updateBlindCvData(updatedBlindCvData);
  };

  const updateBlindCvLanguages = async (updatedValues: string[]) => {
    if (!blindCvData) return;
    const updatedBlindCvData = { ...blindCvData, languages: updatedValues } as BlindCvDataResponse;
    await updateBlindCvData(updatedBlindCvData);
  };

  const updateBlindCvEmployment = async (updatedValues: PreviousProject[]) => {
    if (!blindCvData) return;
    const updatedBlindCvData = { ...blindCvData, previousProjects: updatedValues } as BlindCvDataResponse;
    await updateBlindCvData(updatedBlindCvData);
  };

  const updateBlindCvEducation = async (updatedValues: EducationAndQualification[]) => {
    if (!blindCvData) return;
    const updatedBlindCvData = { ...blindCvData, educationAndQualification: updatedValues } as BlindCvDataResponse;
    await updateBlindCvData(updatedBlindCvData);
  };

  const updateBlindCvKeyInfo = async ({ name, lastName, email }: KeyInfo) => {
    if (!blindCvData) return;
    const updatedBlindCvData = {
      ...blindCvData,
      candidateName: name,
      candidateLastName: lastName,
      candidateEmail: email,
    } as BlindCvDataResponse;
    await updateBlindCvData(updatedBlindCvData);
  };

  return (
    <StyledContent>
      <BlindKeyInfoSectionComponent
        title={t('blindCvGenerator.blindSections.keyInfo')}
        copyText={`${blindCvData?.candidateName} ${blindCvData?.candidateLastName} ${blindCvData?.candidateEmail}`}
        name={blindCvData?.candidateName || ''}
        lastName={blindCvData?.candidateLastName || ''}
        email={blindCvData?.candidateEmail || ''}
        submitEdit={updateBlindCvKeyInfo}
      />
      <BlindTextSectionComponent
        title={t('blindCvGenerator.blindSections.candidateDescription')}
        copyText={blindCvData?.candidateDescription || ''}
        textToEdit={blindCvData?.candidateDescription || ''}
        submitEdit={updateBlindCvDescription}
      />
      <FlexContainer>
        <BlindEditEmploymentHistory
          title={t('blindCvGenerator.blindSections.previousProjects')}
          copyText={
            blindCvData?.previousProjects
              .map((project) => `${project.companyName}\n${project.time}\n${project.details}`)
              .join('\n\n') || ''
          }
          values={blindCvData?.previousProjects || []}
          submitEdit={updateBlindCvEmployment}
        />
        <BlindSkillsSectionComponent
          title={t('blindCvGenerator.blindSections.Languages')}
          copyText={blindCvData?.languages.join(', ') || ''}
          values={blindCvData?.languages || []}
          submitEdit={updateBlindCvLanguages}
        />
        <BlindSkillsSectionComponent
          title={t('blindCvGenerator.blindSections.keySkills')}
          copyText={blindCvData?.keySkills.join(', ') || ''}
          values={blindCvData?.keySkills || []}
          submitEdit={updateBlindCvSkills}
        />
        <BlindTextSectionComponent
          title={t('blindCvGenerator.blindSections.experience')}
          copyText={blindCvData?.experience || ''}
          textToEdit={blindCvData?.experience || ''}
          submitEdit={updateBlindCvExperience}
        />
        <BlindEditEducationHistory
          title={t('blindCvGenerator.blindSections.educationAndQualifications')}
          copyText={
            blindCvData?.educationAndQualification
              .map((education) => `${education.name}, ${education.detail}`)
              .join('\n') || ''
          }
          values={blindCvData?.educationAndQualification || []}
          submitEdit={updateBlindCvEducation}
        />
      </FlexContainer>
    </StyledContent>
  );
};

export default CandidateBlindCvResultsFlex;

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  padding: 0,
  flexDirection: 'column',
}));

const FlexContainer = styled(Box)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'stretch',
  flexWrap: 'nowrap',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    flex: '0 1 auto',
    alignContent: 'stretch',
  },
}));

/* eslint-disable no-unused-vars */
import { TextField, styled, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../iconify';
import { EducationAndQualification } from '../../../../../services/blindCvService';
import StyledModal from '../../../../modals/styled-modal';

const EditEducationHistoryItem = ({
  item,
  handleDeleteItem,
  handleEditItem,
}: {
  item: EducationAndQualification;
  handleDeleteItem: (question: EducationAndQualification) => void;
  handleEditItem: (question: EducationAndQualification, newItem: EducationAndQualification) => void;
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [confirmDeleteItem, setConfirmDeleteItem] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(item.name);
  const [newTime, setNewTime] = useState<string>(item.time);
  const [newDetails, setNewDetails] = useState<string>(item.detail);

  const newItem = {
    name: newName,
    time: newTime,
    detail: newDetails,
  } as EducationAndQualification;

  const { t } = useTranslation();

  return (
    <StyledBox>
      <StyledModal
        title={t('blindCvEdit.title')}
        onClose={() => setIsEditing(false)}
        onContinue={() => {
          handleEditItem(item, newItem);
          setIsEditing(false);
        }}
        open={isEditing}
        continueButtonText={t('save')}
        disableBackdropClick
      >
        <FlexList>
          <TextField
            label={t('blindCvEdit.education.name')}
            defaultValue={newName}
            onChange={(event) => setNewName(event.target.value)}
            fullWidth
            multiline
          />
          <TextField
            label={t('blindCvEdit.education.time')}
            defaultValue={newTime}
            onChange={(event) => setNewTime(event.target.value)}
            fullWidth
          />
          <TextField
            label={t('blindCvEdit.education.description')}
            defaultValue={newDetails}
            onChange={(event) => setNewDetails(event.target.value)}
            fullWidth
            multiline
          />
        </FlexList>
      </StyledModal>
      <StyledModal
        title={t('blindCvEdit.title')}
        onClose={() => setConfirmDeleteItem(false)}
        onContinue={() => {
          setConfirmDeleteItem(false);
          handleDeleteItem(item);
        }}
        open={confirmDeleteItem}
        continueButtonText={t('yes')}
        disableBackdropClick
      >
        <Typography width={'100%'} textAlign={'center'} fontWeight={600}>
          {t('blindCvEdit.deleteItemConfirmation')}
        </Typography>
      </StyledModal>
      <FlexList>
        <Typography variant="body1" style={{ fontWeight: 'bolder' }} component="div">
          {item.name}
        </Typography>
        <Typography variant="body2" style={{ fontWeight: 'lighter' }} component="div">
          {item.time}
        </Typography>
        <Typography variant="body2" component="div">
          {item.detail}
        </Typography>
      </FlexList>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Tooltip title={t('edit')}>
          <IconButton onClick={() => setIsEditing(true)}>
            <Iconify icon="material-symbols:edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('delete')}>
          <IconButton onClick={() => setConfirmDeleteItem(true)}>
            <Iconify icon="material-symbols:delete" />
          </IconButton>
        </Tooltip>
      </Box>
    </StyledBox>
  );
};

export default EditEducationHistoryItem;

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  // border: `1px solid ${theme.palette.grey[300]}`,
}));

const FlexList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
  width: '100%',
  padding: 0,
}));

import { Button, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { BlindCvListResponse, BlindCvStatus } from '../../../services/blindCvService';
import { fDate } from '../../../utils/formatTime'

interface RecruitmentsListElementProps {
    blind: BlindCvListResponse;
}

const BlindListElement = ({
    blind: { id, createdAt, status, insertedFileName, candidateName, language },
}: RecruitmentsListElementProps) => {
    const { t } = useTranslation();

    return (
        <TableRow>
            <TableCell>{candidateName}</TableCell>
            <TableCell>{insertedFileName}</TableCell>
            <TableCell>{language}</TableCell>
            <TableCell>{fDate(createdAt)}</TableCell>


            <TableCell align="right">
                <Button to={`/dashboard/gptrecruiter/blindCv/edit/${id}`} component={RouterLink}
                    variant={status === BlindCvStatus.Finished ? "outlined" : "contained"}
                    color="secondary"
                    size="small">
                    {t('blindCvList.view')}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default BlindListElement;

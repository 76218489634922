import { Box, Button, Card, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PageTemplate from './PageTemplate';
import contextService, { ContextByIdResponse, ContextType, IndexingStatus } from '../services/contextService';
import ContextFormEdit from '../components/context/ContextFormEdit';
import ScrapWebsite from '../components/context/ScrapWebsite';
import ContextDocuments from '../components/context/ContextDocuments';
import Iconify from '../components/iconify';
import useLongPolling from '../hooks/useLongPooling';

export default function Context() {
  const [context, setContext] = useState<ContextByIdResponse>();
  const { t } = useTranslation();
  const pageTitle = t('contexts.title');
  const { contextId } = useParams<{ contextId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const isDocumentType = context?.contextType === ContextType.Document;
  const isCompleted = context?.isIndexed === IndexingStatus.Indexed;

  const getContext = async () => {
    try {
      if (!contextId) return;
      const context = await contextService.getContextById({ id: contextId });
      setContext(context);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      throw new Error('Error fetching context');
    }
  };

  useLongPolling(getContext, 10000, isCompleted);

  useEffect(() => {
    getContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageTemplate pageTitle={pageTitle}>
      <>
        <Button
          component={RouterLink}
          to="/dashboard/contexts"
          variant="contained"
          sx={{ maxWidth: '150px', marginX: 3, marginLeft: 'auto' }}
        >
          <Iconify icon="bi:arrow-left" sx={{ marginRight: 1 }} />
          {t('back')}
        </Button>
        <Container>
          <ContextFormEdit
            contextType={context?.contextType}
            id={context?.id}
            isIndexed={context?.isIndexed}
            title={context?.title}
            description={context?.description}
            getContext={getContext}
          />
          <DocumentsAndScraperContainer>
            {isDocumentType ? (
              <ContextDocuments contextId={contextId} isCompleted={isCompleted} />
            ) : (
              <ScrapWebsite
                scraperStatus={context?.scraperStatus}
                contextId={context?.id}
                getContext={getContext}
                siteUrl={context?.siteUrl}
              />
            )}
          </DocumentsAndScraperContainer>
        </Container>
      </>
    </PageTemplate>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));

const DocumentsAndScraperContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
}));

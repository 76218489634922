import { useState, useEffect } from 'react';

type FetchFunction<T> = () => Promise<T>;

const useLongPolling = <T,>(fetchFunction: FetchFunction<T>, interval: number, isCompleted: boolean): T | null => {
  const [data, setData] = useState<T | null>(null);
  const [fetchingError, setFetchingError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isCompleted || fetchingError) {
          return;
        }

        const result: T = await fetchFunction();

        setData(result);
      } catch (error) {
        setFetchingError(true);
      }
    };

    const intervalId = setInterval(fetchData, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchFunction, interval, isCompleted, fetchingError]);

  return data;
};

export default useLongPolling;

import { styled, Card, Typography, Button, CircularProgress, useTheme, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../iconify';

interface PricingCardProps {
  accessPlanId: number;
  monthlyRateValue: number;
  name: string;
  features: Array<string>;
  handleSubscribe: (accessPlanId: number) => Promise<void>;
  index: number;
  translationKeys: Array<string>;
  adonType: string;
}

const PricingCard = ({
  accessPlanId,
  monthlyRateValue,
  name,
  features,
  handleSubscribe,
  index,
  translationKeys,
  adonType
}: PricingCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const monthShortcut = t('shortcuts.month');
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <StyledSubsciptionCard key={accessPlanId}>
      <Typography variant="h3">{`${monthlyRateValue} € /${monthShortcut}`}</Typography>
      <Typography variant="h4">{name}</Typography>
      <Typography variant="h4">{t(`pricingPlans.cards.${adonType}.${translationKeys[index]}.title`)}</Typography>
      {features.map((feature, index) => (
        <Box sx={{ textAlign: 'left', display: 'flex', alignItems: 'center' }} key={index}>
          <Iconify
            icon="ic:baseline-check-circle-outline"
            sx={{ marginRight: 1, color: theme.palette.primary.main }}
            width={25}
          />
          <Typography variant="subtitle1"> {feature}</Typography>
        </Box>
      ))}
      <Button
        variant="contained"
        fullWidth
        onClick={async () => {
          setShowSpinner(true);
          await handleSubscribe(accessPlanId);
          setShowSpinner(false);
        }}
        disabled={showSpinner}
        sx={{ marginTop: 'auto' }}
      >
        {t('getStarted')}
        {showSpinner && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
      </Button>
    </StyledSubsciptionCard>
  );
};

export default PricingCard;

const StyledSubsciptionCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(3),
  flex: 1,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

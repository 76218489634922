/* eslint-disable @typescript-eslint/no-explicit-any */

import { EnhanceEmailData } from '../pages/EmailEnhancer';
import axiosInstance from '../axiosInstance';
import { GenerateEmailData } from '../pages/EmailGenerator';

interface EnhanceEmailResponse {
  result: string;
}

async function enhanceEmail({ email, style, size, language }: EnhanceEmailData): Promise<EnhanceEmailResponse> {
  try {
    const response = await axiosInstance.post('/email/enhance', { email, style, size, language });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function generateEmail({
  previousEmails,
  userRequests,
  style,
  size,
  language,
}: GenerateEmailData): Promise<EnhanceEmailResponse> {
  try {
    const response = await axiosInstance.post(
      '/email/generate',
      { previousEmails, userRequests, style, size, language },
      {
        timeout: 240000,
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export default {
  enhanceEmail,
  generateEmail,
};

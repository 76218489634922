/* eslint-disable no-unused-vars */
import { styled, Typography, Box, Button, TextField, Tooltip, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormEvent, SetStateAction, useEffect, useState } from 'react';
import { Recruitment, RecruitmentStatus } from '../../services/gptRecruiterService';
import Scrollbar from '../scrollbar';
import Question from './Question';

interface RecruitmentQuestionsProps {
  questionType: number;
  recruitment: Recruitment | undefined;
  question: string;
  setQuestion: (value: SetStateAction<string>) => void;
  handleAddQuestion: (questionType: number) => Promise<void>;
  handleDeleteQuestion: (question: string, questionType: number) => Promise<void>;
  handleEditQuestion: (question: string, newQuestion: string, questionType: number) => Promise<void>;
}
export enum QuestionTypes {
  NormalQuestion,
  BinaryQuestion,
}

const RecruitmentQuestions = ({
  questionType,
  recruitment,
  question,
  setQuestion,
  handleAddQuestion,
  handleDeleteQuestion,
  handleEditQuestion,
}: RecruitmentQuestionsProps) => {
  const { t } = useTranslation();
  const [isQuestionaQuestion, setIsQuestionaQuestion] = useState<boolean | null>(null);
  const isAddingNewQuestionDisabled = recruitment?.status !== RecruitmentStatus.Opened;
  const allQuestions =
    questionType === QuestionTypes.NormalQuestion ? recruitment?.questions : recruitment?.binaryQuestions;
  useEffect(() => {
    if (question.length > 0) {
      const lastChar = question[question.length - 1];
      setIsQuestionaQuestion(lastChar === '?');
    }
  }, [question]);
  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleAddQuestion(questionType);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" textAlign={'center'}>
        {questionType === QuestionTypes.NormalQuestion ? t('recruitment.questions') : t('recruitment.binaryQuestions')}
      </Typography>
      <Typography textAlign={'center'}>
        {questionType === QuestionTypes.NormalQuestion
          ? t('recruitment.questionsSubtitle')
          : t('recruitment.binaryQuestionsSubtitle')}
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      {!isAddingNewQuestionDisabled && (
        <StyledForm onSubmit={onFormSubmit}>
          <TextField
            label={t('recruitment.addQuestion')}
            value={question}
            onChange={(event) => setQuestion(event.target.value)}
            fullWidth
          />
          {isQuestionaQuestion === false ? (
            <Tooltip title={t('recruitment.questionFormat')}>
              <Button disabled={!question.length} variant="contained" onClick={() => handleAddQuestion(questionType)}>
                {t('add')}
              </Button>
            </Tooltip>
          ) : (
            <Button disabled={!question.length} variant="contained" onClick={() => handleAddQuestion(questionType)}>
              {t('add')}
            </Button>
          )}
        </StyledForm>
      )}

      <Scrollbar sx={scrollbarStyles}>
        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
          {!allQuestions?.length && isAddingNewQuestionDisabled ? (
            <Typography variant="h6" textAlign={'center'}>
              {t('recruitment.noQuestions')}
            </Typography>
          ) : (
            allQuestions?.map((question, id) => (
              <Question
                questionType={questionType}
                handleDeleteQuestion={handleDeleteQuestion}
                handleEditQuestion={handleEditQuestion}
                question={question}
                key={id}
                isAddingNewQuestionDisabled={isAddingNewQuestionDisabled}
              />
            ))
          )}
        </Box>
      </Scrollbar>
    </Box>
  );
};

export default RecruitmentQuestions;

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(2),
  placeContent: 'flex-start',
  marginBottom: theme.spacing(3),
}));

const scrollbarStyles = {
  maxHeight: 300,
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
};

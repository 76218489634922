import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, Link, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import navConfig, { NavConfig } from './config';

const NAV_WIDTH = 280;

interface NavProps {
  openNav: boolean;
  onCloseNav: () => void;
  navData: Array<NavConfig>;
}

export default function Nav({ openNav, onCloseNav, navData }: NavProps) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
    >
      <>
        <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
          <Logo />
        </Box>

        <NavSection data={navData} />
        <Box sx={{ padding: 2, textAlign: 'center' }}>
          <StyledTypography>{t('contactUs')}</StyledTypography>
          <StyledTypography>
            <Link
              sx={{ cursor: 'pointer', fontWeight: 700 }}
              href={t('awakastInquiryEmail') ?? 'mailto://info@awakast.com'}
            >
              {t('awakastMail')}
            </Link>
          </StyledTypography>
          <StyledTypography>{t('footer')}</StyledTypography>
        </Box>
      </>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightRegular,
}));

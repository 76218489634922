import { styled, Box, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApplicantSearch } from '../../../services/gptRecruiterService';
import SearchApplicantCard from './SearchApplicantCard';

interface ApplicantListProps {
  applicants: Array<ApplicantSearch> | undefined;
  isPending: 'GeneratingSearchQuery' | 'Searching' | 'Done' | null;
}

const SearchResultApplicantsList = ({ applicants, isPending }: ApplicantListProps) => {
  const { t } = useTranslation();

  if (isPending && isPending !== 'Done') {
    const getAwaitingText = (awaitingStatus: string): string => {
      switch (awaitingStatus) {
        case 'GeneratingSearchQuery':
          return 'Analyzing search query';
        case 'Searching':
          return 'Searching';
        case 'Done':
          return 'Done';
        default:
          return 'Generating search query';
      }
    };
    return (
      <StyledContent sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{getAwaitingText(isPending)}</Typography>
        <CircularProgress sx={{ margin: 'auto' }} />
      </StyledContent>
    );
  }

  if (!applicants?.length)
    return (
      <StyledContent sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{t('recruitment.emptyApplicants')}</Typography>
      </StyledContent>
    );

  return (
    <StyledContent>
      {applicants?.map(
        ({
          cvDataId,
          applicantName,
          applicantSurname,
          applicantContact,
          answers,
          trinaryAnswers,
          status,
          score,
          keywords,
          daysSinceUpload,
        }) => {
          const contactData = applicantContact ? applicantContact.split(',').map((val) => val.trim()) : [];
          const name = `${applicantName} ${applicantSurname}`;

          return (
            <SearchApplicantCard
              key={cvDataId}
              contactData={contactData}
              name={name}
              id={cvDataId}
              answers={answers}
              trinaryAnswers={trinaryAnswers}
              status={status}
              score={score}
              keywords={keywords}
              daysSinceUpload={daysSinceUpload}
            />
          );
        }
      )}
    </StyledContent>
  );
};

export default SearchResultApplicantsList;

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

import { Box, Card, CircularProgress, Stack, Tooltip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import service, { RecruitmentProcessingStatus } from '../../services/gptRecruiterService';
import useLongPolling from '../../hooks/useLongPooling';
import Iconify from '../iconify';

export interface RecruitmentProcessingStatusProps {
    recruitmentId: string | undefined;
}

export default function RecruitmentProcessingStatusComponent({ recruitmentId }: RecruitmentProcessingStatusProps) {
    const { t } = useTranslation();
    const [processingStatus, setProcessingStatus] = useState<RecruitmentProcessingStatus>();

    const isCompleted = processingStatus != null // should ony be complited if all have only status "finished" and counter matches cvCount 
        && Object.values(processingStatus.embeddingStatusCounter).reduce((a, b) => a + b, 0) === processingStatus.cvCount && Object.keys(processingStatus.embeddingStatusCounter).every(key => key === "Finished")
        && Object.values(processingStatus.indexingStatusCounter).reduce((a, b) => a + b, 0) === processingStatus.cvCount && Object.keys(processingStatus.indexingStatusCounter).every(key => key === "Finished");

    const allUploaded = processingStatus != null
        && Object.values(processingStatus.embeddingStatusCounter).reduce((a, b) => a + b, 0) === processingStatus.cvCount
        && Object.values(processingStatus.indexingStatusCounter).reduce((a, b) => a + b, 0) === processingStatus.cvCount;

    const isInProgress = processingStatus != null
        && (
            Object.keys(processingStatus.embeddingStatusCounter).some(key => key === "Started" && processingStatus.embeddingStatusCounter[key] > 0)
            || Object.keys(processingStatus.indexingStatusCounter).some(key => key === "Started" && processingStatus.indexingStatusCounter[key] > 0)
        )

    const isFailed = processingStatus != null
        && (
            Object.keys(processingStatus.embeddingStatusCounter).some(key => !["Finished", "Started"].includes(key))
            || Object.keys(processingStatus.indexingStatusCounter).some(key => !["Finished", "Started"].includes(key))
        )

        // QUEUED STATUS KUUURWA!!!!
        // słowa kluczowe (nie) prawie nie działają po polsku - call center, konsultant. lipa!
        

    const getRecruitmentProcessingStatusFromService = async () => {
        try {
            if (!recruitmentId) return;
            const processingStatus = await service.getRecruitmentProcessingStatus({ recruitmentId });
            setProcessingStatus(processingStatus);
        } catch (error) {
            throw new Error('Error fetching recruitment processing status');
        }
    };

    useLongPolling(getRecruitmentProcessingStatusFromService, 10000, isCompleted);

    useEffect(() => {
        getRecruitmentProcessingStatusFromService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (isFailed) {
        return (
            <Tooltip title={
                <>
                    <p>{JSON.stringify(processingStatus)}</p>
                </>
            }>
                <Stack direction="row">
                    <p>{t('recruitmentProcessingStatus.failed')}</p>
                </Stack>
            </Tooltip>

        )
    }

    if (isInProgress) {
        return (
            <Tooltip title={
                <>
                    <p>{JSON.stringify(processingStatus)}</p>
                </>
            }>
                <Stack direction="row">
                    <p>{t('recruitmentProcessingStatus.inProgress')}</p>
                    <CircularProgressSpinner size={20} />
                </Stack>
            </Tooltip>
        )
    }

    if (isCompleted) {
        return (
            <Stack direction="row">
                <p>{t('recruitmentProcessingStatus.completed')}</p>
            </Stack>
        )
    }

    return (
        <>

        </>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: theme.spacing(5),
    padding: theme.spacing(3),
}));

const DocumentsAndScraperContainer = styled(Card)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
}));

const CircularProgressSpinner = styled(CircularProgress)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main
}));

const TickIcon = styled(Iconify)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main
}));

import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Crop, ReactCrop } from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid, IconButton, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { BlindSettingsContext } from '../../contexts/blindSettingsContext';
import 'react-image-crop/dist/ReactCrop.css';
import { mockedData } from './mocks';
import StyledModal from '../modals/styled-modal';
import organizationService, { BlindCVSettingsProps, ImageType } from '../../services/organizationService';
import { LanguageContext } from '../../contexts/languagesContext';
import { BlindCvPdfDoc } from '../recruitment/BlindCv/BlindCvPdf/BlindCvPdfDoc';
import BlindDetailsForm from './BlindDetailsForm';
import BlindInludeInForm from './BlindInludeInForm';
import BlindSpacesForm from './BlindSpacesForm';
import BlindBackgroundForm from './BlindBackgroundForm';
import Iconify from '../iconify';

const BlindCVSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    blindCVSettings,
    setBlindCVSettings,
    organizationBackground,
    setOrganizationBackground,
    getOrganizationBackground,
    getOrganizationSettings,
  } = useContext(BlindSettingsContext);
  const { languagesOptions } = useContext(LanguageContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isCropNeeded, setIsCropNeeded] = useState(false);
  const [preview, setPreview] = useState<string | null>(organizationBackground);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(organizationBackground);
  const [crop, setCrop] = useState<Crop | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { control, getValues } = useForm<BlindCVSettingsProps>({
    defaultValues: {
      blindLanguage: blindCVSettings.blindLanguage,
      positionDetails: blindCVSettings.positionDetails,
      companyNameDetails: blindCVSettings.companyNameDetails,
      includeInBlind: blindCVSettings.includeInBlind,
      padding: blindCVSettings.padding,
    },
  });
  const watchedValues = useWatch({ control });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result as string);
      const img = new Image();
      img.onload = () => {
        const aspectRatio = Number(img.width.toFixed(2)) / Number(img.height.toFixed(2));
        setIsCropNeeded(aspectRatio !== Number((1 / Math.sqrt(2)).toFixed(2)));
        setCroppedImageUrl(reader.result as string);
        handleSaveBackground(reader.result as string);
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result as string);
      const img = new Image();
      img.onload = () => {
        const aspectRatio = Number(img.width.toFixed(2)) / Number(img.height.toFixed(2));
        setCroppedImageUrl(reader.result as string);
        setIsCropNeeded(aspectRatio !== Number((1 / Math.sqrt(2)).toFixed(2)));
        handleSaveBackground(reader.result as string);
      };
      img.src = reader.result as string;
    };
    reader.readAsDataURL(file);
    e.target.value = '';
  };

  const handleCropComplete = (crop: Crop) => {
    if (!croppedImageUrl) return;
    const img = new Image();
    img.src = croppedImageUrl;
    img.onload = () => {
      const scaleX = img.naturalWidth / img.width;
      const scaleY = img.naturalHeight / img.height;
      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;
      const cropWidth = crop.width * scaleX;
      const cropHeight = crop.height * scaleY;
      const offscreenCanvas = document.createElement('canvas');
      offscreenCanvas.width = cropWidth;
      offscreenCanvas.height = cropHeight;
      const ctx = offscreenCanvas.getContext('2d');

      ctx?.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

      const dataUrl = offscreenCanvas.toDataURL('image/png');
      setCroppedImageUrl(dataUrl);
      setIsCropNeeded(false);
      handleSaveBackground(dataUrl);
    };
  };

  const handleOpenCropper = () => {
    setCroppedImageUrl(preview);
    setIsModalOpen(true);
  };

  const handleSaveCrop = () => {
    if (crop) handleCropComplete(crop);
    setIsModalOpen(false);
  };

  const handleSaveBackground = async (dataUrl: string) => {
    if (!dataUrl) return;
    setOrganizationBackground(dataUrl);
    const base64Data = dataUrl.split(',')[1];
    const typeArr = dataUrl.split(',')[0].match(/:(.*?);/);
    const fileType = (typeArr && typeArr[1]) || 'image/png';
    const fileName = `background.${fileType.split('/')[1]}`;
    const byteString = atob(base64Data);
    const byteArray = Uint8Array.from(Array.from(byteString, (char) => char.charCodeAt(0)));
    const blob = new Blob([byteArray], { type: fileType });
    const file = new File([blob], fileName, { type: fileType });
    try {
      await organizationService.setOrganizationBackground({
        file,
        imageType: ImageType.Background,
      });
      enqueueSnackbar({
        variant: 'success',
        message: t('organizationSettings.blindCVSettings.backgroundUploadSuccess'),
      });
    } catch (err) {
      enqueueSnackbar({ variant: 'error', message: t('organizationSettings.blindCVSettings.backgroundUploadError') });
    }
  };

  const handleSaveBlindCVSettings = async () => {
    try {
      await organizationService.setOrganizationSettings({
        blindCVSettings,
      });
      enqueueSnackbar({ variant: 'success', message: t('organizationSettings.blindCVSettings.settingsUpdateSuccess') });
    } catch (err) {
      enqueueSnackbar({ variant: 'error', message: t('organizationSettings.blindCVSettings.settingsUpdateError') });
    }
  };

  const handleSaveSettings = () => {
    handleSaveBlindCVSettings();
  };

  const handleDeleteBackgroundImage = async () => {
    try {
      await organizationService.deleteOrganizationBackground();
      setOrganizationBackground('');
      setPreview(null);
      setCroppedImageUrl(null);
    } catch (e) {
      enqueueSnackbar({ variant: 'error', message: t('organizationSettings.blindCVSettings.backgroundDeleteError') });
    }
  };

  const handleNavigateToInstruction = () => navigate('/instruction/blind-customization');

  useEffect(() => {
    const newPadding = Object.entries(getValues().padding).reduce(
      (acc, [key, val]) => ({ ...acc, [key]: Number(val) }),
      { top: 0, sides: 0, bottom: 0 }
    );
    const newBlindLanguage = getValues().blindLanguage;
    const newIncludeInBlind = getValues().includeInBlind;
    const newPositionDetails = getValues().positionDetails;
    const newCompanyNameDetails = getValues().companyNameDetails;

    setBlindCVSettings({
      padding: newPadding,
      blindLanguage: newBlindLanguage,
      includeInBlind: newIncludeInBlind,
      positionDetails: newPositionDetails,
      companyNameDetails: newCompanyNameDetails,
    });
  }, [watchedValues]);

  useEffect(() => {
    if (isCropNeeded) setIsModalOpen(true);
  }, [isCropNeeded]);

  useEffect(() => {
    getOrganizationBackground();
    getOrganizationSettings();
  }, []);

  return (
    <Wrapper>
      <StyledModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onContinue={handleSaveCrop}
        title={t('organizationSettings.blindCVSettings.cropperTitle')}
      >
        <ReactCrop crop={crop} onChange={(c) => setCrop(c)} aspect={1.0 / Math.sqrt(2)}>
          {preview && <img src={preview} alt="" />}
        </ReactCrop>
      </StyledModal>
      <Grid container flexDirection={'row'}>
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'flex-end'} width={'100%'} mb={theme.spacing(2)}>
            <Button variant="contained" component="label" onClick={handleSaveSettings}>
              {t('organizationSettings.blindCVSettings.saveSettings')}
            </Button>
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12} mt={theme.spacing(2)}>
          <Typography textAlign={'center'} variant="h5">
            {t('organizationSettings.blindCVSettings.blindGenerationSettings.title')}
          </Typography>
          <BlindDetailsForm control={control} blindCVSettings={blindCVSettings} languagesOptions={languagesOptions} />
          <Divider />
        </Grid>
        <Grid item xs={12} mt={theme.spacing(2)}>
          <Typography textAlign={'center'} variant="h5">
            {t('organizationSettings.blindCVSettings.blindApperanceSettings.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={7} container flexDirection={'column'} gap={theme.spacing(2)} padding={theme.spacing(2)}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Typography textAlign={'center'}>
                {t('organizationSettings.blindCVSettings.blindApperanceSettings.include')}
              </Typography>
              <BlindInludeInForm control={control} blindCVSettings={blindCVSettings} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box mb={theme.spacing(2)}>
                <Typography textAlign={'center'}>
                  {t('organizationSettings.blindCVSettings.blindApperanceSettings.spaces')}
                </Typography>
              </Box>
              <BlindSpacesForm control={control} blindCVSettings={blindCVSettings} />
            </Grid>
            <Box mb={theme.spacing(2)} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
              <Typography>
                {t('organizationSettings.blindCVSettings.blindApperanceSettings.backgroundImage')}
              </Typography>
              <Tooltip title={t('organizationSettings.blindCVSettings.goToInstruction')}>
                <Box>
                  <IconButton onClick={handleNavigateToInstruction} size="large">
                    <Iconify icon="ph:question-duotone" width={'100%'} />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
            <BlindBackgroundForm
              croppedImageUrl={croppedImageUrl}
              handleFileChange={handleFileChange}
              handleOpenCropper={handleOpenCropper}
              handleDeleteBackgroundImage={handleDeleteBackgroundImage}
              onDrop={onDrop}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} container justifyContent={'center'} gap={theme.spacing(2)} padding={theme.spacing(2)}>
          <Typography>{t('organizationSettings.blindCVSettings.pdfPreview')}</Typography>
          <PDFViewer style={{ width: '100%', height: '100vh' }}>
            <BlindCvPdfDoc
              blindCvData={mockedData}
              image={croppedImageUrl}
              padding={blindCVSettings.padding}
              includeInBlind={blindCVSettings.includeInBlind}
            />
          </PDFViewer>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default BlindCVSettings;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
}));

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useModal from '../../hooks/useModal';
import contextService, { UploadedFileResponse } from '../../services/contextService';
import Scrollbar from '../scrollbar';
import DocumentListElement from './DocumentListElement';
import StyledModal from '../modals/styled-modal';

interface DocumentListProps {
  documents: Array<UploadedFileResponse>;
  isPending: boolean;
  contextId: string | undefined;
  getDocuments: () => Promise<void>;
}
interface ContainerStyledProps {
  'data-ispending': boolean;
}

const DocumentList = ({ documents, isPending, getDocuments, contextId }: DocumentListProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, toggleModal] = useModal();
  const [fileId, setFileId] = useState<string | null>(null);
  const [isDeletePending, setIsDeletePending] = useState(false);

  const handleOpenModal = (fileId: string) => {
    toggleModal();
    setFileId(fileId);
  };

  const TABLE_HEAD = [
    { id: 'fileName', label: t('context.table.fileName') },
    { id: 'size', label: t('context.table.fileSize') },
    { id: 'isIndexed', label: t('context.table.isIndexed') },
    { id: '' },
  ];

  const deleteDocument = async () => {
    if (!contextId) return;
    if (!fileId) return;
    setIsDeletePending(true);
    try {
      toggleModal();
      await contextService.deleteDocument({ fileId, contextId });
      await getDocuments();
      enqueueSnackbar({ variant: 'success', message: t('context.fileDeleteSuccess') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsDeletePending(false);
    }
  };

  if (documents.length === 0)
    return (
      <Container data-ispending sx={{ padding: 5 }}>
        <Typography> {t('context.documentsNotFound')} </Typography>
      </Container>
    );

  return (
    <Scrollbar sx={scrollbarStyles}>
      <Container data-ispending={isPending}>
        {isPending ? (
          <CircularProgress />
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map(({ id, label }) => (
                    <TableCell key={id}>{label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {documents.map((document) => (
                  <DocumentListElement
                    key={document.id}
                    document={document}
                    isPending={isDeletePending}
                    handleOpenModal={handleOpenModal}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <StyledModal
          title={t('context.fileDelete')}
          open={isModalOpen}
          onClose={toggleModal}
          onContinue={deleteDocument}
        >
          <Typography>{t('context.confirmFileDelete')}</Typography>
        </StyledModal>
      </Container>
    </Scrollbar>
  );
};

export default DocumentList;

// TODO: Seperate this into reusable components
const Container = styled(Box)<ContainerStyledProps>(({ theme, 'data-ispending': isPending }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isPending ? 'center' : 'flex-start',
  alignItems: isPending ? 'center' : 'flex-start',
  flex: 1,
  gap: theme.spacing(3),
  overflow: 'auto',
}));

const scrollbarStyles = {
  height: 1,
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
};

/* eslint-disable no-unused-vars */
import { Box, Button, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import Iconify from '../iconify';
import gptRecruiterService, { RecruitmentCvStatus } from '../../services/gptRecruiterService';

interface ApplicantCardActionsProps {
  id: string;
  status: RecruitmentCvStatus;
}

const ApplicantCardActions = ({ id, status }: ApplicantCardActionsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { recruitmentId } = useParams<{ recruitmentId?: string }>();
  const [applicantStatus, setApplicantStatus] = useState<string>(status);
  const navigate = useNavigate();
  const handleChangeStatus = async (status: RecruitmentCvStatus, cvId: string) => {
    try {
      if (!recruitmentId || !cvId) return;
      const updatedStatus = applicantStatus === status ? RecruitmentCvStatus.NotAssigned : status;

      await gptRecruiterService.setStatusRecruitmentByIdCV({ recruitmentId, status: updatedStatus, cvId });
      setApplicantStatus(updatedStatus);
      enqueueSnackbar({ variant: 'success', message: t('recruitment.statusUpdated') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const handleDownloadCv = async () => {
    if (!recruitmentId || !id) return;
    try {
      const openPdf = (fileURL: string) => {
        window.open(fileURL, '_blank');
      };

      const downloadFile = (fileURL: string) => {
        const anchorElement = document.createElement('a');

        document.body.appendChild(anchorElement);
        anchorElement.style.display = 'none';
        anchorElement.href = fileURL;
        anchorElement.download = response.fileName;
        anchorElement.click();

        window.URL.revokeObjectURL(fileURL);
      };

      const response = await gptRecruiterService.downloadCvById({ recruitmentId, cvId: id });

      const blob = new Blob([response.binaryData], { type: response.fileType });
      const fileURL = URL.createObjectURL(blob);

      if (response.fileType === 'application/pdf') {
        openPdf(fileURL);
      } else {
        downloadFile(fileURL);
      }
    } catch (error) {
      enqueueSnackbar(t('recruitment.downloadCvError'), { variant: 'error' });
    }
  };
  return (
    <ButtonWrapper>
      <ApplicantStatusButtonsWrapper>
        <StyledButton
          variant={applicantStatus === RecruitmentCvStatus.Accepted ? 'contained' : 'outlined'}
          color="success"
          onClick={() => handleChangeStatus(RecruitmentCvStatus.Accepted, id)}
        >
          {t('accept')}
        </StyledButton>
        <StyledButton
          variant={applicantStatus === RecruitmentCvStatus.Maybe ? 'contained' : 'outlined'}
          color="info"
          onClick={() => handleChangeStatus(RecruitmentCvStatus.Maybe, id)}
        >
          {t('maybe')}
        </StyledButton>
        <StyledButton
          variant={applicantStatus === RecruitmentCvStatus.Rejected ? 'contained' : 'outlined'}
          color="error"
          onClick={() => handleChangeStatus(RecruitmentCvStatus.Rejected, id)}
        >
          {t('decline')}
        </StyledButton>
      </ApplicantStatusButtonsWrapper>
      <ApplicantStatusButtonsWrapper>
        <Button variant="outlined" onClick={handleDownloadCv}>
          <Iconify icon="bi:download" sx={{ marginRight: 1 }} />
          {t('downloadCv')}
        </Button>
      </ApplicantStatusButtonsWrapper>
    </ButtonWrapper>
  );
};

export default ApplicantCardActions;

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: '150px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

const ApplicantStatusButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

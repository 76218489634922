import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import Routes from './Routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import './i18n';
import { LanguageProvider } from './contexts/languagesContext';
import { SnackbarUtilsConfigurator } from './utils/snackbar';

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <SnackbarProvider>
            <SnackbarUtilsConfigurator />
            <LanguageProvider>
              <ScrollToTop />
              <Routes />
            </LanguageProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

import { Card, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import PageTemplate from './PageTemplate';

import useModal from '../hooks/useModal';
import StyledModal from '../components/modals/styled-modal';
import gptRecruiterService, { Recruitment } from '../services/gptRecruiterService';
import RecruitmentsListHeading from '../components/recruitment/RecruitmentsListHeading';
import RecruitmentsList from '../components/recruitment/RecruitmentsList';
import RecruitmentForm from '../components/recruitment/RecruitmentForm';

export interface RecruitmentsFormValues {
  name: string;
}

export default function GptRecruitments() {
  const [isPending, setIsPending] = useState(false);
  const [isCreateRecruitmentPending, setIsCreateRecruitmentPending] = useState(false);
  const [recruitments, setRecruitments] = useState<Array<Recruitment>>([]);
  const [isModalOpen, toggleModal] = useModal();
  const { t } = useTranslation();
  const pageTitle = t('recruitments.title');
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<RecruitmentsFormValues>({
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit } = form;

  const openModal = () => toggleModal();

  const closeModal = () => {
    toggleModal();
    form.reset();
  };

  const getRecruitments = async () => {
    setIsPending(true);
    try {
      const recruitments = await gptRecruiterService.getRecruitments();
      setRecruitments(recruitments);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsPending(false);
    }
  };

  const onSubmit: SubmitHandler<RecruitmentsFormValues> = async ({ name }) => {
    setIsCreateRecruitmentPending(true);

    try {
      closeModal();
      await gptRecruiterService.createRecruitment(name);
      await getRecruitments();
      enqueueSnackbar(t('recruitments.recruitmentCreated'), { variant: 'success' });
    } catch (error) {
      const errorMessage = t('somethingWentWrong');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      form.reset();
      setIsCreateRecruitmentPending(false);
    }
  };

  return (
    <PageTemplate pageTitle={pageTitle}>
      <FormProvider {...form}>
        <Container>
          <RecruitmentsListHeading openModal={openModal} isCreateRecruitmentPending={isCreateRecruitmentPending} />
          <RecruitmentsList recruitments={recruitments} getRecruitments={getRecruitments} isPending={isPending} />
        </Container>
        <StyledModal
          title={t('recruitments.createNewRecrutations')}
          open={isModalOpen}
          onClose={closeModal}
          onContinue={handleSubmit(onSubmit)}
        >
          <RecruitmentForm onContinue={handleSubmit(onSubmit)} />
        </StyledModal>
      </FormProvider>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));

import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../components/logo';
import LoginForm from '../sections/auth/login';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const Wrapper = styled(Card)(({ theme }) => ({
  maxWidth: '90%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(5),
  gap: theme.spacing(1),
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(0),
  width: '100%',
}));

export default function LoginPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('login.title')}</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Wrapper
          sx={{
            display: { xs: 'flex', md: 'block' },
            justifyContent: { xs: 'flex-start', md: 'center' },
          }}
        >
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {t('login.signIn')}
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              {t('login.dontHaveAccount')}{' '}
              {t('login.viewPricingPlans')}{' '}
              <Link sx={{ cursor: 'pointer' }} variant="subtitle2" to="/pricing-plans" component={RouterLink}>
                {t('login.pricingPlans')}
              </Link>
              {' '}{t('login.and')}{' '}
              <Link sx={{ cursor: 'pointer' }} variant="subtitle2" to="/create-organization" component={RouterLink}>
                {t('getStarted')}
              </Link>
            </Typography>

            <LoginForm />
          </StyledContent>
        </Wrapper>
      </StyledRoot>
    </>
  );
}

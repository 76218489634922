import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography, styled, Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import BlindGeneratorForm from '../components/recruitment/BlindCv/BlindGeneratorForm';
import PageTemplate from './PageTemplate';
import useLongPolling from '../hooks/useLongPooling';
import blindCvService, { BlindCvStatus, BlindCvDataResponse } from '../services/blindCvService';
import CandidateBlindCvResultsFlex from '../components/recruitment/BlindCv/CandidateBlindCvResultsFlex';
import { BlindCvPdfDoc } from '../components/recruitment/BlindCv/BlindCvPdf/BlindCvPdfDoc';
import PdfLinkButton from '../components/recruitment/BlindCv/BlindCvPdf/PdfLink';
import organizationService from '../services/organizationService';
import { BlindSettingsContext } from '../contexts/blindSettingsContext';
import { OrganizationSettingsMenu } from './OrganizationSettings';

export default function BlindCvGenerator() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const pageTitle = t('blindCvGenerator.title');
  const navigate = useNavigate();
  const [blindCvId, setBlindCvId] = useState<string>('');
  const [processingStatus, setProcessingStatus] = useState<BlindCvStatus>();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [blindCvData, setBlindCvData] = useState<BlindCvDataResponse | undefined>(undefined);
  const { blindCVSettings, organizationBackground, getOrganizationBackground, getOrganizationSettings } =
    useContext(BlindSettingsContext);

  const getBlindCvResult = async () => {
    try {
      if (!blindCvId) return;
      const response = await blindCvService.getBlindCv(blindCvId);
      setProcessingStatus(response.status);
      if (response.blindCvData) {
        setBlindCvData(response.blindCvData);
        setIsCompleted(true);
      }
      if (response.status === BlindCvStatus.Failed) {
        setIsCompleted(true);
        setBlindCvData(undefined);
      }
    } catch (error) {
      setIsCompleted(true);
      setBlindCvData(undefined);
      throw new Error('Error fetching recruitment processing status');
    }
  };
  const updateBlindCvData = async (updatedBlindCvData: BlindCvDataResponse) => {
    try {
      if (!blindCvId) return;

      const response = await blindCvService.updateBlindCv(blindCvId, updatedBlindCvData);
      if (response.status === BlindCvStatus.Failed) {
        setIsCompleted(true);
        enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      }
      setBlindCvData(response.blindCvData);
      enqueueSnackbar({ variant: 'success', message: 'Blind updated sucessfully' });
    } catch (error) {
      setIsCompleted(true);
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      throw new Error('Error updating blind CV');
    }
  };
  const setBlindCvHandler = (blindCvId: string) => {
    setBlindCvId(blindCvId);
    setIsCompleted(false);
  };
  const handleGoToBlindSettings = () => {
    navigate(`/dashboard/organization-settings?initialTab=${OrganizationSettingsMenu.BlindSettings}`);
  };
  useLongPolling(getBlindCvResult, 1000, isCompleted);
  const isProcessing =
    (processingStatus === BlindCvStatus.Queued || processingStatus === BlindCvStatus.Started) && !isCompleted;

  useEffect(() => {
    getOrganizationBackground();
    getOrganizationSettings();
  }, []);

  return (
    <PageTemplate pageTitle={pageTitle}>
      <>
        <Container>
          <StyledCard>
            <Grid container>
              <Grid item xs={12} lg={9}>
                <Typography variant="h3">{t('blindCvGenerator.heading')}</Typography>
                <SubHeading>{t('blindCvGenerator.subHeading')}</SubHeading>
              </Grid>
              <Grid item xs={12} lg={3} container justifyContent={'flex-end'} alignItems={'center'}>
                <Button variant={'contained'} onClick={() => handleGoToBlindSettings()}>
                  {t('blindCvGenerator.customize')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <BlindGeneratorForm setBlindCvId={setBlindCvHandler} />
              </Grid>
            </Grid>
          </StyledCard>

          {blindCvData && (
            <Box sx={{ marginRight: 'auto', display: 'flex' }}>
              <PdfLinkButton
                Pdfdocument={
                  <BlindCvPdfDoc
                    blindCvData={blindCvData}
                    image={organizationBackground}
                    padding={blindCVSettings.padding}
                    includeInBlind={blindCVSettings.includeInBlind}
                  />
                }
              />
            </Box>
          )}

          <CandidateBlindCvResultsFlex
            isPending={isProcessing}
            isCompleted={isCompleted}
            blindCvData={blindCvData}
            updateBlindCvData={updateBlindCvData}
          />
        </Container>
      </>
    </PageTemplate>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextResponse } from '../../services/contextService';
import Scrollbar from '../scrollbar';
import ContextListElement from './ContextListElement';

interface ContextListProps {
  contexts: Array<ContextResponse>;
  isPending: boolean;
  getContexts: () => Promise<void>;
}
interface ContainerStyledProps {
  'data-ispending': boolean;
}

const ContextList = ({ contexts, isPending, getContexts }: ContextListProps) => {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'title', label: t('contexts.table.title') },
    { id: 'description', label: t('contexts.table.description') },
    { id: 'contextType', label: t('contexts.table.contextType') },
    { id: 'isIndexed', label: t('contexts.table.isIndexed') },
    { id: '' },
  ];

  useEffect(() => {
    getContexts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPending)
    return (
      <Container data-ispending={isPending}>
        <CircularProgress />
      </Container>
    );

  return (
    <Scrollbar sx={scrollbarStyles}>
      <Container data-ispending={isPending}>
        {contexts.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map(({ id, label }) => (
                    <TableCell key={id}>{label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {contexts.map((context) => (
                  <ContextListElement key={context.id} context={context} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Card sx={{ display: 'flex', width: '100%', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h4">{t('contexts.noContextsFound')}</Typography>
          </Card>
        )}
      </Container>
    </Scrollbar>
  );
};

export default ContextList;

// TODO: Seperate this into reusable components
const Container = styled(Box)<ContainerStyledProps>(({ theme, 'data-ispending': isPending }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isPending ? 'center' : 'flex-start',
  alignItems: isPending ? 'center' : 'flex-start',
  flex: 1,
  gap: theme.spacing(3),
  overflow: 'auto',
}));

const scrollbarStyles = {
  height: 1,
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
};

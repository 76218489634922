import { Box, Button, Modal as MuiModal, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SyntheticEvent } from 'react';
import Scrollbar from '../../scrollbar';

interface ModalProps {
  title: string;
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  continueButtonText?: string | null;
  disableBackdropClick?: boolean;
}

const StyledModal = ({
  open,
  onClose,
  title,
  children,
  onContinue,
  continueButtonText,
  disableBackdropClick = false,
}: ModalProps) => {
  const { t } = useTranslation();
  const handleClose = (_: SyntheticEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };
  return (
    <MuiModal open={open} onClose={disableBackdropClick ? handleClose : onClose} closeAfterTransition>
      <ModalContainer>
        <ModalContent>
          <ModalHeading>
            <Typography variant="h6" component="h2">
              {title}
            </Typography>
          </ModalHeading>
          <Scrollbar sx={scrollbarStyles}>{children}</Scrollbar>
          <ActionsContainer>
            <Button fullWidth variant="outlined" color="error" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button fullWidth variant="contained" type={'submit'} onClick={onContinue}>
              {continueButtonText || t('continue')}
            </Button>
          </ActionsContainer>
        </ModalContent>
      </ModalContainer>
    </MuiModal>
  );
};

export default StyledModal;

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
}));

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  justifyContent: 'space-evenly',
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const ModalHeading = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: '100%',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
}));

const scrollbarStyles = {
  height: 1,
  maxHeight: 400,
  padding: '16px',
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
};

import { Box, Button, CircularProgress, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Iconify from '../../iconify';
import gptRecruiterService from '../../../services/gptRecruiterService';
import { Stepper } from '../../shared/Stepper';

interface CVProps {
  recruitmentId: string | undefined;
  candidateId: string;
}
enum CVDownload {
  Pending = 'Pending',
  Failed = 'Failed',
  Succeded = 'Succeded',
}
export const CV = ({ recruitmentId, candidateId }: CVProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [cvUrl, setCvUrl] = useState<string>('');
  const [cvData, setCvData] = useState<BlobPart>('');
  const [fileName, setFileName] = useState<string>('');
  const [cvFileType, setCvFileType] = useState<string>('');
  const [cvStatus, setCvStatus] = useState<CVDownload>(CVDownload.Pending);
  const isPDF = cvFileType === 'application/pdf';

  const downloadFile = () => {
    const fileURL = URL.createObjectURL(new Blob([cvData], { type: cvFileType }));
    const anchorElement = document.createElement('a');
    document.body.appendChild(anchorElement);
    anchorElement.style.display = 'none';
    anchorElement.href = fileURL;
    anchorElement.download = fileName;
    anchorElement.click();
    URL.revokeObjectURL(fileURL);
  };

  const getCandidateCV = async () => {
    if (!recruitmentId) {
      setCvStatus(CVDownload.Failed);
      return;
    }
    try {
      const response = await gptRecruiterService.downloadCvById({ recruitmentId, cvId: candidateId });
      const blob = new Blob([response.binaryData], { type: response.fileType });
      const fileURL = URL.createObjectURL(blob);
      setCvFileType(response.fileType);
      setFileName(response.fileName);
      setCvUrl(fileURL);
      setCvData(response.binaryData);
      setCvStatus(CVDownload.Succeded);
    } catch (error) {
      setCvStatus(CVDownload.Failed);
    }
  };

  useEffect(() => {
    getCandidateCV();
    return () => {
      if (cvUrl) {
        URL.revokeObjectURL(cvUrl);
      }
    };
  }, []);

  return (
    <Stepper
      steps={{
        [CVDownload.Failed]: (
          <CVWrapper>
            <Typography>{t('candidatePanel.cvDownloadError')}</Typography>
          </CVWrapper>
        ),
        [CVDownload.Pending]: (
          <CVWrapper>
            <SpinnerContainer sx={{ height: 300 }}>
              <CircularProgress sx={{ margin: 'auto' }} />
            </SpinnerContainer>
          </CVWrapper>
        ),
        [CVDownload.Succeded]: (
          <Box padding={theme.spacing(1)}>
            {isPDF ? (
              <Box height={'95vh'}>
                <iframe src={cvUrl} width={'100%'} height={'100%'} title={candidateId} />
              </Box>
            ) : (
              <CVWrapper>
                <Typography fontSize={'1.5rem'} fontWeight={500}>
                  {t('candidatePanel.cvDisplayError')}
                </Typography>
                <Button variant="outlined" onClick={downloadFile}>
                  <Iconify icon="bi:download" sx={{ marginRight: 1 }} />
                  {t('downloadCv')}
                </Button>
              </CVWrapper>
            )}
          </Box>
        ),
      }}
      activeStep={cvStatus}
    />
  );
};

const CVWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}));
const SpinnerContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Typography, Card, Box, CircularProgress, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import userService, { Tier } from '../services/userService';
import PricingPlansList from '../components/pricing-plans/PricingPlansList';
import organizationService from '../services/organizationService';
import PricingTable from '../components/pricing-plans/PricingTable';


interface TypedTiers {
  generator: Tier[];
  recruiter: Tier[];
  blinds: Tier[];
}

export enum AdonType {
  Generator = 'generator',
  Recruiter = 'recruiter',
  Blinds = 'blinds'
}

export default function PricingPlans() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [typedPricingPlans, setTypedPricingPlans] = useState<TypedTiers>({} as TypedTiers);
  const [currentTypeIndex, setCurrentTypeIndex] = useState<AdonType>(AdonType.Blinds);

  const [isFetchingPlans, setIsFetchingPlans] = useState(true);

  const getPricingPlans = async () => {
    setIsFetchingPlans(true);
    try {
      const response = await userService.getPricingPlans();

      const typedPricingPlans: TypedTiers = {
        generator: Object.keys(response).filter((key) => key.includes('generator')).map((key) => response[key]),
        recruiter: Object.keys(response).filter((key) => key.includes('recruiter')).map((key) => response[key]),
        blinds: Object.keys(response).filter((key) => key.includes('blinds')).map((key) => response[key])
      };

      typedPricingPlans.generator.unshift(response.trial);
      typedPricingPlans.recruiter.unshift(response.trial);
      typedPricingPlans.blinds.unshift(response.trial);

      setTypedPricingPlans(typedPricingPlans);

    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsFetchingPlans(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: AdonType) => {
    setCurrentTypeIndex(newValue);
  };

  const getPricingPlanForAdonType = (adonType: AdonType) => {
    switch (adonType) {
      case AdonType.Generator:
        return typedPricingPlans.generator;
      case AdonType.Recruiter:
        return typedPricingPlans.recruiter;
      case AdonType.Blinds:
        return typedPricingPlans.blinds;
      default:
        return [];
    }
  }

  const selectedTypePricePlans = getPricingPlanForAdonType(currentTypeIndex)


  const handleSubscribe = async (accessPlanId: number) => {
    try {
      if (accessPlanId === 5) // free trial
      {
        const { redirectUrl } = await organizationService.subscribeFreeTrial();
        if (redirectUrl) window.location.replace(redirectUrl);
      }

      const { redirectUrl } = await organizationService.subscribe(accessPlanId);
      if (redirectUrl) window.location.replace(redirectUrl);
    } catch (error: any) {
      if (error?.message?.startsWith('RedirectToCreateOrganization')) {
        window.location.href = '/create-organization';
        return;
      }
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  useEffect(() => {
    getPricingPlans();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('pricingPlans.title')}</title>
      </Helmet>

      <Wrapper>
        {isFetchingPlans ? (
          <CircularProgress />
        ) : (
          <>
            <Box sx={{ alignSelf: 'flex-start' }}>
              <Typography variant="h2" gutterBottom>
                {t('pricingPlans.heading')}
              </Typography>
            </Box>

            <Box>
              <StyledTabs value={currentTypeIndex}
                onChange={handleChange}
                variant="fullWidth"
                scrollButtons
                allowScrollButtonsMobile>
                <StyledTab isSelected={currentTypeIndex === AdonType.Blinds} value={AdonType.Blinds} label="Blind CV" />
                <StyledTab isSelected={currentTypeIndex === AdonType.Recruiter} value={AdonType.Recruiter} label="Recruiter" />
                <StyledTab isSelected={currentTypeIndex === AdonType.Generator} value={AdonType.Generator} label="Generator" />
              </StyledTabs>

              {/* not assigned should be the default status and include SUGGESTIONS */}
            </Box>




            <PricingPlansList pricingPlans={selectedTypePricePlans} handleSubscribe={handleSubscribe} adonType={currentTypeIndex ?? AdonType.Generator} />
            <PricingTable pricingPlans={selectedTypePricePlans} adonType={currentTypeIndex ?? AdonType.Generator} />
          </>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Card)(({ theme }) => ({
  maxWidth: '90%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(5),
  gap: theme.spacing(3),
}));


const StyledTab = styled(Tab)<{isSelected: boolean}>(({ theme, isSelected }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.3rem',
  color: isSelected ? theme.palette.common.white : theme.palette.text.secondary,
  backgroundColor: isSelected ? theme.palette.primary.main : theme.palette.background.paper,
  marginRight: theme.spacing(1),
  padding: theme.spacing(1, 3),
  boxShadow: isSelected ? '0 3px 5px 2px rgba(0, 0, 0, .1)' : 'none',
  transition: 'all .3s ease-in-out',
  '&:hover': {
    backgroundColor: isSelected ? theme.palette.primary.dark : theme.palette.grey[200],
    boxShadow: '0 2px 4px 1px rgba(0, 0, 0, .15)',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[300]}`,
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.secondary.main,
  },
}));
import { styled, Box, Typography, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import EditEmploymentDetailsItem from './EditEmploymentDetailsItem';

export const EmploymentArraySectionEditComponent = ({
  array,
  onCancel,
  onUpdate,
}: {
  array: string[];
  onCancel: () => void;
  onUpdate: (updatedValue: string[]) => void;
}) => {
  const { t } = useTranslation();

  const [edittedArray, setEdditedArray] = useState(array);
  const [newItem, setNewItem] = useState<string>('');

  const { register, handleSubmit } = useForm<{ newDetail: string }>({
    defaultValues: {
      newDetail: newItem,
    },
  });

  const handleAddItem = () => {
    setEdditedArray([...edittedArray, newItem]);
    setNewItem('');
  };

  const handleDeleteItem = (value: string) => {
    setEdditedArray([...edittedArray.filter((q) => q !== value)]);
  };

  const handleEditItem = (value: string, newValue: string) => {
    setEdditedArray([...edittedArray.map((q) => (q === value ? newValue : q))]);
  };

  useEffect(() => {
    onUpdate(edittedArray);
  }, [edittedArray]);

  return (
    <>
      <Box sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
        <Typography textAlign={'center'} fontWeight={600}>
          {t('blindCvEdit.workExperience.detailsList')}
        </Typography>
        {edittedArray.map((item, id) => (
          <EditEmploymentDetailsItem
            handleDeleteItem={handleDeleteItem}
            handleEditItem={handleEditItem}
            item={item}
            key={id}
          />
        ))}
        <StyledForm onSubmit={handleSubmit(handleAddItem)}>
          <TextField
            {...register('newDetail', { required: true })}
            label={t('blindCvEdit.addNewItem')}
            value={newItem}
            onChange={(event) => setNewItem(event.target.value)}
            fullWidth
          />
          <Button type="submit" disabled={!newItem.length} variant="outlined" onClick={handleAddItem}>
            {t('add')}
          </Button>
        </StyledForm>
      </Box>
    </>
  );
};

export default EmploymentArraySectionEditComponent;

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  placeContent: 'flex-start',
  marginBottom: theme.spacing(1),
}));

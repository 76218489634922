import { styled, Box, Typography, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import BlindStyledCardWrapper from '../BlindStyledCardWrapperProps';
import EditEmploymentHistoryItem from './EditEmploymentHistoryItem';
import { PreviousProject } from '../../../../../services/blindCvService';
import StyledModal from '../../../../modals/styled-modal';
import EmploymentArraySectionEditComponent from './EmploymentArraySectionEditComponent';

interface BlindTextSectionComponentProps {
  title: string;
  copyText: string;
  values: PreviousProject[];
  submitEdit: (updatedValues: PreviousProject[]) => Promise<void>;
}

const ArraySectionEditComponent = ({
  array,
  onUpdate,
}: {
  array: PreviousProject[];
  onUpdate: (updatedValue: PreviousProject[]) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const [edittedArray, setEdditedArray] = useState(array);
  const [newItem, setNewItem] = useState<PreviousProject>({
    companyName: '',
    time: '',
    details: '',
    position: '',
    detailsList: [],
  });
  const [isAddingNewItem, setIsAddingNewItem] = useState<boolean>(false);
  const handleAddItem = async () => {
    const newArr = [...edittedArray, newItem];
    setEdditedArray(newArr);
    await onUpdate(newArr);
    setNewItem({ companyName: '', time: '', details: '', position: '', detailsList: [] });
  };

  const handleDeleteItem = async (value: PreviousProject) => {
    const newArr = [...edittedArray.filter((q) => JSON.stringify(q) !== JSON.stringify(value))];
    setEdditedArray(newArr);
    await onUpdate(newArr);
  };

  const handleEditItem = async (value: PreviousProject, newValue: PreviousProject) => {
    const newArr = [...edittedArray.map((q) => (JSON.stringify(q) === JSON.stringify(value) ? newValue : q))];
    setEdditedArray(newArr);
    await onUpdate(newArr);
  };

  return (
    <Box sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
      {edittedArray.map((item, id) => (
        <EditEmploymentHistoryItem
          handleDeleteItem={handleDeleteItem}
          handleEditItem={handleEditItem}
          item={item}
          key={id}
        />
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button variant="contained" onClick={() => setIsAddingNewItem(true)}>
          {t('blindCvEdit.addNewItem')}
        </Button>
      </Box>
      <StyledModal
        title={t('blindCvEdit.title')}
        onClose={() => {
          setIsAddingNewItem(false);
          setNewItem({ companyName: '', time: '', details: '', position: '', detailsList: [] });
        }}
        onContinue={() => {
          handleAddItem();
          setIsAddingNewItem(false);
        }}
        open={isAddingNewItem}
        continueButtonText={t('save')}
        disableBackdropClick
      >
        <FlexList>
          <TextField
            label={t('blindCvEdit.workExperience.position')}
            value={newItem.position}
            onChange={(event) => setNewItem({ ...newItem, position: event.target.value })}
            fullWidth
            multiline
          />
          <TextField
            label={t('blindCvEdit.workExperience.companyName')}
            value={newItem.companyName}
            onChange={(event) => setNewItem({ ...newItem, companyName: event.target.value })}
            fullWidth
            multiline
          />
          <TextField
            label={t('blindCvEdit.workExperience.time')}
            value={newItem.time}
            onChange={(event) => setNewItem({ ...newItem, time: event.target.value })}
            fullWidth
          />
          <TextField
            label={t('blindCvEdit.workExperience.description')}
            value={newItem.details}
            onChange={(event) => setNewItem({ ...newItem, details: event.target.value })}
            fullWidth
            multiline
          />
          <EmploymentArraySectionEditComponent
            array={newItem.detailsList}
            onUpdate={(updatedValue) => setNewItem({ ...newItem, detailsList: updatedValue })}
            onCancel={() => setIsAddingNewItem(false)}
          />
        </FlexList>
      </StyledModal>
    </Box>
  );
};

const BlindEditEmploymentHistory = ({ title, copyText, submitEdit, values }: BlindTextSectionComponentProps) => {
  const handleUpdate = async (updatedValues: PreviousProject[]) => {
    await submitEdit(updatedValues);
  };
  return (
    <BlindStyledCardWrapper title={title} copyText={copyText}>
      <ArraySectionEditComponent array={values} onUpdate={handleUpdate} />
    </BlindStyledCardWrapper>
  );
};

export default BlindEditEmploymentHistory;

const FlexList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
  width: '100%',
}));

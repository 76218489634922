/* eslint-disable no-unused-vars */

export enum Languages {
  EN = 'en',
  PL = 'pl',
  ES = 'es',
  JP = 'jp',
}

const setLanguage = (language: Languages) => {
  localStorage.setItem('language', language);
};

const getLanguage = () => {
  const language = localStorage.getItem('language');

  if (language) {
    return language;
  }

  // Get browser language and extract the first two characters (e.g., 'en-US' becomes 'en')
  const browserLanguage = navigator.language.slice(0, 2);

  // Check if the browser's language is available in the app
  if (Object.values(Languages).map(e => `${e}`).includes(browserLanguage)) {
    return browserLanguage as Languages;
  }

  return Languages.EN;
};

export default { setLanguage, getLanguage };

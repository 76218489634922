import { useEffect, useRef } from 'react';

interface UseFocusAfterSubmitProps {
  isPending: boolean;
}

const useFocusAfterSubmit = ({ isPending }: UseFocusAfterSubmitProps) => {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!isPending && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isPending]);

  return inputRef;
};

export default useFocusAfterSubmit;

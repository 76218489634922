import { useState, useEffect } from 'react';
import { styled, Typography, Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import UserMenagmentList from './UserMenagmentList';
import organizationService, { User } from '../../services/organizationService';
import UserMenagmentForm from './UserMenagmentForm';
import StyledModal from '../modals/styled-modal';
import useModal from '../../hooks/useModal';

// TODO: Display this component only if the user is an admin

const OrganizationUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [deleteUserId, setDeleteUserId] = useState<string>('');
  const { t } = useTranslation();
  const [isOpen, toggleModal] = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const deleteUserHeader = t('organizationUsers.deleteUserHeader');
  const confirmDelete = t('organizationUsers.confirmDelete');

  const scrollToUserMenagmentForm = () => {
    const userMenagmentFormElement = document.getElementById('user-menagment-form');
    if (userMenagmentFormElement) {
      userMenagmentFormElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchUsers = async () => {
    setIsPending(true);
    try {
      const usersData = await organizationService.getOrganizationUsers();
      setUsers(usersData.users);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsPending(false);
    }
  };

  const deleteUser = async () => {
    if (!deleteUserId) return;
    try {
      await organizationService.deleteOrganizationUser(deleteUserId);
      fetchUsers();
      enqueueSnackbar({ variant: 'success', message: t('organizationUsers.userDeleted') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      toggleModal();
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isPending)
    return (
      <Box sx={{ minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  return (
    <Container>
      <UserMenagmentList
        users={users}
        toggleModal={toggleModal}
        setSelectedUser={setSelectedUser}
        setDeleteUserId={setDeleteUserId}
        scrollToUserMenagmentForm={scrollToUserMenagmentForm}
      />
      <UserMenagmentForm fetchUsers={fetchUsers} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
      <StyledModal title={deleteUserHeader} open={isOpen} onClose={toggleModal} onContinue={deleteUser}>
        <Typography>{confirmDelete}</Typography>
      </StyledModal>
    </Container>
  );
};

export default OrganizationUsers;

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));

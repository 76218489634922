import React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Recruitment, RecruitmentStatus } from '../../services/gptRecruiterService';

interface Props {
  recruitment: Recruitment | undefined;
  handleRecruitmentStatusChange: (status: RecruitmentStatus) => Promise<void>;
}
interface RecruitmentStatusFormProps {
  status: RecruitmentStatus;
}

const RecruitmentStuatus: React.FC<Props> = ({ recruitment, handleRecruitmentStatusChange }) => {
  const { t } = useTranslation();
  const { control } = useForm<RecruitmentStatusFormProps>({
    defaultValues: {
      status: recruitment?.status,
    },
  });
  if (!recruitment) return null;

  const statusList = {
    [RecruitmentStatus.InProgress]: t('recruitment.tableRecruitmentStatus.InProgress'),
    [RecruitmentStatus.Closed]: t('recruitment.tableRecruitmentStatus.Closed'),
  };
  return (
    <StyledCard>
      <Typography>{t('recruitment.status.status')}</Typography>
      <Controller
        name={'status'}
        control={control}
        defaultValue={recruitment?.status}
        render={({ field }) => (
          <FormControl variant="outlined" sx={{ width: '30%' }} size="small">
            <InputLabel>{t('recruitment.status.status')}</InputLabel>
            <Select
              {...field}
              defaultValue={recruitment?.status}
              onChange={(e) => {
                field.onChange(e);
                handleRecruitmentStatusChange(e.target.value as RecruitmentStatus);
              }}
            >
              {Object.entries(statusList).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </StyledCard>
  );

  return null;
};

const StyledCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  height: '2rem',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

export default RecruitmentStuatus;

import { Typography, Grid, Card, Box, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PageTemplate from './PageTemplate';
import EmailEnhancer from './EmailEnhancer';
import PrivateChat from './PrivateChat';

const Dashboard = () => {
  const { t } = useTranslation();
  const pageWelcome = t('welcomeAdon');
  const pageTitle = t('dashboard.title');

  const headingDescription = t('dashboard.heading');



  return (
    <PageTemplate pageTitle={pageTitle}>
      <Container>
        <Heading variant="h2">{pageWelcome}</Heading>

        {/* <SubHeading variant="h3">{headingDescription}</SubHeading> */}


        <Box sx={{ maxWidth: '6xl', mx: 'auto', px: 2, sm: { px: 6 } }}>
          <Box sx={{ maxWidth: 'full', mx: 'auto', textAlign: 'center', mb: 14, mt: 3 }}>

            <Grid container spacing={3} alignItems="stretch" justifyContent="center" >

              <Grid item sm={12} md={4}>
                <Paper elevation={5} sx={{ p: 4, display: 'flex', flexDirection: 'column', borderRadius: 2, borderColor: 'primary.main', borderWidth: 2, height: '100%' }}>
                  <Typography variant="h5" component="h3" gutterBottom>
                    AI <span style={{ color: '#ec4899' }}>Recruiter</span>
                  </Typography>
                  {t('dashboard.aiRecruiterDescription')}
                  <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
                    <Button variant="contained" sx={{ color: 'common.white', minWidth: '60%' }} component={RouterLink} to="/dashboard/gptrecruiter/recruitments">
                      {t('dashboard.aiRecruiterButton')}
                    </Button>
                  </Box>
                </Paper>
              </Grid>

              <Grid item sm={12} md={4} >
                <Paper elevation={5} sx={{ p: 4, display: 'flex', flexDirection: 'column', borderRadius: 2, borderColor: 'primary.main', borderWidth: 2, height: '100%' }}>
                  <Typography variant="h5" component="h3" gutterBottom textAlign="center">
                    AI <span style={{ color: 'rgba(251, 191, 36, 0.9)' }}>Blind CV</span> generator
                  </Typography>
                  {t('dashboard.blindGeneratorDescription')}
                  <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
                    <BlindGeneratorButton variant="contained" component={RouterLink} to="/dashboard/gptrecruiter/blindCv/create">
                      {t('dashboard.blindGeneratorButton')}
                    </BlindGeneratorButton>
                  </Box>
                </Paper>
              </Grid>

              <Grid item sm={12} md={4}>
                <Paper elevation={5} sx={{ p: 4, display: 'flex', flexDirection: 'column', borderRadius: 2, borderColor: 'primary.main', borderWidth: 2, height: '100%' }}>
                  <Typography variant="h5" component="h3" gutterBottom>
                    AI <AiAssistantTypography variant="h5" display="inline">Assistant</AiAssistantTypography>
                  </Typography>
                  {t('dashboard.generativeAiAssistantDescription')}
                  <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
                    <Button variant="contained" sx={{ color: 'common.white', minWidth: '60%' }} component={RouterLink} to="/dashboard/private-chat">
                      {t('dashboard.generativeAiAssistantButton')}
                    </Button>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} xl={12}>
                <></>
              </Grid>

              <Grid item xs={12} xl={8}>
                <MiddleSectionText variant="h5">{t('dashboard.enhanceEmail')}</MiddleSectionText>
                <EmailEnhancer />
              </Grid>

              <Grid item xs={12} xl={4}>
                <MiddleSectionText variant="h5">{t('dashboard.chat')}</MiddleSectionText>
                <PrivateChat />
              </Grid>

            </Grid>
          </Box>
        </Box>
      </Container>
    </PageTemplate>
  );
};

const Container = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5)
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  textAlign: 'center'
}));

const MiddleSectionText = styled(Typography)(({ theme }) => ({
  textAlign: 'start',
  margin: theme.spacing(3)
}));

const AiAssistantTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const BlindGeneratorButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(251, 191, 36, 0.8)',
  color: 'common.white',
  minWidth: '60%',
  '&:hover': {
    backgroundColor: 'rgba(251, 191, 93, 0.8)',
  }
}));

export default Dashboard;

import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { Verbs } from './services/userService';
import { UserContext } from './contexts/userContext';

interface HasAccessRouteProps {
  verbs: Verbs[];
  children: JSX.Element;
}

const HasAccessRoute = ({ verbs, children }: HasAccessRouteProps): JSX.Element | null => {
  const { hasAccess, userVerbs } = useContext(UserContext);

  if (!userVerbs) {
    return null;
  }

  if (!hasAccess(verbs)) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default HasAccessRoute;

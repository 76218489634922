import { Box, Card, CircularProgress, Grid, styled, Tab, Tabs, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Summary } from './Summary';
import { CV } from './CV';
import CandidatePanel from './CandidatePanel';
import gptRecruiterService, { Applicant, RecruitmentCvStatus } from '../../../services/gptRecruiterService';
import { Stepper } from '../../shared/Stepper';
import Recruitments from './Recruitments';
import BlindCvs from './BlindCvs';

enum CandidateMenu {
  Summary = 'Summary',
  Recruitments = 'Recruitments',
  CV = 'CV',
  BlindCvs = 'Blind CVs',
}

interface ApplicantPanelProps {
  candidateId: string;
  recruitmentId: string;
}

const ApplicantPanel = ({ candidateId, recruitmentId }: ApplicantPanelProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [tab, setTab] = useState<CandidateMenu>(CandidateMenu.Summary);
  const theme = useTheme();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [candidate, setCandidate] = useState<Applicant>({
    answers: [],
    applicantContact: '',
    applicantName: '',
    applicantSurname: '',
    daysSinceUpload: 0,
    id: '',
    keywords: [],
    processingStatus: 'Never',
    score: 0,
    scoreDetails: { booleanScore: 0, embeddingScore: 0, keywordScore: 0 },
    status: RecruitmentCvStatus.NotAssigned,
    trinaryAnswers: [],
    uploadDate: '',
    notes: '',
    recruitments: [],
    blindCvs: [],
  });

  const getCandidateData = async () => {
    if (!recruitmentId || !candidateId) return;
    setIsPending(true);
    try {
      const candidate = await gptRecruiterService.getCandidateData({
        recruitmentId,
        candidateId,
      });
      setCandidate(candidate);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    getCandidateData();
  }, [candidateId]);

  return (
    <Grid container>
      <Grid item xs={12} lg={3} padding={theme.spacing(1)}>
        <CandidateWrapper>
          {isPending ? (
            <SpinnerContainer sx={{ height: '100%' }}>
              <CircularProgress sx={{ margin: 'auto' }} />
            </SpinnerContainer>
          ) : (
            <CandidatePanel
              candidate={candidate}
              setCandidate={setCandidate}
              recruitmentId={recruitmentId}
              candidateId={candidateId}
            />
          )}
        </CandidateWrapper>
      </Grid>
      <Grid item xs={12} lg={9} padding={theme.spacing(1)}>
        <StyledTabs
          value={tab}
          onChange={(_, val) => setTab(val)}
          variant="fullWidth"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab value={CandidateMenu.Summary} label={CandidateMenu.Summary} />
          <Tab value={CandidateMenu.CV} label={CandidateMenu.CV} />
          <Tab value={CandidateMenu.Recruitments} label={CandidateMenu.Recruitments} />
          <Tab value={CandidateMenu.BlindCvs} label={CandidateMenu.BlindCvs} />
        </StyledTabs>
        <MainWrapper>
          {isPending ? (
            <SpinnerContainer sx={{ height: '100%' }}>
              <CircularProgress sx={{ margin: 'auto' }} />
            </SpinnerContainer>
          ) : (
            <Stepper
              steps={{
                [CandidateMenu.Summary]: (
                  <Summary
                    candidate={candidate}
                    setCandidate={setCandidate}
                    recruitmentId={recruitmentId}
                    candidateId={candidateId}
                  />
                ),
                [CandidateMenu.CV]: <CV recruitmentId={recruitmentId} candidateId={candidate.id} />,
                [CandidateMenu.Recruitments]: <Recruitments candidate={candidate} setCandidate={setCandidate} />,
                [CandidateMenu.BlindCvs]: (
                  <BlindCvs blindCvs={candidate.blindCvs} recruitmentId={recruitmentId} candidateId={candidate.id} />
                ),
              }}
              activeStep={tab}
            />
          )}
        </MainWrapper>
      </Grid>
    </Grid>
  );
};

export default ApplicantPanel;

const CandidateWrapper = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  [theme.breakpoints.down('lg')]: { height: 'auto' },
}));

const MainWrapper = styled(Card)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));
const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const SpinnerContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import CreateOrganizationForm from '../components/CreateOrganization/CreateOrganizationForm';
import Logo from '../components/logo';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const Wrapper = styled(Card)(({ theme }) => ({
  maxWidth: '90%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(5),
  gap: theme.spacing(1),
}));

export default function CreateOrganization() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('createOrganization.title')}</title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Wrapper
          sx={{
            display: { xs: 'flex', md: 'block' },
            justifyContent: { xs: 'flex-start', md: 'center' },
          }}
        >
          <Typography variant="h4">
            {t('createOrganization.heading')}
          </Typography>
          <Typography gutterBottom sx={{ my: 0 }}>
            {t('createOrganization.AlreadyHaveAccount')}
            <NavLink to="/login">{t('createOrganization.login')}</NavLink>
          </Typography>

          <CreateOrganizationForm />

        </Wrapper>
      </StyledRoot>
    </>
  );
}

import { TFunction } from 'i18next';
import { RecruitmentCvStatus, RecruitmentStatus } from '../services/gptRecruiterService';

interface UseTranslationDictionary {
  t: TFunction<'translation', undefined, 'translation'>;
}
export const useTranslationDictionary = ({ t }: UseTranslationDictionary) => {
  const cvStatusListDictionary = {
    [RecruitmentCvStatus.NotAssigned]: t('recruitment.applicantsStatus.notAssigned'),
    [RecruitmentCvStatus.Accepted]: t('recruitment.applicantsStatus.accepted'),
    [RecruitmentCvStatus.Maybe]: t('recruitment.applicantsStatus.maybe'),
    [RecruitmentCvStatus.Rejected]: t('recruitment.applicantsStatus.rejected'),
  };
  const statusListDictionary = {
    [RecruitmentStatus.Opened]: t('recruitment.tableRecruitmentStatus.Opened'),
    [RecruitmentStatus.InProgress]: t('recruitment.tableRecruitmentStatus.InProgress'),
    [RecruitmentStatus.Closed]: t('recruitment.tableRecruitmentStatus.Closed'),
  };
  return { cvStatusListDictionary, statusListDictionary };
};

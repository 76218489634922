import React, { useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Iconify from '../iconify';

interface SocialMedia {
    name: string;
    logo: string;
    description: string;
}

const socialMedias: SocialMedia[] = [
    {
        name: "Facebook",
        logo: "mdi:facebook",
        description: "Post for Facebook",
    },
    {
        name: "LinkedIn",
        logo: "mdi:linkedin",
        description: "Post for LinkedIn",
    },
    {
        name: "Twitter",
        logo: "mdi:twitter",
        description: "tweet on Twitter",
    },
    {
        name: "Instagram",
        logo: "mdi:instagram",
        description: "Post for Instagram",
    },
];

const StyledCard = styled(Card)(({ theme }: { theme: any; }) => ({
    textAlign: 'center',
    padding: theme.spacing(2),
    transition: '0.3s',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'rgba(33, 43, 54, 0.10)',
    },
    position: 'relative',
}));

const TickIcon = styled(Iconify)(({ theme }) => ({
    position: 'absolute',
    top: '10px',
    left: '10px',
    color: theme.palette.primary.main
}));

interface SocialMediaToggleProps {
    selected: string | null;
    setSelected: (newSelected: string | null) => void;
}

const SocialMediaToggle: React.FC<SocialMediaToggleProps> = ({ selected, setSelected }) => {

    const handleChange = (event: React.MouseEvent<HTMLElement>, newSelected: string) => {
        setSelected(newSelected);
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" flexWrap="wrap">
                {socialMedias.map((media) => (
                    <StyledCard
                        key={media.name}
                        onClick={(e) => handleChange(e, media.name)}
                    >
                        {selected === media.name && <TickIcon
                            icon="subway:tick"
                            width={25}
                        />}
                        <Iconify
                            icon={media.logo}
                            sx={{ width: '50px', height: '50px', color: '#4e4e4e' }}
                            width={25}
                        />
                        <Stack direction="column" spacing={0} justifyContent="center" alignItems="center">
                            <Typography variant="h5" fontWeight="bold">{media.name}</Typography>
                            <Typography variant="body2">{media.description}</Typography>
                        </Stack>
                    </StyledCard>
                ))}
            </Stack>
        </Box>
    );
};

export default SocialMediaToggle;

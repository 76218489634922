import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton, Popover, Select, styled, Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import account from '../../../_mock/account';
import userService from '../../../services/userService';
import languageService, { Languages } from '../../../services/languageService';

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userEmail = userService.getUserInfo();

  const languageOptions = Object.entries(Languages).map(([key, value]) => ({ title: t(`language.${key}`), value }));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    userService.logoutUserAndRedirect();
  };

  const handleManageSubscription = async () => {
    try {
      const { redirectUrl } = await userService.stripeCustomerPortal();
      window.location.replace(redirectUrl);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      handleClose();
    }
  };

  const handleChangeLanguage = (language: Languages) => {
    i18n.changeLanguage(language).then((t) => {
      t('key');
      languageService.setLanguage(language);
      handleClose();
    });
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open
            ? {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }
            : {}),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userEmail}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userEmail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ m: 1 }}>
          <StyledSmallTypography>{t('chooseLanguage')}</StyledSmallTypography>
          <StyledBasicSelect value={i18n.language}>
            {languageOptions.map(({ value, title }) => (
              <MenuItem key={value} value={value} onClick={() => handleChangeLanguage(value)}>
                {title}
              </MenuItem>
            ))}
          </StyledBasicSelect>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {userService.checkIfUserIsOrganizationAdmin() && (
          <MenuItem onClick={handleManageSubscription} sx={{ m: 1 }}>
            {t('manageSubscription')}
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t('logout')}
        </MenuItem>
      </Popover>
    </>
  );
}

const StyledBasicSelect = styled(Select)(({ theme }) => ({
  width: '100%',

  '& .MuiSelect-select': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(0.5, 1),

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

const StyledSmallTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey[600],
  marginBottom: theme.spacing(0.5),
}));

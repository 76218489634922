/* eslint-disable no-unused-vars */
import { Box, Button, FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Iconify from '../../iconify';
import gptRecruiterService, { Recruitment, RecruitmentMetadata } from '../../../services/gptRecruiterService';

interface RecruitmentsSelectProps {
    applicantRecrutations: Array<RecruitmentMetadata>;
    organizationReceruitments: Array<Recruitment>;
    applicantCvEmbeddedId: string;
}

interface selectRecruitmentsType {
    id: string;
    name: string;
    isApplicantIn: boolean;
}

interface MenuItemProps {
    recruitmentId: string;
    recruitmentName: string;
    isSelectable: boolean;
    uploadCvToRecruitment: (recruitmentId: string) => Promise<void>;
}
const RecruitmentMenuItem = (props: MenuItemProps) => {
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState(false);
    const [isSelectable, setIsSelectable] = useState(props.isSelectable);

    const handleUploadCv = async () => {
        setIsUploading(true);

        if(!isSelectable){
            setIsUploading(false);
            return;
        }

        try {
            console.log(props)
            await props.uploadCvToRecruitment(props.recruitmentId)
            setIsSelectable(false);
        } finally {
            setIsUploading(false);
        }
    }

    return (
        <MenuItem key={props.recruitmentId} value={props.recruitmentId} onClick={handleUploadCv}>
            <ApplicantStatusButtonsWrapper>

                {isUploading && <StyledButton > <Iconify icon="svg-spinners:180-ring" /> </StyledButton>}
                {!isUploading &&
                    <StyledButton
                        variant='outlined'
                        disabled={!isSelectable}
                        color="success">
                        {t('recruitmentSearch.applicantCard.addToRecruitmentButton')}
                    </StyledButton>
                }

                <MenuItemTextWrapper >
                    {props.recruitmentName}
                </MenuItemTextWrapper>

            </ApplicantStatusButtonsWrapper>



        </MenuItem>
    )
}

const RecruitmentsSelect = ({ organizationReceruitments, applicantRecrutations, applicantCvEmbeddedId }: RecruitmentsSelectProps) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [selectedRecruitment, setSelectedRecruitment] = useState<string[]>(applicantRecrutations.map(x => x.recruitmentId));
    const [selectRecruitments, setSelectRecruitments] = useState<Array<selectRecruitmentsType>>([]);

    const [open, setOpen] = useState(false);


    const handleChange = async (value: string) => {

        try {
            await gptRecruiterService.assignCandidateToRecruitment({ recruitmentId: value, cvEmbeddingId: applicantCvEmbeddedId });
            enqueueSnackbar(t('recruitmentSearch.applicantCard.addedToRecruitment'), { variant: 'success' });

            const newValue = [...selectedRecruitment, value];
            setSelectedRecruitment(newValue);

            setSelectRecruitments(organizationReceruitments.reduce((acc, curr) => {
                const isApplicantIn = !!newValue.find(x => x === curr.id);
                acc.push({ id: curr.id, name: curr.name, isApplicantIn })
                return acc;
            }, [] as Array<selectRecruitmentsType>))

            setOpen(false);
        } catch (error) {
            enqueueSnackbar(t('recruitmentSearch.applicantCard.addedToRecruitmentError'), { variant: 'error' });
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setSelectRecruitments(organizationReceruitments.reduce((acc, curr) => {
            const isApplicantIn = !!applicantRecrutations.find(x => x.recruitmentId === curr.id);
            acc.push({ id: curr.id, name: curr.name, isApplicantIn })
            return acc;
        }, [] as Array<selectRecruitmentsType>))

    }, [applicantRecrutations, organizationReceruitments])

    return (
        <ButtonWrapper>
            <ApplicantStatusButtonsWrapper>
                <FormControl fullWidth size="small" sx={{ display: 'flex', width: '100%' }}>
                    <InputLabel id="demo-simple-select-label">{t('recruitmentSearch.applicantCard.recruitmentSelecLabel')}</InputLabel>
                    <Select
                        multiple
                        value={selectedRecruitment}
                        renderValue={() => "Select recruitments"}

                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                    >
                        {selectRecruitments.map((recruitment) =>
                        (
                            <RecruitmentMenuItem
                                key={recruitment.id}
                                recruitmentId={recruitment.id}
                                recruitmentName={recruitment.name}
                                isSelectable={!recruitment.isApplicantIn}
                                uploadCvToRecruitment={handleChange} />
                        ))}
                    </Select>
                </FormControl>
            </ApplicantStatusButtonsWrapper>
        </ButtonWrapper>
    );
};

export default RecruitmentsSelect;


const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(2),
    },
}));

const MenuItemTextWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    // width: '20%',
    maxWidth: '150px',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
    },
    minHeight: '2em',
    maxHeight: '2em',
}));

const ApplicantStatusButtonsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    width: '100%',
    overflow: 'wrap',
    justifySelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/iconify';
import { emailRegexPattern } from '../../../utils/regexes';
import userService from '../../../services/userService';
import organizationService from '../../../services/organizationService';

interface FormValues {
  email: string;
  password: string;
}

export default function LoginForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, register, formState } = form;
  const { errors } = formState;

  const onSubmit = async ({ email, password }: FormValues) => {
    try {
      const { token, refreshToken } = await userService.loginUser(email, password);
      userService.setUserTokensToLocalStorage(token, refreshToken);
      enqueueSnackbar(t('login.loginSuccess'), { variant: 'success' });

      await organizationService.getIsSubscriptionPaid();

      navigate('/dashboard', { replace: true });
    } catch (error: any) {
      let errorMessage = t('login.loginError');
      if ('isOrganizationAdmin' in error) {
        errorMessage = t('login.subscriptionExpired');
      }
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField
          label={t('login.emailAddress')}
          {...register('email', {
            required: { value: true, message: t('login.emailAddressRequired') },
            pattern: {
              value: emailRegexPattern,
              message: t('login.emailAddressInvalid'),
            },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        <TextField
          label={t('login.password')}
          type={showPassword ? 'text' : 'password'}
          {...register('password', {
            required: { value: true, message: t('login.passwordRequired') },
          })}
          error={!!errors.password}
          helperText={errors.password?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 3 }}>
        {t('login.logIn')}
      </LoadingButton>
    </form>
  );
}

import { Avatar, Box, Button, Divider, Grid, LinearProgress, styled, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import CircularProgressWithLabel from '../search/CircularProgressWithLabel';
import gptRecruiterService, { Applicant, RecruitmentCvStatus } from '../../../services/gptRecruiterService';
import { LinearProgressWithLabel } from '../../shared/LinearProgressWithLabel';

interface CandidatePanelProps {
  candidate: Applicant;
  setCandidate: (candidate: Applicant) => void;
  recruitmentId: string | undefined;
  candidateId: string | undefined;
}

const CandidatePanel = ({ candidate, setCandidate, recruitmentId, candidateId }: CandidatePanelProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const changeCandidateStatus = async (status: RecruitmentCvStatus) => {
    if (!recruitmentId || !candidateId) return;
    try {
      const updatedStatus = candidate.status === status ? RecruitmentCvStatus.NotAssigned : status;
      await gptRecruiterService.setStatusRecruitmentByIdCV({ recruitmentId, status: updatedStatus, cvId: candidateId });
      setCandidate({ ...candidate, status: updatedStatus });
      enqueueSnackbar({ variant: 'success', message: t('recruitment.statusUpdated') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };
  return (
    <>
      <Avatar sx={{ width: '5rem', height: '5rem' }}>
        {candidate.applicantName ? candidate.applicantName[0] : ''}
      </Avatar>
      <Typography
        fontSize={'1.5rem'}
        fontWeight={600}
      >{`${candidate.applicantName} ${candidate.applicantSurname}`}</Typography>
      <CandidateInfoWrapper>
        <Divider />
        <Box padding={theme.spacing(1)}>
          {candidate.applicantContact?.split(',').map((contact, idx) => (
            <Typography key={idx}>{contact.trim()}</Typography>
          ))}
        </Box>
        <Divider />
        <Box padding={theme.spacing(1)}>
          <Grid container gap={theme.spacing(1)}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant={candidate.status === RecruitmentCvStatus.Accepted ? 'contained' : 'outlined'}
                color="success"
                onClick={() => changeCandidateStatus(RecruitmentCvStatus.Accepted)}
              >
                {t('accept')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant={candidate.status === RecruitmentCvStatus.Maybe ? 'contained' : 'outlined'}
                color="info"
                onClick={() => changeCandidateStatus(RecruitmentCvStatus.Maybe)}
              >
                {t('maybe')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant={candidate.status === RecruitmentCvStatus.Rejected ? 'contained' : 'outlined'}
                color="error"
                onClick={() => changeCandidateStatus(RecruitmentCvStatus.Rejected)}
              >
                {t('decline')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box padding={theme.spacing(1)}>
          <Typography>{t('candidatePanel.cvUploaded', { days: candidate.daysSinceUpload })}</Typography>
        </Box>
        <Divider />
        {candidate.score > 0 && (
          <Box padding={theme.spacing(1)}>
            <StyledTypography>{t('candidatePanel.score')}</StyledTypography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgressWithLabel
                size="9em"
                value={candidate.score * 100}
                sx={{ margin: 'auto', width: '100%' }}
              />
            </Box>
            <Box>
              <StyledTypography>{t('candidatePanel.scoreDetails.title')}</StyledTypography>
              <Typography>{t('candidatePanel.scoreDetails.boolean')}</Typography>
              <LinearProgressWithLabel value={candidate.scoreDetails.booleanScore * 100} />
              <Typography>{t('candidatePanel.scoreDetails.embedement')}</Typography>
              <LinearProgressWithLabel value={candidate.scoreDetails.embeddingScore * 100} />
              <Typography>{t('candidatePanel.scoreDetails.keywords')}</Typography>
              <LinearProgressWithLabel value={candidate.scoreDetails.keywordScore * 100} />
            </Box>
          </Box>
        )}
      </CandidateInfoWrapper>
    </>
  );
};

export default CandidatePanel;

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  textAlign: 'center',
}));

const CandidateInfoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
}));

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Collapse,
  Chip,
  List,
  ListItem,
  ListItemText,
  Badge,
  Stack,
  Card,
  useTheme,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../iconify';
import { Answer, KeywordFragments, Recruitment, RecruitmentMetadata } from '../../../services/gptRecruiterService';
import ApplicantCardActions from './ApplicantCardActions';
import CircularProgressWithLabel from '../search/CircularProgressWithLabel';

interface ApplicantCardProps {
  contactData: string[];
  name: string;
  id: string;
  answers: Answer[] | null;
  score: number;
  keywords: KeywordFragments[];
  daysSinceUpload: number;
  recruitments: RecruitmentMetadata[];
  listPosition: number;
  organizationReceruitments: Array<Recruitment>;
}

const SearchApplicantCard = ({
  contactData,
  name,
  id,
  answers,
  score,
  keywords,
  daysSinceUpload,
  recruitments,
  listPosition,
  organizationReceruitments,
}: ApplicantCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState<KeywordFragments | null>(keywords[0] ?? null);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const renderContactData = () => contactData.map((contact, index) => <Typography key={index}>{contact}</Typography>);

  const renderKeywords = () =>
    keywords.length === 0 ? (
      <Typography fontWeight={500} color={theme.palette.grey[600]}>
        {t('recruitmentSearch.applicantCard.noKeywords')}
      </Typography>
    ) : (
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {keywords.map((keyword, index) => (
          <>
            {keyword.rank > 0 ? (
              <Badge
                key={index}
                badgeContent={`${keyword.fragments.length}${keyword.fragments.length === 10 ? '+' : ''}`}
                color="primary"
                max={10}
              >
                <StyledKeywordChip
                  isSelected={keyword === selectedKeyword}
                  icon={<Iconify icon={keyword === selectedKeyword ? 'bi:arrow-up' : 'bi:arrow-down'} />}
                  variant="outlined"
                  clickable
                  label={keyword.keyword}
                  onClick={() => setSelectedKeyword(keyword === selectedKeyword ? null : keyword)}
                />
              </Badge>
            ) : (
              <StyledKeywordChip variant="outlined" label={keyword.keyword} isSelected={false} />
            )}
          </>
        ))}
      </Stack>
    );

  const renderKeywordFragments = () => (
    <StyledKeywordFragmentsList>
      <Collapse in={!!selectedKeyword}>
        <List dense>
          {selectedKeyword?.fragments.map((fragment, index) => (
            <ListItem key={index}>
              <ListItemText>
                <div dangerouslySetInnerHTML={{ __html: fragment.replace(/<</g, '<b>').replace(/>>/g, '</b>') }} />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </StyledKeywordFragmentsList>
  );

  const renderAnswers = () =>
    answers?.length ? (
      <>
        <StyledChip
          label={t('recruitment.answers')}
          icon={<Iconify icon={isOpen ? 'bi:arrow-up' : 'bi:arrow-down'} sx={{ marginRight: 1 }} />}
          onClick={toggleCollapse}
        />

        <Collapse in={isOpen}>
          {answers.map(({ question, answer }, index) => (
            <Box key={index} sx={{ marginTop: 3 }}>
              <Typography fontWeight={600} sx={{ marginBottom: 1 }}>
                {question}
              </Typography>

              <Typography fontWeight={500} color={theme.palette.grey[600]}>
                {answer}
              </Typography>
            </Box>
          ))}
        </Collapse>
      </>
    ) : null;

  return (
    <StyledApplicantBox>
      <StyledApplicantContactBox>
        <FlexBox>
          <Box>
            <Typography>{name}</Typography>
            {renderContactData()}
          </Box>
          {recruitments?.length > 0 && (
            <ApplicantCardActions
              applicantRecrutations={recruitments}
              organizationReceruitments={organizationReceruitments}
              applicantCvEmbeddedId={id}
            />
          )}
        </FlexBox>

        <InfoBox
          isScoreAndKeywords={keywords?.length > 0 && !!selectedKeyword && selectedKeyword?.fragments.length !== 0}
        >
          <DetailBox>
            <Box>
              <StyledTypography>{t('recruitmentSearch.applicantCard.DaysSinceUpload')}</StyledTypography>
              <StyledTypography>{daysSinceUpload}</StyledTypography>
            </Box>
            {score === null || score === undefined || score === 0 ? null : (
              <Box>
                <StyledTypography>{t('recruitmentSearch.applicantCard.score')}</StyledTypography>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgressWithLabel size="9em" value={score * 100} sx={{ margin: 'auto', width: '100%' }} />
                </Box>
              </Box>
            )}
          </DetailBox>

          <ContentBox>
            <Typography fontWeight={600} sx={{ marginBottom: 1 }}>
              {t('recruitmentSearch.applicantCard.keywords')}
            </Typography>

            {renderKeywords()}
            {renderKeywordFragments()}
          </ContentBox>
        </InfoBox>

        {renderAnswers()}
      </StyledApplicantContactBox>
    </StyledApplicantBox>
  );
};

export default SearchApplicantCard;

const StyledApplicantContactBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
}));

const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',

  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(4),
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const InfoBox = styled(Box)<{ isScoreAndKeywords: boolean }>(({ theme, isScoreAndKeywords }) => ({
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  justifyContent: 'start',
  width: '100%',
  gap: theme.spacing(4),
  marginTop: theme.spacing(4),
  minHeight: isScoreAndKeywords ? '16em' : 'auto',
  border: '1px solid #ddd',
  borderRadius: theme.shape.borderRadius,
  paddingY: theme.spacing(1),
}));

const DetailBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  minWidth: '20%',
  padding: theme.spacing(1),
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  width: '100%',
  padding: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  textAlign: 'center',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1rem',
  padding: theme.spacing(1),
  cursor: 'pointer',
}));

const StyledKeywordChip = styled(Chip)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1rem',
  padding: theme.spacing(1),
  background: isSelected ? theme.palette.primary.lighter : theme.palette.background.default,
}));

const StyledKeywordFragmentsList = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1rem',
  maxHeight: '10em',
  overflowY: 'auto',
  marginTop: theme.spacing(2),
}));

const StyledApplicantBox = styled(Card)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '150px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

import {
  styled,
  Box,
  Button,
  CircularProgress,
  Typography,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import gptRecruiterService, { RecruitmentStatus } from '../../services/gptRecruiterService';

interface RecruitmentDocumentsProps {
  recruitmentId: string | undefined;
  onFileUploaded?: () => void;
  disabled: boolean;
}

interface FileToUploadProps {
  file: File;
  uploadingStatus: 'Queued' | 'Uploading' | 'Uploaded' | 'Error' | 'Duplicate';
}

const RecruitmentDocuments = ({ recruitmentId, onFileUploaded = () => null, disabled }: RecruitmentDocumentsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState<Array<FileToUploadProps>>([]);
  const [fileUploadPending, setFileUploadPending] = useState(false);
  const [uploadingPercentageProgress, setUploadingPercentageProgress] = useState(0);
  const [skipDuplicates, setSkipDuplicates] = useState(true);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const addAcceptedFiles = acceptedFiles.map((file) => ({ file, uploadingStatus: 'Queued' } as FileToUploadProps));
    setSelectedFiles((prevFiles) => [...prevFiles, ...addAcceptedFiles]);
  }, []);

  const onDropZip = useCallback(
    (acceptedFiles: File[]) => {
      const addAcceptedFiles = acceptedFiles.map((file) => ({ file, uploadingStatus: 'Queued' } as FileToUploadProps));
      if (selectedFiles.length > 0) {
        enqueueSnackbar({ variant: 'error', message: t('recruitment.onlyOneZip') });
        return;
      }
      setSelectedFiles((prevFiles) => [...prevFiles, ...addAcceptedFiles]);
    },
    [selectedFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'docs/': ['.doc', '.docx', '.pdf', '.txt', '.csv', '.xls', '.xlsx'] },
    disabled,
    onDrop,
  });

  const {
    getRootProps: getZipRootProps,
    getInputProps: getZipInputProps,
    isDragActive: isZipDragActive,
  } = useDropzone({
    accept: { 'application/zip': ['.zip'] },
    disabled,
    onDrop: onDropZip,
  });

  const uploadFiles = async () => {
    if (!recruitmentId || !selectedFiles) return;

    if (disabled) {
      enqueueSnackbar({ variant: 'error', message: t('recruitment.questionsNotSet') });
      return;
    }

    setFileUploadPending(true);
    setUploadingPercentageProgress(1);

    const zipFile = selectedFiles.find((file) => file.file.name.endsWith('.zip'));
    if (zipFile) {
      try {
        await gptRecruiterService.uploadZipDocument({ recruitmentId, file: zipFile.file });
        zipFile.uploadingStatus = 'Uploaded';
        enqueueSnackbar({ variant: 'success', message: t('recruitment.cvUploaded') });
      } catch (error) {
        enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      } finally {
        setFileUploadPending(false);

        onFileUploaded();

        setUploadingPercentageProgress(100);
      }
      return;
    }

    const simultanousUploadCount = 5;

    try {
      let i = 0;
      while (i < selectedFiles.length) {
        const promises = [];

        // Iterate through the chunk and create promises
        for (let j = i; j < i + simultanousUploadCount && j < selectedFiles.length; j++) {
          selectedFiles[j].uploadingStatus = 'Uploading';
          promises.push(
            gptRecruiterService
              .uploadDocument({ recruitmentId, file: selectedFiles[j].file, skipDuplicates })
              .then((result) => {
                selectedFiles[j].uploadingStatus = 'Uploaded';
                if (result?.id === '00000000-0000-0000-0000-000000000000') {
                  selectedFiles[j].uploadingStatus = 'Duplicate';
                }
              })
              .catch(() => {
                selectedFiles[j].uploadingStatus = 'Error';
              })
          );
        }

        // eslint-disable-next-line no-await-in-loop
        await Promise.all(promises);

        setSelectedFiles([...selectedFiles]);

        i = i + simultanousUploadCount >= selectedFiles.length ? selectedFiles.length : i + simultanousUploadCount;
        setUploadingPercentageProgress(Math.ceil((i / selectedFiles.length) * 100));
      }

      enqueueSnackbar({ variant: 'success', message: t('recruitment.cvUploaded') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setFileUploadPending(false);
      onFileUploaded();
    }
  };

  const handleDeleteFile = (index: number) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const UploadingComponent = () => <CircularProgress size={20} />;

  const QueuedComponent = ({ onDelete, index }: { onDelete: any; index: number }) => (
    <Button variant="outlined" color="error" size="small" onClick={() => onDelete(index)}>
      {t('recruitment.removeFile')}
    </Button>
  );

  const UploadedComponent = () => (
    <Button variant="outlined" color="success" size="small">
      {t('recruitment.uploadedFile')}
    </Button>
  );

  const DuplicatedComponent = () => (
    <Button variant="outlined" color="warning" size="small">
      {t('recruitment.DuplicatedCv')}
    </Button>
  );

  const ErrorComponent = () => (
    <Button variant="contained" color="error" size="small">
      {t('recruitment.errorUploadingFile')}
    </Button>
  );

  const FileStatus = ({
    selectedFile,
    index,
    handleDeleteFile,
  }: {
    selectedFile: FileToUploadProps;
    index: number;
    handleDeleteFile: any;
  }) => {
    switch (selectedFile.uploadingStatus) {
      case 'Uploading':
        return <UploadingComponent />;
      case 'Queued':
        return <QueuedComponent onDelete={handleDeleteFile} index={index} />;
      case 'Uploaded':
        return <UploadedComponent />;
      case 'Duplicate':
        return <DuplicatedComponent />;
      case 'Error':
        return <ErrorComponent />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <UploadFileSection>
        <div {...getRootProps()}>
          <input {...getInputProps()} multiple />
          <Button variant="outlined" disabled={fileUploadPending || disabled}>
            {isDragActive ? t('dropFilesHere') : t('uploadFile')}
          </Button>
        </div>

        <div {...getZipRootProps()}>
          <input {...getZipInputProps()} />
          <Button variant="outlined" disabled={fileUploadPending || disabled}>
            {isZipDragActive ? t('dropFilesHere') : t('uploadZipFile')}
          </Button>
        </div>

        <Tooltip
          title={
            <>
              <p>{t('recruitment.skipDuplicatesTooltip')}</p>
            </>
          }
        >
          <FormControlLabel
            control={
              <Checkbox
                disabled={!selectedFiles.length || fileUploadPending}
                checked={skipDuplicates}
                onChange={(e) => {
                  setSkipDuplicates(e.target.checked);
                }}
              />
            }
            label={t('recruitment.skipDuplicatesLabel')}
          />
        </Tooltip>

        <Button disabled={!selectedFiles.length || fileUploadPending} variant="contained" onClick={uploadFiles}>
          {t('send')}
          {fileUploadPending && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
        </Button>
      </UploadFileSection>
      <FilesWrapper>
        {uploadingPercentageProgress > 0 && uploadingPercentageProgress < 100 && (
          <LinearProgress variant="determinate" value={uploadingPercentageProgress} />
        )}

        {!!selectedFiles.length &&
          selectedFiles.map((selectedFile, index) => (
            <StyledFile key={`selectedFile.file.name${index}`}>
              <Typography>{selectedFile?.file?.name}</Typography>
              <div key={index}>
                <FileStatus selectedFile={selectedFile} index={index} handleDeleteFile={handleDeleteFile} />
              </div>
            </StyledFile>
          ))}
      </FilesWrapper>
    </Wrapper>
  );
};

export default RecruitmentDocuments;

const UploadFileSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(3),
  marginTop: theme.spacing(1),
}));

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const FilesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledFile = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
}));

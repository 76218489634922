import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../scrollbar';
import RecruitmentsListElement from './RecruitmentsListElement';
import { Recruitment } from '../../services/gptRecruiterService';

interface RecruitmentsListProps {
  recruitments: Array<Recruitment>;
  isPending: boolean;
  getRecruitments: () => Promise<void>;
}

interface ContainerStyledProps {
  'data-ispending': boolean;
}

const RecruitmentsList = ({ recruitments, isPending, getRecruitments }: RecruitmentsListProps) => {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { id: 'name', label: t('recruitments.table.name') },
    { id: 'cvNumber', label: t('recruitments.table.uploadedCv') },
    { id: 'status', label: t('recruitments.table.status') },
    { id: '' },
  ];

  useEffect(() => {
    getRecruitments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPending)
    return (
      <Container data-ispending={isPending}>
        <CircularProgress />
      </Container>
    );

  return (
    <Scrollbar sx={scrollbarStyles}>
      <Container data-ispending={isPending}>
        {recruitments.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map(({ id, label }) => (
                    <TableCell key={id}>{label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {recruitments.map((recruitment) => (
                  <RecruitmentsListElement key={recruitment.id} recruitment={recruitment} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box sx={{ margin: 'auto' }}>
            <Typography variant="h4">{t('recruitments.noRecruimentCreated')}</Typography>
          </Box>
        )}
      </Container>
    </Scrollbar>
  );
};

export default RecruitmentsList;

const Container = styled(Box)<ContainerStyledProps>(({ theme, 'data-ispending': isPending }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isPending ? 'center' : 'flex-start',
  alignItems: isPending ? 'center' : 'flex-start',
  flex: 1,
  gap: theme.spacing(3),
  overflow: 'auto',
}));

const scrollbarStyles = {
  height: 1,
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
};

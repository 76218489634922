import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { BlindCvDataResponse } from '../../../../services/blindCvService';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/assets/fonts/noto-sans.ttf', fontWeight: 'normal' },
    { src: '/assets/fonts/NotoSans-Bold.ttf', fontWeight: 'bold' },
    { src: '/assets/fonts/NotoSans-Italic.ttf', fontWeight: 'normal', fontStyle: 'italic' },
    { src: '/assets/fonts/NotoSans-BoldItalic.ttf', fontWeight: 'bold', fontStyle: 'italic' },
  ],
});

interface BlindCvPdfDocProps {
  blindCvData: BlindCvDataResponse | undefined;
  image: string | null;
  padding: { top: number; sides: number; bottom: number };
  includeInBlind: { name: boolean; lastname: boolean; email: boolean };
}
type ColumnData = {
  [key: number]: string[];
};
export const BlindCvPdfDoc = ({ blindCvData, image, padding, includeInBlind }: BlindCvPdfDocProps) => {
  const styles = StyleSheet.create({
    page: {
      position: 'relative',
      fontFamily: 'Roboto',
      height: '100%',
      width: '100%',
      fontSize: 11,
      lineHeight: 1.5,
      flexDirection: 'column',
      zIndex: 1,
      paddingTop: padding.top,
      paddingBottom: padding.bottom,
      paddingLeft: padding.sides,
      paddingRight: padding.sides,
    },
    background: {
      position: 'absolute',
      zIndex: -1,
      width: '100vw',
      height: '100vh',
    },

    divider: {
      fontSize: 16,
      fontWeight: 'bold',
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      borderBottomStyle: 'solid',
      marginTop: 10,
      marginBottom: 10,
    },

    nameAndLastnameContainer: { flexDirection: 'row', gap: 5, justifyContent: 'center' },

    name: { fontSize: 16, textAlign: 'center', fontWeight: 'bold' },
    email: { textAlign: 'center', fontWeight: 'normal' },

    sectionTitle: { fontSize: 16, textAlign: 'left', fontWeight: 'bold' },
    description: { marginTop: 6 },

    subsectionTitle: { fontWeight: 'bold' },
    subsectionTime: { fontSize: 8, fontStyle: 'italic', fontWeight: 'bold' },

    skillsSectionContainer: { marginTop: 6, flexDirection: 'row' },
    skillsSubsectionContainer: {
      width: '33%',
      flexDirection: 'column',
    },

    row: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '95%',
      flexWrap: 'wrap',
    },
  });

  const { t } = useTranslation();

  const CandidateKeyInfo = () => (
    <View>
      <View style={styles.nameAndLastnameContainer}>
        {includeInBlind.name && blindCvData?.candidateName && blindCvData?.candidateName.length > 0 && (
          <Text style={styles.name}>{blindCvData?.candidateName}</Text>
        )}
        {includeInBlind.lastname && blindCvData?.candidateLastName && blindCvData?.candidateLastName.length > 0 && (
          <Text style={styles.name}>{blindCvData?.candidateLastName}</Text>
        )}
      </View>
      {includeInBlind.email && blindCvData?.candidateEmail && blindCvData?.candidateEmail.length > 0 && (
        <Text style={styles.email}>{blindCvData?.candidateEmail}</Text>
      )}
    </View>
  );

  const CandidateDescription = () => (
    <View>
      <Text style={styles.sectionTitle}>{t('blindPDF.profileSummary')}</Text>
      <Text style={styles.description}>{blindCvData?.candidateDescription}</Text>
    </View>
  );

  const Experience = () => (
    <View>
      <Text style={styles.sectionTitle}>{t('blindPDF.employmentHistory')}</Text>
      {blindCvData?.previousProjects.map((previousProject, index) => (
        <View style={styles.description} key={index}>
          <Text style={styles.subsectionTitle}>{previousProject.position}</Text>
          <Text style={styles.subsectionTitle}>{previousProject.companyName}</Text>
          <Text style={styles.subsectionTime}>{previousProject.time}</Text>
          {previousProject.details && <Text>{previousProject.details}</Text>}
          {previousProject.detailsList &&
            previousProject.detailsList.map((detail, index) => (
              <View key={index} style={styles.row}>
                <Text>{`• ${detail}`}</Text>
              </View>
            ))}
        </View>
      ))}
    </View>
  );

  const KeySkills = () => {
    const data = blindCvData ? blindCvData.keySkills : [];
    const initialValue: ColumnData = { 0: [], 1: [], 2: [] };
    const columns = data.reduce((acc, curr, idx) => {
      acc[idx % 3].push(curr);
      return acc;
    }, initialValue);
    return (
      <View>
        <Text style={styles.sectionTitle}>{t('blindPDF.keySkills')}</Text>
        <View style={styles.skillsSectionContainer}>
          {Object.entries(columns).map(([key, array]) => (
            <View key={key} style={styles.skillsSubsectionContainer}>
              {array.map((skill, index) => (
                <View key={index} style={styles.row}>
                  <Text>{`• ${skill}`}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </View>
    );
  };

  const Education = () => (
    <View>
      <Text style={styles.sectionTitle}>{t('blindPDF.educationAndQualifications')}</Text>
      {blindCvData?.educationAndQualification.map((education, index) => (
        <View style={styles.description} key={index}>
          <Text style={styles.subsectionTitle}>{education.name}</Text>
          <Text style={styles.subsectionTime}>{education.time}</Text>
          <Text>{education.detail}</Text>
        </View>
      ))}
    </View>
  );

  const Languages = () => (
    <View>
      <Text style={styles.sectionTitle}>{t('blindPDF.languages')}</Text>
      <View style={styles.description}>
        {blindCvData?.languages.map((language, index) => (
          <View key={index} style={styles.row}>
            <Text>{`• ${language}`}</Text>
          </View>
        ))}
      </View>
    </View>
  );

  return (
    <Document>
      {blindCvData ? (
        <Page size="A4" style={styles.page} wrap>
          {image && image.length > 0 && <Image style={styles.background} src={image} fixed />}
          <View>
            {Object.entries(includeInBlind).some(([_, val]) => val) && (
              <>
                <CandidateKeyInfo />
                <View style={styles.divider} />
              </>
            )}

            {blindCvData?.candidateDescription && blindCvData?.candidateDescription.length > 0 && (
              <>
                <CandidateDescription />
                <View style={styles.divider} />
              </>
            )}

            {blindCvData?.previousProjects && blindCvData?.previousProjects.length > 0 && (
              <>
                <Experience />
                <View style={styles.divider} />
              </>
            )}

            {blindCvData?.keySkills && blindCvData?.keySkills.length > 0 && (
              <>
                <KeySkills />
                <View style={styles.divider} />
              </>
            )}

            {blindCvData?.educationAndQualification && blindCvData?.educationAndQualification.length > 0 && (
              <>
                <Education />
                <View style={styles.divider} />
              </>
            )}

            {blindCvData?.languages && blindCvData?.languages.length > 0 && <Languages />}
          </View>
        </Page>
      ) : (
        <Page size="A4" style={styles.page} wrap>
          <Text>{t('blindPDF.somethingWentWrong')}</Text>
        </Page>
      )}
    </Document>
  );
};

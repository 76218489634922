import { Box, MenuItem, Select, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { EditTrinaryAnswersProps } from './Summary';

interface EditTrinaryQuestionsActionsProps {
  type: boolean | null;
  question: string;
  updateTrinaryAnswers: (props: EditTrinaryAnswersProps) => void;
}

const EditTrinaryQuestionsActions = ({ type, question, updateTrinaryAnswers }: EditTrinaryQuestionsActionsProps) => {
  const { t } = useTranslation();
  const { control } = useForm<{ questionType: string }>({
    defaultValues: { questionType: String(type) },
  });

  const handleChangeType = (newType: string) => {
    const booleanType = newType === 'true' ? true : newType === 'false' ? false : null;
    updateTrinaryAnswers({ question, newType: booleanType });
  };

  return (
    <FormWrapper>
      <Controller
        name="questionType"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              handleChangeType(e.target.value);
            }}
          >
            <MenuItem value="true">{t('yes')}</MenuItem>
            <MenuItem value="null">{t('maybe')}</MenuItem>
            <MenuItem value="false">{t('no')}</MenuItem>
          </Select>
        )}
      />
    </FormWrapper>
  );
};

export default EditTrinaryQuestionsActions;

const FormWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));

import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface PageTemplateProps {
  pageTitle?: string;
  children?: JSX.Element;
}

const containerFlexStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

export default function PageTemplate({ pageTitle, children }: PageTemplateProps) {
  const location = useLocation();
  const pathName = location.pathname;

  const metaHeadTitle = pageTitle || pathName;

  return (
    <>
      <Helmet>
        <title>{metaHeadTitle}</title>
      </Helmet>

      <Container maxWidth="xl" sx={containerFlexStyles}>
        {children}
      </Container>
    </>
  );
}

import { forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { Box, SxProps } from '@mui/material';

interface IconifyProps {
  icon: string;
  width?: number | string;
  sx?: SxProps;
}

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }: IconifyProps, ref) => (
  <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));

export default Iconify;

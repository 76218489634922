/* eslint-disable no-unused-vars */
import { styled, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Tier } from '../../services/userService';
import PricingCard from './PricingCard';

interface PricingPlansListProps {
  pricingPlans: Array<Tier>;
  handleSubscribe: (accessPlanId: number) => Promise<void>;
  adonType: string;
}

const PricingPlansList = ({ pricingPlans, handleSubscribe, adonType }: PricingPlansListProps) => {
  const { t } = useTranslation();
  const translationKeys = ['trial','low', 'medium', 'high'];

  pricingPlans.sort((a, b) => a.monthlyRateValue - b.monthlyRateValue);

  return (
    <CardWrapper>
      {pricingPlans.map(({ accessPlanId, monthlyRateValue, name }, index) => {
        const features = t(`pricingPlans.cards.${adonType}.${translationKeys[index]}.features`, {
          returnObjects: true,
        }) as Array<string>;
        return (
          <PricingCard
            key={accessPlanId}
            features={features}
            accessPlanId={accessPlanId}
            monthlyRateValue={monthlyRateValue}
            name={name}
            handleSubscribe={handleSubscribe}
            translationKeys={translationKeys}
            index={index}
            adonType={adonType}
          />
        );
      })}
    </CardWrapper>
  );
};

export default PricingPlansList;

const CardWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

/* eslint-disable no-unused-vars */
import axiosInstance from '../axiosInstance';
import { BlindCvStatus } from './blindCvService';

export enum RecruitmentStatus {
  Opened = 'Opened',
  InProgress = 'InProgress',
  Closed = 'Closed',
}

export enum RecruitmentCvStatus {
  NotAssigned = 'NotAssigned',
  Accepted = 'Accepted',
  Maybe = 'Maybe',
  Rejected = 'Declined',
}

export enum MagicDescriptionPromptType {
  SkillSetList = 'SkillSetList',
  JobListing = 'JobListing',
  PerfectCandidate = 'PerfectCandidate',
}

export interface Recruitment {
  id: string;
  name: string;
  status: RecruitmentStatus;
  createdAt: string;
  numberOfUploadedCvs: number;
  questions: Array<string>;
  binaryQuestions: Array<string>;
}
export interface CandidateRecruitments {
  id: string;
  candidateStatus: number;
  recruitmentStatus: number;
  recruitmentName: string;
}

export interface CandidateBlindCvs {
  createdAt: string;
  id: string;
  language: string;
  status: BlindCvStatus;
}

export interface Answer {
  question: string;
  answer: string;
}
export interface TrinaryAnswer {
  question: string;
  answer: boolean | null;
}
export interface Applicant {
  id: string;
  applicantName: string | null;
  applicantSurname: string | null;
  applicantContact: string | null;
  answers: Array<Answer> | null;
  trinaryAnswers: Array<TrinaryAnswer> | null;
  uploadDate: string;
  status: RecruitmentCvStatus;
  processingStatus: 'Never' | 'Finished' | 'Started' | 'Failed' | 'Queued';
  score: number;
  scoreDetails: { booleanScore: number; embeddingScore: number; keywordScore: number };
  keywords: KeywordFragments[];
  daysSinceUpload: number;
  notes: string;
  recruitments: CandidateRecruitments[];
  blindCvs: CandidateBlindCvs[];
}

export interface RecruitmentMetadata {
  recruitmentId: string;
  cvDataId: string;
  cvFileId: string;
}

export interface ApplicantOrganizationSearch {
  cvEmbeddingId: string;
  answers: Array<Answer> | null;
  score: number;
  keywords: KeywordFragments[];
  daysSinceUpload: number;
  listPosition: number;
  applicantName: string | null;
  applicantSurname: string | null;
  applicantContact: string | null;
  recruitments: RecruitmentMetadata[];
}

export interface ApplicantSearch extends Applicant {
  cvEmbeddingId: string;
  cvDataId: string;
  answers: Array<Answer> | null;
  score: number;
  keywords: KeywordFragments[];
  daysSinceUpload: number;
  listPosition: number;
}

export interface KeywordFragments {
  keyword: string;
  rank: number;
  fragments: string[];
}

export interface Comment {
  comment: string;
  createdAt: string;
  commentId: string;
}

export interface Comments {
  comments: Array<Comment>;
}
export interface RecruitmentCvFile {
  binaryData: Blob;
  fileName: string;
  fileType: string;
}

export interface RecruitmentProcessingStatus {
  cvCount: number;
  indexingStatusCounter: { [key: string]: number };
  embeddingStatusCounter: { [key: string]: number };
}

export interface UpdateCandidateDataProps {
  recruitmentId: string;
  cvId: string;
  applicantName: string;
  applicantSurname: string;
  applicantContact: string;
  answers: Array<Answer>;
  trinaryAnswers: Array<TrinaryAnswer>;
}

async function createRecruitment(name: string): Promise<Recruitment> {
  try {
    const response = await axiosInstance.post('/recruitment', { name });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getActiveRecruitments(): Promise<Array<Recruitment>> {
  try {
    const response = await axiosInstance.get('/recruitment/active');
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getRecruitments(status: RecruitmentStatus | null = null): Promise<Array<Recruitment>> {
  try {
    const response = await axiosInstance.get('/recruitment', { params: { status } });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getRecruitmentById(id: string): Promise<Recruitment> {
  try {
    const response = await axiosInstance.get(`/recruitment/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function updateRecruitmentQuestions(
  id: string,
  questions: Array<string>,
  binaryQuestions: Array<string>,
  name: string
): Promise<Recruitment> {
  try {
    const response = await axiosInstance.put(`/recruitment/${id}`, { questions, binaryQuestions, name });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function updateRecruitmentStatus(id: string, status: RecruitmentStatus): Promise<Recruitment> {
  try {
    const response = await axiosInstance.put(`/recruitment/${id}/status`, { status });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function uploadDocument({
  recruitmentId,
  file,
  skipDuplicates,
}: {
  recruitmentId: string;
  file: File;
  skipDuplicates: boolean;
}): Promise<{ id: string }> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('recruitmentId', recruitmentId);
  formData.append('skipDuplicate', skipDuplicates.toString());

  const headers = { 'Content-Type': 'multipart/form-data' };

  const response = await axiosInstance.post(`/recruitment/${recruitmentId}/cv`, formData, { headers });
  return response.data;
}

async function uploadZipDocument({ recruitmentId, file }: { recruitmentId: string; file: File }): Promise<void> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('recruitmentId', recruitmentId);

  const headers = { 'Content-Type': 'multipart/form-data' };

  const response = await axiosInstance.post(`/recruitment/${recruitmentId}/cv/batch-import`, formData, { headers });
  return response.data;
}

async function getRecruitmentByIdCV({
  recruitmentId,
  status,
  keywords,
  daysSinceUpload,
  useSmartMatch,
}: {
  recruitmentId: string;
  status: RecruitmentCvStatus | null;
  keywords: string[];
  daysSinceUpload: number | null;
  useSmartMatch: boolean;
}): Promise<Array<Applicant>> {
  try {
    const response = await axiosInstance.get(`/recruitment/${recruitmentId}/cv`, {
      params: { status, keywords, maxDaysSinceUpload: daysSinceUpload, useSmartMatch },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getApplicantStatusStats({
  recruitmentId,
}: {
  recruitmentId: string;
}): Promise<{ [key in RecruitmentCvStatus]: number }> {
  try {
    const response = await axiosInstance.get(`/recruitment/${recruitmentId}/status-stats`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getSearchRecruitment({
  recruitmentId,
  question,
  keywords,
  DaysSinceUpload,
}: {
  recruitmentId: string;
  question: string;
  keywords: string[];
  DaysSinceUpload: number;
}): Promise<Array<ApplicantSearch>> {
  try {
    const response = await axiosInstance.post(`/recruitment/${recruitmentId}/search`, {
      question,
      keywords,
      MaxDaysSinceUpload: DaysSinceUpload,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getSearchOrganizationApplicants({
  question,
  keywords,
  DaysSinceUpload,
}: {
  question: string;
  keywords: string[];
  DaysSinceUpload: number;
}): Promise<Array<ApplicantOrganizationSearch>> {
  try {
    const response = await axiosInstance.post(`/recruitment/applicants/search`, {
      question,
      keywords,
      MaxDaysSinceUpload: DaysSinceUpload,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getMagicDescription(magicDescription: string, magicPromptType: string): Promise<string> {
  try {
    const response = await axiosInstance.post(`/recruitment/magic-description`, {
      simpleDescription: magicDescription,
      promptType: magicPromptType,
    });
    return response.data.result;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getRecruitmentProcessingStatus({
  recruitmentId,
}: {
  recruitmentId: string;
}): Promise<RecruitmentProcessingStatus> {
  try {
    const response = await axiosInstance.get(`/recruitment/${recruitmentId}/processing-status`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function setStatusRecruitmentByIdCV({
  recruitmentId,
  cvId,
  status,
}: {
  recruitmentId: string;
  status: RecruitmentCvStatus;
  cvId: string;
}): Promise<void> {
  try {
    const response = await axiosInstance.post(`/recruitment/${recruitmentId}/cv/${cvId}/set-status`, {
      status,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function assignCandidateToRecruitment({
  recruitmentId,
  cvEmbeddingId,
}: {
  recruitmentId: string;
  cvEmbeddingId: string;
}): Promise<void> {
  try {
    const response = await axiosInstance.post(`/recruitment/${recruitmentId}/cv/add-from-organization`, {
      cvEmbeddingId,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function addCandidateToOtherRecruitment({
  recruitmentId,
  cvId,
  newRecruitmentId,
}: {
  recruitmentId: string;
  cvId: string;
  newRecruitmentId: string;
}): Promise<void> {
  try {
    const response = await axiosInstance.post(`/recruitment/${recruitmentId}/cv/${cvId}/add-to-recruitment`, {
      newRecruitmentId,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getCandidateData({
  recruitmentId,
  candidateId,
}: {
  recruitmentId: string;
  candidateId: string;
}): Promise<Applicant> {
  try {
    const response = await axiosInstance.get(`/recruitment/${recruitmentId}/cv/${candidateId}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}
async function downloadCvById({
  recruitmentId,
  cvId,
}: {
  recruitmentId: string;
  cvId: string;
}): Promise<RecruitmentCvFile> {
  try {
    const response = await axiosInstance.get(`/recruitment/${recruitmentId}/cv/${cvId}/download`, {
      responseType: 'blob',
    });

    const fileName =
      response.headers['content-disposition'].split('filename=')[1]?.split(';')[0]?.replace(/"/g, '') ?? 'cv';
    const fileType = response.headers['content-type'];

    return {
      binaryData: response.data,
      fileName,
      fileType,
    } as RecruitmentCvFile;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function exportCvByStatus({
  recruitmentId,
  status,
}: {
  recruitmentId: string;
  status: RecruitmentCvStatus | null;
}): Promise<RecruitmentCvFile> {
  try {
    const response = await axiosInstance.get(`/recruitment/${recruitmentId}/cv/export`, {
      responseType: 'blob',
      params: { status },
    });

    const fileName =
      response.headers['content-disposition'].split('filename=')[1]?.split(';')[0]?.replace(/"/g, '') ?? 'cv';
    const fileType = response.headers['content-type'];

    return {
      binaryData: response.data,
      fileName,
      fileType,
    } as RecruitmentCvFile;
  } catch (error: any) {
    throw new Error(error);
  }
}
async function updateNotes({
  recruitmentId,
  candidateId,
  notes,
}: {
  recruitmentId: string;
  candidateId: string;
  notes: string;
}): Promise<void> {
  try {
    const response = await axiosInstance.post(`/recruitment/${recruitmentId}/cv/${candidateId}/update-notes`, {
      notes,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}
async function addComment({
  recruitmentId,
  candidateId,
  comment,
}: {
  recruitmentId: string;
  candidateId: string;
  comment: string;
}): Promise<void> {
  try {
    const response = await axiosInstance.post(`/recruitment/${recruitmentId}/cv/${candidateId}/comment`, { comment });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getComments({
  recruitmentId,
  candidateId,
}: {
  recruitmentId: string;
  candidateId: string;
}): Promise<Comments> {
  try {
    const response = await axiosInstance.get(`/recruitment/${recruitmentId}/cv/${candidateId}/comment`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function updateCandidateData({
  recruitmentId,
  cvId,
  applicantName,
  applicantSurname,
  applicantContact,
  answers,
  trinaryAnswers,
}: UpdateCandidateDataProps): Promise<Recruitment> {
  const payload = {
    applicantName,
    applicantSurname,
    applicantContact,
    answers,
    trinaryAnswers,
  };
  try {
    const response = await axiosInstance.put(`/recruitment/${recruitmentId}/cv/${cvId}`, payload);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export default {
  updateCandidateData,
  createRecruitment,
  getActiveRecruitments,
  getRecruitments,
  getRecruitmentById,
  updateRecruitmentQuestions,
  updateRecruitmentStatus,
  uploadDocument,
  uploadZipDocument,
  getRecruitmentByIdCV,
  getApplicantStatusStats,
  setStatusRecruitmentByIdCV,
  downloadCvById,
  exportCvByStatus,
  getSearchRecruitment,
  getRecruitmentProcessingStatus,
  getSearchOrganizationApplicants,
  getMagicDescription,
  assignCandidateToRecruitment,
  addCandidateToOtherRecruitment,
  getCandidateData,
  getComments,
  updateNotes,
  addComment,
};

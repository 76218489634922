/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Collapse,
  Chip,
  List,
  ListItem,
  ListItemText,
  Badge,
  Stack,
  Card,
  useTheme,
  styled,
  Grid,
  Divider,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Iconify from '../../iconify';
import { Answer, KeywordFragments, RecruitmentCvStatus, TrinaryAnswer } from '../../../services/gptRecruiterService';
import ApplicantCardActions from '../ApplicantCardActions';
import CircularProgressWithLabel from './CircularProgressWithLabel';

interface ApplicantCardProps {
  contactData: string[];
  name: string;
  id: string;
  answers: Answer[] | null;
  trinaryAnswers: TrinaryAnswer[] | null;
  status: RecruitmentCvStatus;
  score: number;
  keywords: KeywordFragments[];
  daysSinceUpload: number;
  params: {
    status: RecruitmentCvStatus | null;
    keywords: string[];
    daysSinceUpload: number | null;
    useSmartMatch: boolean;
  };
}

const SearchApplicantCard = ({
  contactData,
  name,
  id,
  answers,
  trinaryAnswers,
  status,
  score,
  keywords,
  daysSinceUpload,
  params,
}: ApplicantCardProps) => {
  const params2string = Object.entries(params).reduce<Record<string, string>>((acc, [key, value]) => {
    if (value) {
      acc[key] = Array.isArray(value) ? value.join(',') : String(value);
    }
    return acc;
  }, {});
  const parsedParams = new URLSearchParams(params2string);

  const { t } = useTranslation();
  const theme = useTheme();
  const { recruitmentId } = useParams<{ recruitmentId: string }>();
  const [isOpen, setIsOpen] = useState(status === 'NotAssigned');
  const [selectedKeyword, setSelectedKeyword] = useState<KeywordFragments | null>(keywords[0] ?? null);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const renderContactData = () => contactData.map((contact, index) => <Typography key={index}>{contact}</Typography>);

  const renderKeywords = () =>
    keywords.length === 0 ? (
      <Typography fontWeight={500} color={theme.palette.grey[600]}>
        {t('recruitmentSearch.applicantCard.noKeywords')}
      </Typography>
    ) : (
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {keywords.map((keyword, index) => (
          <>
            {keyword.rank > 0 ? (
              <Badge
                key={index}
                badgeContent={`${keyword.fragments.length}${keyword.fragments.length === 10 ? '+' : ''}`}
                color="primary"
                max={10}
              >
                <StyledKeywordChip
                  isSelected={keyword === selectedKeyword}
                  icon={<Iconify icon={keyword === selectedKeyword ? 'bi:arrow-up' : 'bi:arrow-down'} />}
                  variant="outlined"
                  clickable
                  label={keyword.keyword}
                  onClick={() => setSelectedKeyword(keyword === selectedKeyword ? null : keyword)}
                />
              </Badge>
            ) : (
              <StyledKeywordChip variant="outlined" label={keyword.keyword} isSelected={false} />
            )}
          </>
        ))}
      </Stack>
    );

  const renderKeywordFragments = () => (
    <StyledKeywordFragmentsList>
      <Collapse in={!!selectedKeyword}>
        <List dense>
          {selectedKeyword?.fragments.map((fragment, index) => (
            <ListItem key={index}>
              <ListItemText>
                <div dangerouslySetInnerHTML={{ __html: fragment.replace(/<</g, '<b>').replace(/>>/g, '</b>') }} />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </StyledKeywordFragmentsList>
  );

  const renderAnswers = () =>
    answers?.length
      ? answers.map(({ question, answer }, index) => (
          <Box key={index} sx={{ marginBottom: 3 }}>
            <Typography fontWeight={600} sx={{ marginBottom: 1 }}>
              {question}
            </Typography>

            <Typography fontWeight={500} color={theme.palette.grey[600]}>
              {answer}
            </Typography>
          </Box>
        ))
      : null;
  const renderTrinaryAnswers = () =>
    trinaryAnswers?.length ? (
      <Grid container>
        <Grid item md={12} lg={4} marginBottom={1} width={'100%'}>
          <Typography fontWeight={600} color={theme.palette.success.main}>
            {t('recruitmentSearch.applicantCard.yes', {
              count: trinaryAnswers.filter((res) => res.answer === true).length,
            })}
          </Typography>
          <Divider sx={{ borderColor: theme.palette.success.main, width: '80%' }} />
          {trinaryAnswers
            .filter((res) => res.answer === true)
            .map(({ question }, id) => (
              <Typography fontWeight={400} sx={{ marginBottom: 1, width: '80%' }} key={id}>
                <Iconify icon={'mdi:check'} sx={{ paddingTop: 1, color: theme.palette.success.main }} />
                {question}
              </Typography>
            ))}
        </Grid>
        <Grid item md={12} lg={4} marginBottom={1} width={'100%'}>
          <Typography fontWeight={600} color={theme.palette.info.main}>
            {t('recruitmentSearch.applicantCard.maybe', {
              count: trinaryAnswers.filter((res) => res.answer === null).length,
            })}
          </Typography>
          <Divider sx={{ borderColor: theme.palette.info.main, width: '80%' }} />
          {trinaryAnswers
            .filter((res) => res.answer === null)
            .map(({ question }, id) => (
              <Typography fontWeight={400} sx={{ marginBottom: 1, width: '80%' }} key={id}>
                <Iconify icon={'mdi:help'} sx={{ paddingTop: 1, color: theme.palette.info.main }} />
                {question}
              </Typography>
            ))}
        </Grid>
        <Grid item md={12} lg={4} marginBottom={1} width={'100%'}>
          <Typography fontWeight={600} color={theme.palette.error.main}>
            {t('recruitmentSearch.applicantCard.no', {
              count: trinaryAnswers.filter((res) => res.answer === false).length,
            })}
          </Typography>
          <Divider sx={{ borderColor: theme.palette.error.main, width: '80%' }} />
          {trinaryAnswers
            .filter((res) => res.answer === false)
            .map(({ question }, id) => (
              <Typography fontWeight={400} sx={{ marginBottom: 1, width: '80%' }} key={id}>
                <Iconify icon={'mdi:close'} sx={{ paddingTop: 1, color: theme.palette.error.main }} />
                {question}
              </Typography>
            ))}
        </Grid>
      </Grid>
    ) : null;

  return (
    <StyledApplicantBox>
      <StyledApplicantContactBox>
        <FlexBox>
          <Box>
            <Typography>{name}</Typography>
            {renderContactData()}
            <Button
              component={RouterLink}
              disabled={!id || !recruitmentId}
              to={`/dashboard/gptrecruiter/recruitments/${recruitmentId}/applications/${id}?${parsedParams}`}
              variant="outlined"
            >
              {t('candidatePanel.expandCandidateView')}
            </Button>
          </Box>
          <ApplicantCardActions id={id} status={status} />
        </FlexBox>

        <InfoBox isScoreAndKeywords={!!keywords && !!selectedKeyword && selectedKeyword?.fragments.length !== 0}>
          <DetailBox>
            <Box>
              <StyledTypography>{t('recruitmentSearch.applicantCard.DaysSinceUpload')}</StyledTypography>
              <StyledTypography>{daysSinceUpload}</StyledTypography>
            </Box>
            {score === null || score === undefined || score === 0 ? null : (
              <Box>
                <StyledTypography>{t('recruitmentSearch.applicantCard.score')}</StyledTypography>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgressWithLabel size="9em" value={score * 100} sx={{ margin: 'auto', width: '100%' }} />
                </Box>
              </Box>
            )}
          </DetailBox>

          <ContentBox>
            <Typography fontWeight={600} sx={{ marginBottom: 1 }}>
              {t('recruitmentSearch.applicantCard.keywords')}
            </Typography>

            {renderKeywords()}
            {renderKeywordFragments()}
          </ContentBox>
        </InfoBox>
        <StyledChip
          label={t('recruitment.answers')}
          icon={<Iconify icon={isOpen ? 'bi:arrow-up' : 'bi:arrow-down'} sx={{ marginRight: 1 }} />}
          onClick={toggleCollapse}
        />

        <Collapse in={isOpen}>
          <Grid container>
            {trinaryAnswers?.length ? (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: {
                      xs: 'block',
                      md: 'none',
                    },
                  }}
                >
                  <Divider />
                  <Typography fontWeight={600} padding={1} textAlign={'center'}>
                    {t('recruitment.binaryQuestions')}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6} padding={1}>
                  {renderTrinaryAnswers()}
                </Grid>
              </>
            ) : null}
            {answers?.length ? (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: {
                      xs: 'block',
                      md: 'none',
                    },
                  }}
                >
                  <Divider />
                  <Typography fontWeight={600} padding={1} textAlign={'center'}>
                    {t('recruitment.questions')}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6} padding={1}>
                  {renderAnswers()}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Collapse>
      </StyledApplicantContactBox>
    </StyledApplicantBox>
  );
};

export default SearchApplicantCard;

const StyledApplicantContactBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: 1,
}));

const FlexBox = styled(Box)(({ theme }) => ({
  display: 'flex',

  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const InfoBox = styled(Box)<{ isScoreAndKeywords: boolean }>(({ theme, isScoreAndKeywords }) => ({
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  justifyContent: 'start',
  width: '100%',
  gap: theme.spacing(4),
  marginTop: theme.spacing(4),
  minHeight: isScoreAndKeywords ? '16em' : 'auto',
  border: '1px solid #ddd',
  borderRadius: theme.shape.borderRadius,
  paddingY: theme.spacing(1),
}));

const DetailBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
  minWidth: '20%',
  padding: theme.spacing(1),
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  width: '100%',
  padding: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  textAlign: 'center',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1rem',
  padding: theme.spacing(1),
  cursor: 'pointer',
}));

const StyledKeywordChip = styled(Chip)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1rem',
  padding: theme.spacing(1),
  background: isSelected ? theme.palette.primary.lighter : theme.palette.background.default,
}));

const StyledKeywordFragmentsList = styled(Box)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1rem',
  maxHeight: '10em',
  overflowY: 'auto',
  marginTop: theme.spacing(2),
}));

const StyledApplicantBox = styled(Card)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '150px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

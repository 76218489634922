import {
  Button,
  TextField,
  styled,
  Typography,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Iconify from '../iconify';
import organizationService, { User } from '../../services/organizationService';
import { emailRegexPattern } from '../../utils/regexes';
import { UserRoles, SetableUserRoles, passwordPattern } from '../../services/userService';

export interface OrganizationUsersFormValue {
  email: string;
  password?: string;
  role: UserRoles;
}

interface UserMenagmentFormProps {
  fetchUsers: () => void;
  selectedUser: User | undefined;
  setSelectedUser: Dispatch<SetStateAction<User | undefined>>;
}

const UserMenagmentForm = ({ fetchUsers, selectedUser, setSelectedUser }: UserMenagmentFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitButtonPending, setIsSubmitButtonPending] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const emailInputLabel = t('organizationUsers.emailInputLabel');
  const emailInputLabelPlaceholder = t('organizationUsers.emailInputPlaceholder');
  const emailInputRequired = t('organizationUsers.emailInputRequired');
  const passwordInputLabel = t('organizationUsers.passwordInputLabel');
  const passwordInputLabelPlaceholder = t('organizationUsers.passwordInputPlaceholder');
  const passwordInputRequired = t('organizationUsers.passwordInputRequired');

  const userRoles = Object.entries(SetableUserRoles)
    .map(([key, value]) => ({
      value,
      label: t(`userRoles.${key}`),
    }))
    .filter(({ value }) => typeof value === 'string');

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<OrganizationUsersFormValue>({
    defaultValues: {
      email: '',
      password: '',
      role: UserRoles.User,
    },
  });

  const initialReset = () => {
    reset({ password: '', email: '', role: UserRoles.User });
  };

  useEffect(() => {
    if (selectedUser?.id) {
      reset({ email: selectedUser.email, role: selectedUser.role, password: '' });
    }
  }, [selectedUser, reset]);

  const addUser = async (user: OrganizationUsersFormValue) => {
    setIsSubmitButtonPending(true);
    try {
      await organizationService.addOrganizationUser(user);
      fetchUsers();
      initialReset();
      enqueueSnackbar({ variant: 'success', message: t('organizationUsers.userAdded') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsSubmitButtonPending(false);
    }
  };

  const editUser = async (user: OrganizationUsersFormValue) => {
    if (!selectedUser?.id) return;
    setIsSubmitButtonPending(true);
    if (!user.password) {
      delete user.password;
    }
    try {
      await organizationService.editOrganizationUser(selectedUser.id, user);
      fetchUsers();
      initialReset();
      setSelectedUser(undefined);
      enqueueSnackbar({ variant: 'success', message: t('organizationUsers.userEdited') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsSubmitButtonPending(false);
    }
  };

  const onSubmit = async (data: OrganizationUsersFormValue) => {
    if (selectedUser) {
      editUser(data);
    } else {
      addUser(data);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} id="user-menagment-form">
      <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
        {selectedUser ? t('organizationUsers.editUserButton') : t('organizationUsers.addUserButton')}
      </Typography>
      <TextField
        {...register('email', {
          required: {
            value: true,
            message: emailInputRequired,
          },
          pattern: {
            value: emailRegexPattern,
            message: t('login.emailAddressInvalid'),
          },
        })}
        label={emailInputLabel}
        placeholder={emailInputLabelPlaceholder}
        fullWidth
        InputLabelProps={{ shrink: !!watch('email') }}
        error={Boolean(errors.email)}
        helperText={errors.email?.message}
      />
      <TextField
        type={passwordVisible ? 'text' : 'password'}
        {...register('password', {
          required: {
            value: !selectedUser,
            message: passwordInputRequired,
          },
          pattern: {
            value: passwordPattern,
            message: t('createOrganization.passwordInvalid'),
          },
        })}
        label={passwordInputLabel}
        placeholder={passwordInputLabelPlaceholder}
        fullWidth
        error={Boolean(errors.password)}
        helperText={errors.password?.message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setPasswordVisible(!passwordVisible)} edge="end">
                <Iconify icon={passwordVisible ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Controller
        name="role"
        control={control}
        render={({ field }) => (
          <FormControl variant="outlined" fullWidth>
            <InputLabel>{t('organizationUsers.userRole')}</InputLabel>
            <Select {...field} label={t('organizationUsers.userRole')}>
              {userRoles.map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 3 }}>
        {selectedUser && (
          <Button
            variant="outlined"
            sx={{ maxWidth: '150px', alignSelf: 'flex-end', padding: 1.5, marginTop: 2 }}
            disabled={isSubmitButtonPending}
            onClick={() => {
              setSelectedUser(undefined);
              initialReset();
            }}
          >
            {t('undo')}
          </Button>
        )}

        <Button
          variant="contained"
          type="submit"
          sx={{ maxWidth: '150px', alignSelf: 'flex-end', padding: 1.5, marginTop: 2 }}
          disabled={isSubmitButtonPending}
        >
          {selectedUser ? t('organizationUsers.editUserButton') : t('organizationUsers.addUserButton')}
        </Button>
      </Box>
    </StyledForm>
  );
};

export default UserMenagmentForm;

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

import { Box, Button, CircularProgress, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/userContext';
import { Verbs } from '../../services/userService';

interface ContextListHeadingProps {
  openModal: () => void;
  isCreateContextPending: boolean;
}

const ContextListHeading = ({ openModal, isCreateContextPending }: ContextListHeadingProps) => {
  const { t } = useTranslation();
  const heading = t('contexts.heading');
  const subHeading = t('contexts.subHeading');
  const createNewContext = t('contexts.createNewContext');
  const { hasAccess } = useContext(UserContext);

  return (
    <HeadingSection>
      <Box>
        <Typography color="text" variant="h4">
          {heading}
        </Typography>
        <SubHeading>{subHeading}</SubHeading>
      </Box>
      {hasAccess([Verbs.ChatContextManage]) && (
        <Button
          sx={{ alignSelf: 'flex-end' }}
          variant="contained"
          color="primary"
          onClick={openModal}
          size="small"
          disabled={isCreateContextPending}
        >
          {createNewContext}
          {isCreateContextPending && <CircularProgress color="success" sx={{ marginLeft: 1 }} size={20} />}
        </Button>
      )}
    </HeadingSection>
  );
};

export default ContextListHeading;

const HeadingSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

import { Box, Chip, CircularProgress, IconButton, InputAdornment, TextField, styled } from '@mui/material';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KeyboardEvent } from 'react';
import useFocusAfterSubmit from '../../hooks/useFocusAfterSubmit';
import Iconify from '../iconify';
import { PrivateChatFormValues } from '../../pages/PrivateChat';

interface ChatTextFieldProps {
  isPending: boolean;
  onSubmit: SubmitHandler<PrivateChatFormValues>;
  togglePromptsModal: () => void;
  toggleChatHistoryModal: () => void;
}

const ChatTextField = ({ isPending, onSubmit, togglePromptsModal, toggleChatHistoryModal }: ChatTextFieldProps) => {
  const { t } = useTranslation();
  const textFieldRef = useFocusAfterSubmit({ isPending });
  const { register, handleSubmit, formState } = useFormContext<PrivateChatFormValues>();
  const { errors } = formState;
  const inputPlaceholder = t('privateChat.inputPlaceholder');

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <StyledWrapper>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <StyledChip
          icon={<Iconify icon="fluent:open-16-filled" />}
          label={t('privateChat.prompts.heading')}
          onClick={togglePromptsModal}
          disabled={isPending}
        />
        <StyledChip
          icon={<Iconify icon="fluent-mdl2:history" />}
          label={t('privateChat.chatHistory.heading')}
          onClick={toggleChatHistoryModal}
          disabled={isPending}
        />
      </Box>
      <StyledInput
        inputRef={textFieldRef}
        {...register('message', {
          required: { value: true, message: t('privateChat.inputMessageRequired') },
        })}
        error={!!errors.message}
        helperText={errors.message?.message}
        multiline
        maxRows={15}
        placeholder={inputPlaceholder}
        onKeyDown={handleKeyPress}
        disabled={isPending}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ marginRight: 1 }}>
              <IconButton edge="end" onClick={handleSubmit(onSubmit)} disabled={isPending}>
                {isPending ? <CircularProgress size={20} /> : <Iconify icon="mdi:send" />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </StyledWrapper>
  );
};

export default ChatTextField;

const StyledWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(2),
  alignItems: 'flex-start',
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  width: '100%',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: 16,
  padding: theme.spacing(1),
}));

import { Control, Controller } from 'react-hook-form';
import { Box, FormControl, FormGroup, TextField, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BlindCVSettingsProps } from '../../services/organizationService';

interface BlindSpacesFormProps {
  control: Control<BlindCVSettingsProps, any>;
  blindCVSettings: BlindCVSettingsProps;
}

const BlindSpacesForm = ({ control, blindCVSettings }: BlindSpacesFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <FormGroup>
      <Box mb={theme.spacing(2)}>
        <Controller
          name={'padding.top'}
          control={control}
          defaultValue={blindCVSettings.padding.top}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <TextField
                type="number"
                label={t('organizationSettings.blindCVSettings.blindApperanceSettings.spaceTop')}
                {...field}
                value={field.value}
                onChange={(e) => {
                  const value = e.target.value === '' ? '' : Number(e.target.value);
                  if (value === '' || value <= 200) {
                    field.onChange(value);
                  } else {
                    field.onChange(200);
                  }
                }}
                onBlur={() => {
                  if (!field.value) {
                    field.onChange(0);
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Box>
      <Box mb={theme.spacing(2)}>
        <Controller
          name="padding.sides"
          control={control}
          defaultValue={blindCVSettings.padding.sides}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <TextField
                type="number"
                label={t('organizationSettings.blindCVSettings.blindApperanceSettings.spaceSides')}
                {...field}
                value={field.value}
                onChange={(e) => {
                  const value = e.target.value === '' ? '' : Number(e.target.value);
                  if (value === '' || value <= 200) {
                    field.onChange(value);
                  } else {
                    field.onChange(200);
                  }
                }}
                onBlur={() => {
                  if (!field.value) {
                    field.onChange(0);
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Box>
      <Box mb={theme.spacing(2)}>
        <Controller
          name="padding.bottom"
          control={control}
          defaultValue={blindCVSettings.padding.bottom}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl variant="outlined" fullWidth>
              <TextField
                type="number"
                label={t('organizationSettings.blindCVSettings.blindApperanceSettings.spaceBottom')}
                {...field}
                value={field.value}
                onChange={(e) => {
                  const value = e.target.value === '' ? '' : Number(e.target.value);
                  if (value === '' || value <= 200) {
                    field.onChange(value);
                  } else {
                    field.onChange(200);
                  }
                }}
                onBlur={() => {
                  if (!field.value) {
                    field.onChange(0);
                  }
                }}
              />
            </FormControl>
          )}
        />
      </Box>
    </FormGroup>
  );
};

export default BlindSpacesForm;

import { styled, Box, Button, CircularProgress, Typography, LinearProgress, Autocomplete, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import blindCvService, { BlindCvListResponse } from '../../../services/blindCvService';
import Scrollbar from '../../scrollbar';
import RecruitmentsListElement from '../RecruitmentsListElement';
import BlindListElement from './BlindListElement';

interface BlindsOrganizationListProps {
    blindCVs: Array<BlindCvListResponse>;
    isPending: boolean;
}

interface ContainerStyledProps {
    'data-ispending': boolean;
}


const BlindsOrganizationList = ({ blindCVs, isPending }: BlindsOrganizationListProps) => {
    const { t } = useTranslation();

    const TABLE_HEAD = [
        { id: 'name', label: t('blindCvList.table.name') },
        { id: 'fileName', label: t('blindCvList.table.fileName')},
        { id: 'language', label: t('blindCvList.table.language') },
        { id: 'createdAt', label: t('blindCvList.table.createdAt')},
        { id: '' },
    ];

    if (isPending)
        return (
            <Container data-ispending={isPending}>
                <CircularProgress />
            </Container>
        );

    return (
        <Scrollbar sx={scrollbarStyles}>
            <Container data-ispending={isPending}>
                {blindCVs.length ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {TABLE_HEAD.map(({ id, label }) => (
                                        <TableCell key={id}>{label}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {blindCVs.map((blind, i) => (
                                    <BlindListElement key={i} blind={blind} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box sx={{ margin: 'auto' }}>
                        <Typography variant="h4">{t('blindCvList.noBlindCv')}</Typography>
                    </Box>
                )}
            </Container>
        </Scrollbar>
    );
};

export default BlindsOrganizationList;

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const Container = styled(Box)<ContainerStyledProps>(({ theme, 'data-ispending': isPending }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isPending ? 'center' : 'flex-start',
    alignItems: isPending ? 'center' : 'flex-start',
    flex: 1,
    gap: theme.spacing(3),
    overflow: 'auto',
}));

const scrollbarStyles = {
    height: 1,
    '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
  };
  

const ButtonsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    flex: 1,
}));


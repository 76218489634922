import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BlindCVSettingsProps } from '../../services/organizationService';

interface BlindInludeInFormProps {
  control: Control<BlindCVSettingsProps, any>;
  blindCVSettings: BlindCVSettingsProps;
}

const BlindInludeInForm = ({ control, blindCVSettings }: BlindInludeInFormProps) => {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <Controller
        name="includeInBlind.name"
        control={control}
        defaultValue={blindCVSettings.includeInBlind.name}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t('organizationSettings.blindCVSettings.blindApperanceSettings.name')}
          />
        )}
      />
      <Controller
        name="includeInBlind.lastname"
        control={control}
        defaultValue={blindCVSettings.includeInBlind.lastname}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t('organizationSettings.blindCVSettings.blindApperanceSettings.lastname')}
          />
        )}
      />
      <Controller
        name="includeInBlind.email"
        control={control}
        defaultValue={blindCVSettings.includeInBlind.email}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={t('organizationSettings.blindCVSettings.blindApperanceSettings.email')}
          />
        )}
      />
    </FormGroup>
  );
};

export default BlindInludeInForm;

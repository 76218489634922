import { styled } from '@mui/material/styles';
import { Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function SubscriptionExpired() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Typography variant="h3">{t('subscriptionExpired')}</Typography>
    </Wrapper>
  );
}

const Wrapper = styled(Card)(({ theme }) => ({
  maxWidth: '90%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(5),
  gap: theme.spacing(3),
}));

/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import Iconify from '../components/iconify';
import ChatIdentityForm from '../components/chatIdentities/ChatIdentityForm';
import chatService, { ChatIdentity } from '../services/chatService';
import chatIdentityService from '../services/chatIdentityService';
import PageTemplate from './PageTemplate';

export interface ChatIdentityFormValues {
  title: string;
  temperature: number;
  identity: string;
}

export default function ChatIdentities() {
  const { t } = useTranslation();
  const [selectedChatIdentity, setSelectedChatIdentity] = useState<string>('');
  const [chatIdentities, setChatIdentities] = useState<Array<ChatIdentity>>([]);
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);

  const pageTitle = t('chatIdentities.title');

  const form = useForm<ChatIdentityFormValues>({
    defaultValues: {
      title: '',
      temperature: 0,
      identity: '',
    },
  });
  const { handleSubmit } = form;

  const getChatIdentities = async () => {
    try {
      const { chatIdentities } = await chatService.getChatIdentities();
      setChatIdentities(chatIdentities);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  useEffect(() => {
    getChatIdentities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFormValues = (chatIdentity: ChatIdentity) => {
    form.setValue('title', chatIdentity.title);
    form.setValue('temperature', chatIdentity.temperature);
    form.setValue('identity', chatIdentity.identity);
  };

  const setChatIdentity = (chatIdentityId: string) => {
    setSelectedChatIdentity(chatIdentityId);

    const chatIdentity = chatIdentities.find((identity) => identity.id === chatIdentityId);
    if (!chatIdentity) return;
    setFormValues(chatIdentity);
  };

  const currentChatIdentity = chatIdentities.find((identity) => identity.id === selectedChatIdentity);

  const onSelectedChatIdentityChange = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as string;
    if (selectedValue === 'add-new') {
      setSelectedChatIdentity('');
      form.reset();
    } else {
      setChatIdentity(selectedValue);
    }
  };

  const onSubmit: SubmitHandler<ChatIdentityFormValues> = async ({ title, temperature, identity }) => {
    setIsSubmitPending(true);
    let tempselectedChatIdentityId = selectedChatIdentity;
    try {
      if (!selectedChatIdentity) {
        // add new
        const response = await chatIdentityService.createChatIdentity(title, identity, temperature);
        tempselectedChatIdentityId = response.chatIdentity.id;
      } else {
        // update existing
        await chatIdentityService.updateChatIdentity(selectedChatIdentity, title, identity, temperature);
      }

      await getChatIdentities();
      enqueueSnackbar({ variant: 'success', message: t('success') });
      setSelectedChatIdentity(tempselectedChatIdentityId);
      form.reset();
    } catch (error) {
      const errorMessage = t('somethingWentWrong');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setIsSubmitPending(false);
    }
  };

  const deleteChatIdentity = async () => {
    setIsDeletePending(true);
    try {
      if (!selectedChatIdentity) {
        return;
      }
      await chatIdentityService.deleteChatIdentity(selectedChatIdentity);
      await getChatIdentities();
      enqueueSnackbar({ variant: 'success', message: t('success') });
      setSelectedChatIdentity('');
      form.reset();
    } catch (error) {
      const errorMessage = t('somethingWentWrong');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setIsDeletePending(false);
    }
  };

  return (
    <PageTemplate pageTitle={pageTitle}>
      <Container>
        <FormControl fullWidth>
          <FormProvider {...form}>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
              <InputLabel id="select-identity-label">{t('chatIdentities.selectChatIdentity')}</InputLabel>
              <Select
                id="select-identity"
                label={t('chatIdentities.selectChatIdentity')}
                value={selectedChatIdentity}
                onChange={onSelectedChatIdentityChange}
                key={selectedChatIdentity}
              >
                <MenuItem value="add-new">{t('chatIdentities.addNew')}</MenuItem>
                {chatIdentities?.map((identity) => (
                  <MenuItem key={identity.id} value={identity.id}>
                    {identity.title}
                  </MenuItem>
                ))}
              </Select>

              <ButtonContainer>
                <Button
                  variant="outlined"
                  onClick={handleSubmit(onSubmit)}
                  type="submit"
                  color="primary"
                  disabled={isSubmitPending}
                  startIcon={isSubmitPending ? <CircularProgress size={20} /> : <Iconify icon="mdi:check" />}
                >
                  {selectedChatIdentity ? t('chatIdentities.Update') : t('chatIdentities.Add')}
                </Button>

                <Button
                  variant="outlined"
                  onClick={deleteChatIdentity}
                  color="error"
                  disabled={isDeletePending || !selectedChatIdentity}
                  startIcon={isDeletePending ? <CircularProgress size={20} /> : <Iconify icon="mdi:delete" />}
                >
                  {t('chatIdentities.delete')}
                </Button>
              </ButtonContainer>

              <ChatIdentityForm chatIdentity={currentChatIdentity} />
            </FormContainer>
          </FormProvider>
        </FormControl>
      </Container>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  gap: theme.spacing(2),
}));

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography, styled, useTheme } from '@mui/material';
import { useParams, Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PageTemplate from './PageTemplate';
import blindCvService, { BlindCvStatus, BlindCvDataResponse } from '../services/blindCvService';
import CandidateBlindCvResultsFlex from '../components/recruitment/BlindCv/CandidateBlindCvResultsFlex';
import Iconify from '../components/iconify';
import { BlindCvPdfDoc } from '../components/recruitment/BlindCv/BlindCvPdf/BlindCvPdfDoc';
import PdfLinkButton from '../components/recruitment/BlindCv/BlindCvPdf/PdfLink';
import { BlindSettingsContext } from '../contexts/blindSettingsContext';
import { OrganizationSettingsMenu } from './OrganizationSettings';

export default function BlindCvEdit() {
  const { t } = useTranslation();
  const theme = useTheme();
  const pageTitle = t('blindCvEdit.title');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { blindCvId } = useParams<{ blindCvId: string }>();
  const [searchParams] = useSearchParams();
  const fromPath = searchParams.get('fromPath');
  const [backPath, setBackPath] = useState<string>('/dashboard/gptrecruiter/blindCv/list');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [blindCvData, setBlindCvData] = useState<BlindCvDataResponse | undefined>(undefined);
  const { blindCVSettings, organizationBackground } = useContext(BlindSettingsContext);

  const getBlindCvResult = async () => {
    try {
      if (!blindCvId) return;
      const response = await blindCvService.getBlindCv(blindCvId);
      if (response.blindCvData) {
        setBlindCvData(response.blindCvData);
        setIsCompleted(true);
      }
      if (response.status === BlindCvStatus.Failed) {
        setIsCompleted(true);
        setBlindCvData(undefined);
      }
    } catch (error) {
      setIsCompleted(true);
      setBlindCvData(undefined);
      throw new Error('Error fetching recruitment processing status');
    }
  };

  const updateBlindCvData = async (updatedBlindCvData: BlindCvDataResponse) => {
    try {
      if (!blindCvId) return;

      const response = await blindCvService.updateBlindCv(blindCvId, updatedBlindCvData);
      if (response.status === BlindCvStatus.Failed) {
        setIsCompleted(true);
        enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      }
      setBlindCvData(response.blindCvData);
      enqueueSnackbar({ variant: 'success', message: 'Blind updated sucessfully' });
    } catch (error) {
      setIsCompleted(true);
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      throw new Error('Error updating blind CV');
    }
  };

  const handleGoToBlindSettings = () => {
    navigate(`/dashboard/organization-settings?initialTab=${OrganizationSettingsMenu.BlindSettings}`);
  };

  useEffect(() => {
    getBlindCvResult();
  }, [blindCvId]);

  useEffect(() => {
    if (fromPath) {
      setBackPath(fromPath);
    }
  }, [fromPath]);

  return (
    <PageTemplate pageTitle={pageTitle}>
      <>
        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
          <Button
            component={RouterLink}
            to={backPath}
            variant="contained"
            sx={{ maxWidth: '150px', marginX: 3, marginLeft: 'auto' }}
          >
            <Iconify icon="bi:arrow-left" sx={{ marginRight: 1 }} />
            {t('back')}
          </Button>
        </Box>
        <Container>
          <StyledCard>
            <Box>
              <Typography variant="h3">{t('blindCvEdit.heading')}</Typography>
              <SubHeading>{t('blindCvEdit.subHeading')}</SubHeading>
            </Box>
          </StyledCard>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: theme.spacing(2) }}>
            <PdfLinkButton
              Pdfdocument={
                <BlindCvPdfDoc
                  blindCvData={blindCvData}
                  image={organizationBackground}
                  padding={blindCVSettings.padding}
                  includeInBlind={blindCVSettings.includeInBlind}
                />
              }
            />
            <Button variant={'outlined'} onClick={() => handleGoToBlindSettings()}>
              {t('blindCvGenerator.customize')}
            </Button>
          </Box>

          <CandidateBlindCvResultsFlex
            isCompleted={isCompleted}
            blindCvData={blindCvData}
            updateBlindCvData={updateBlindCvData}
          />
        </Container>
      </>
    </PageTemplate>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

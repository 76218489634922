import { styled, Box, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApplicantOrganizationSearch, Recruitment } from '../../../services/gptRecruiterService';
import SearchApplicantCard from './SearchApplicantCard';

interface ApplicantListProps {
  applicants: Array<ApplicantOrganizationSearch> | undefined;
  isPending: "GeneratingSearchQuery" | "Searching" | "Done" | null;
  organizationReceruitments: Array<Recruitment>
}

const OrganizationSearchResultApplicantsList = ({ applicants, isPending, organizationReceruitments }: ApplicantListProps) => {
  const { t } = useTranslation();


  if (isPending && isPending !== "Done") {
    const getAwaitingText = (awaitingStatus: string) : string => {
      switch (awaitingStatus) {
        case "GeneratingSearchQuery":
          return "Analyzing search query";
        case "Searching":
          return "Searching";
        case "Done":
          return "Done";
        default:
          return "Generating search query";
      }
    } 
    return (
      <StyledContent sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{getAwaitingText(isPending)}</Typography>
        <CircularProgress sx={{ margin: 'auto' }} />
      </StyledContent>
    );
  }

  if (!applicants?.length)
    return (
      <StyledContent sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography variant="h5">{t('recruitment.emptyApplicants')}</Typography>
      </StyledContent>
    );

  return (
    <StyledContent>
      {applicants?.map(
        ({cvEmbeddingId, applicantName, applicantSurname, applicantContact, answers, score, keywords, daysSinceUpload, recruitments, listPosition }) => {
          const contactData = applicantContact ? applicantContact.split(',').map((val) => val.trim()) : [];
          const name = `${applicantName} ${applicantSurname}`;

          return (
            <SearchApplicantCard
              key={cvEmbeddingId}
              contactData={contactData}
              name={name}
              id={cvEmbeddingId}
              answers={answers}
              score={score}
              keywords={keywords}
              daysSinceUpload={daysSinceUpload}
              recruitments={recruitments}
              listPosition={listPosition}
              organizationReceruitments={organizationReceruitments}
            />
          );
        }
      )}
    </StyledContent>
  );
};

export default OrganizationSearchResultApplicantsList;

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

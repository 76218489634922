import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, styled, Button, CircularProgress } from '@mui/material';
import contextService, { UploadedFileResponse } from '../../services/contextService';
import DocumentList from './DocumentList';
import useLongPolling from '../../hooks/useLongPooling';
import { UserContext } from '../../contexts/userContext';
import { Verbs } from '../../services/userService';

interface ContextDocumentsProps {
  contextId: string | undefined;
  isCompleted: boolean;
}

const ContextDocuments = ({ contextId, isCompleted }: ContextDocumentsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const [documents, setDocuments] = useState<UploadedFileResponse[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [fileUploadPending, setFileUploadPending] = useState(false);
  const { hasAccess } = useContext(UserContext);

  const userHasPermission = hasAccess([Verbs.ChatContextManage]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;
    const fileArray = Array.from(files);
    setSelectedFiles(fileArray);
  };

  const handleDeleteFile = (index: number) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
  };

  const getDocuments = async () => {
    if (!contextId) return;
    try {
      const response = await contextService.getDocuments({ contextId });
      setDocuments(response);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      throw new Error('Error fetching documents');
    } finally {
      setIsPending(false);
    }
  };

  useLongPolling(getDocuments, 10000, isCompleted);

  const uploadFile = async () => {
    if (!contextId || !selectedFiles) return;

    setFileUploadPending(true);

    try {
      const promises = selectedFiles.map((file) => contextService.uploadDocument({ contextId, file }));
      await Promise.all(promises);
      await getDocuments();
      setSelectedFiles([]);
      enqueueSnackbar({ variant: 'success', message: t('context.fileUploadSuccess') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setFileUploadPending(false);
    }
  };

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Box>
        <Typography variant="h5">{t('context.documentsHeading')}</Typography>
        <SubHeading>{t('context.documentsSubHeading')}</SubHeading>
      </Box>
      {userHasPermission && (
        <UploadFileSection>
          <Button variant="outlined" component="label">
            {t('uploadFile')}
            <input
              type="file"
              disabled={fileUploadPending}
              hidden
              accept=".txt,.csv,.xls,.xlsx,.doc,.docx,.pdf"
              onChange={handleFileChange}
              multiple
            />
          </Button>
          <Button disabled={!selectedFiles.length || fileUploadPending} variant="contained" onClick={uploadFile}>
            {t('send')}
            {fileUploadPending && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
          </Button>
        </UploadFileSection>
      )}

      <FilesWrapper>
        {!!selectedFiles.length &&
          selectedFiles.map((file, index) => (
            <StyledFile key={file.name}>
              <Typography>{file.name}</Typography>
              {fileUploadPending ? (
                <CircularProgress size={20} />
              ) : (
                <Button variant="contained" color="error" size="small" onClick={() => handleDeleteFile(index)}>
                  {t('delete')}
                </Button>
              )}
            </StyledFile>
          ))}
      </FilesWrapper>
      <DocumentList getDocuments={getDocuments} documents={documents} isPending={isPending} contextId={contextId} />
    </Wrapper>
  );
};

export default ContextDocuments;

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const UploadFileSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: theme.spacing(3),
}));

const FilesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const StyledFile = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
}));

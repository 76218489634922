import React from 'react';
import { Badge, Box, Chip, Collapse, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Stack, TextField, Tooltip, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ControlledAutocomplete } from '../../hook-form/ControlledAutocomplete';
import Iconify from '../../iconify';
import { MagicDescriptionPromptType } from '../../../services/gptRecruiterService';
import { RHFAutocompleteField } from '../../hook-form/RHFAutocomplete';

interface ProductDescriptionInputsProps {
    errors: any;
    register: any;
    control: any;
}


export default function RecruitmentSearchFrom({ errors, register, control }: ProductDescriptionInputsProps) {

    const { t } = useTranslation();

    const options = [
        { id: MagicDescriptionPromptType.SkillSetList, label: t(`recruitmentSearch.MagicDescriptionPromptType.${MagicDescriptionPromptType.SkillSetList}`) },
        { id: MagicDescriptionPromptType.JobListing, label: t(`recruitmentSearch.MagicDescriptionPromptType.${MagicDescriptionPromptType.JobListing}`) },
        { id: MagicDescriptionPromptType.PerfectCandidate, label: t(`recruitmentSearch.MagicDescriptionPromptType.${MagicDescriptionPromptType.PerfectCandidate}`) },
    ]

    const tooltipDescriptions = t('recruitmentSearch.MagicDescriptionPromptTypeToolTip.descriptions', { returnObjects: true }) as Array<{ type: string, description: string }>

    const [openAdvanced, setOpenAdvanced] = React.useState(false);

    return (
        <InputFieldsWrapper>
            <TextField
                {...register('MagicDescription', {
                    required: {
                        value: false,
                        message: t('recruitmentSearch.form.magicDescription.required'),
                    },
                    minLength: {
                        value: 5,
                        message: t('recruitmentSearch.form.magicDescription.minLength'),
                    },
                })}
                label={t('recruitmentSearch.form.magicDescription.label')}
                placeholder={t('recruitmentSearch.form.magicDescription.placeholder')}
                multiline
                minRows={1}
                maxRows={8}
                fullWidth
                error={Boolean(errors.SearchQuery)}
                helperText={errors.SearchQuery?.message}
            />

            <Stack direction="row" justifyContent="end" alignItems="center" flexWrap="wrap" gap={2}>
                {/* <div style={{ minWidth: '15rem', maxWidth: '50%' }}>
                 */}
                <Stack style={{ minWidth: '15rem', maxWidth: '50%' }} alignItems="center" direction="row" flexWrap="wrap" gap={0}>
                    <Tooltip placement="left" title={
                        <>
                            <p>{t('recruitmentSearch.MagicDescriptionPromptTypeToolTip.title')}</p>
                            <List dense>
                                {tooltipDescriptions?.map((description, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={description.type}
                                            secondary={description.description}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    }>
                        <Box>
                            <IconButton size="large" sx={{padding: 0}}>
                                <Iconify icon="ph:question-duotone" width={'75%'} />
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <div style={{ minWidth: '15rem', maxWidth: '50%' }}>
                        <RHFAutocompleteField
                            name="MagicPromptType"
                            control={control}
                            placeholder="Search algorithm"
                            options={options}
                            size='small'
                            fullWidth
                            disableClearable
                        />
                    </div>
                </Stack>

                <Badge>
                    <StyledKeywordChip
                        isSelected={openAdvanced}
                        icon={<Iconify icon={openAdvanced === true ? 'bi:arrow-up' : 'bi:arrow-down'} />}
                        variant="outlined"
                        clickable
                        label={"Advanced"}
                        onClick={() => setOpenAdvanced(!openAdvanced)}
                    />
                </Badge>
            </Stack>

            <Collapse in={!!openAdvanced} style={!openAdvanced ? { marginBottom: "-1em" } : {}}>
                <TextField
                    {...register('SearchQuery', {
                        required: {
                            value: false,
                            message: t('recruitmentSearch.form.query.required'),
                        },
                        minLength: {
                            value: 50,
                            message: t('recruitmentSearch.form.query.minLength'),
                        },
                    })}
                    label={t('recruitmentSearch.form.query.label')}
                    placeholder={t('recruitmentSearch.form.query.placeholder')}
                    multiline
                    minRows={10}
                    maxRows={20}
                    fullWidth
                    error={Boolean(errors.SearchQuery)}
                    helperText={errors.SearchQuery?.message}
                // style={{ display: 'none' }} // xDD it does the job
                />
            </Collapse>

            <ControlledAutocomplete
                control={control}
                name="Keywords"
                label={t('recruitmentSearch.form.keywords.label') ?? "keywords"}
                placeholder={t('recruitmentSearch.form.keywords.placeholder') ?? "keywords"}
                requiredMessage={t('recruitmentSearch.form.keywords.exception') ?? "keywords"}
            />
            <TextField
                label={t('recruitmentSearch.form.daysSinceUpload.label')}
                placeholder={t('recruitmentSearch.form.daysSinceUpload.placeholder')}
                type='number'
                {...register('DaysSinceUpload', {
                    required: {
                        value: true,
                        message: t('recruitmentSearch.form.daysSinceUpload.exception'),
                    },
                    min: 0,
                })}
                sx={{ width: '25ch' }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">days</InputAdornment>
                }}
                error={Boolean(errors.DaysSinceUpload)}
                helperText={errors.DaysSinceUpload?.message}
            />
        </InputFieldsWrapper>
    );
}


const InputFieldsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const StyledKeywordChip = styled(Chip)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1rem',
    padding: theme.spacing(1),
    background: theme.palette.background.default,
}));
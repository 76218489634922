/* eslint-disable no-unused-vars */
import { Box, Button, Card, styled, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import RecruitmentSearchFrom from '../components/recruitment/search/recruitmentSearchFrom';
import PageTemplate from './PageTemplate';
import Iconify from '../components/iconify';
import gptRecruiterService, { Applicant, ApplicantSearch, RecruitmentCvStatus } from '../services/gptRecruiterService';
import SearchResultApplicantsList from '../components/recruitment/search/SearchResultApplicantList';

export interface ApplicantSearchFormValues {
  MagicDescription: string;
  SearchQuery: string;
  Keywords: string[];
  DaysSinceUpload: number;
}

export default function ApplicantsRecruitmentSmartSearch() {
  const { t } = useTranslation();
  const { recruitmentId } = useParams<{ recruitmentId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [applicants, setApplicants] = useState<Array<ApplicantSearch>>();
  const [isPending, setIsPending] = useState<"GeneratingSearchQuery" | "Searching" | "Done" | null>(null);
  const [prevoiusMagicDescription, setPrevoiusMagicDescription] = useState<string>("");
  const pageTitle = t('recruitments.title');

  const { handleSubmit, register, formState, control, setValue } = useForm<ApplicantSearchFormValues>({
    defaultValues: {
      MagicDescription: '',
      SearchQuery: '',
      Keywords: [],
      DaysSinceUpload: 7
    },
  });

  const { errors } = formState;

  const onSubmit = async (data: ApplicantSearchFormValues) => {
    if (!data.MagicDescription && !data.SearchQuery) return;

    setIsPending("GeneratingSearchQuery");
    try {
      if (prevoiusMagicDescription !== data.MagicDescription) {
        const generatedSearchQuery = await gptRecruiterService.getMagicDescription(data.MagicDescription);
        data.SearchQuery = generatedSearchQuery;
        setValue("SearchQuery", generatedSearchQuery);
      }

      setIsPending("Searching");
      await getApplicantsByCv(data.SearchQuery, data.Keywords, data.DaysSinceUpload);
    }
    finally {
      setPrevoiusMagicDescription(data.MagicDescription)
      setIsPending("Done");
    }

  };

  const getApplicantsByCv = async (question: string, keywords: string[], DaysSinceUpload: number) => {

    try {
      if (!recruitmentId) return;
      const applicants = await gptRecruiterService.getSearchRecruitment({
        recruitmentId,
        question,
        keywords,
        DaysSinceUpload
      });
      setApplicants(applicants);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };


  return (
    <PageTemplate pageTitle={pageTitle}>
      <>
        <Container>
          <Box sx={{  display: 'flex', justifyContent: 'flex-end', gap: 2, my: 1 }}>
            <Button
              component={RouterLink}
              to={`/dashboard/gptrecruiter/recruitments/${recruitmentId}/applications`}
              variant="contained"
            >
              <Iconify icon="bi:arrow-left" sx={{ mr: 1 }} />
              {t('back')}
            </Button>
          </Box>
          <StyledCard>
            <Box>
              <Typography variant="h3">{t('recruitmentSearch.heading')}</Typography>
              <SubHeading>{t('recruitmentSearch.subHeading')}</SubHeading>

              <FormContainer>
                <RecruitmentSearchFrom errors={errors} register={register} control={control} />
              </FormContainer>

            </Box>
            <ButtonContainer>
              <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
                {t('recruitmentSearch.search')}
                <Iconify icon="mdi:magnify" sx={{ ml: 1 }} />
              </Button>
            </ButtonContainer>

          </StyledCard>
          <Box>
            <SearchResultApplicantsList applicants={applicants} isPending={isPending} />
          </Box>
        </Container>
      </>
    </PageTemplate>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  flex: 1,
}));

const FormContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

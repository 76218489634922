import { Button, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ContextResponse } from '../../services/contextService';

interface ContextListElementProps {
  context: ContextResponse;
}

const ContextListElement = ({
  context: { title, id, description, contextType, isIndexed },
}: ContextListElementProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{contextType}</TableCell>
      <TableCell>{isIndexed}</TableCell>
      <TableCell align="right">
        <Button to={id} component={RouterLink} variant="contained" color="secondary" size="small">
          {t('manage')}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ContextListElement;

import { Box, Button, CircularProgress, styled, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import gptRecruiterService, { RecruitmentCvStatus } from '../services/gptRecruiterService';
import PageTemplate from './PageTemplate';
import ApplicantPanel from '../components/recruitment/ApplicantFullView/ApplicantPanel';

type ParamKey = 'status' | 'keywords' | 'daysSinceUpload' | 'useSmartMatch';
interface ParamsObject {
  status: RecruitmentCvStatus | null;
  keywords: string[];
  daysSinceUpload: number | null;
  useSmartMatch: boolean;
}

const ApplicantFullView = () => {
  const { t } = useTranslation();
  const pageTitle = t('recruitments.title');
  const theme = useTheme();
  const { recruitmentId, candidateId } = useParams<{
    recruitmentId: string;
    candidateId: string;
  }>();
  const [searchParams] = useSearchParams();
  const keywords = searchParams.get('keywords');
  const hasKeywords = keywords && keywords.length > 0;

  const paramsObject = {
    status: (searchParams.get('status') as RecruitmentCvStatus) || null,
    keywords: hasKeywords ? keywords.split(',') : [],
    daysSinceUpload: Number(searchParams.get('daysSinceUpload')) || null,
    useSmartMatch: searchParams.get('useSmartMatch') === 'true',
  } as ParamsObject;

  const { enqueueSnackbar } = useSnackbar();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [previous, setPrevious] = useState<string | null>(null);
  const [next, setNext] = useState<string | null>(null);
  const [applicantsIds, setApplicantsIds] = useState<string[]>([]);

  const getApplicantsList = async () => {
    if (!recruitmentId) return;
    setIsPending(true);
    try {
      const applicants = await gptRecruiterService.getRecruitmentByIdCV({
        recruitmentId,
        status: paramsObject.status,
        keywords: paramsObject.keywords,
        daysSinceUpload: paramsObject.daysSinceUpload,
        useSmartMatch: paramsObject.useSmartMatch,
      });
      const ids = applicants.map(({ id }) => id);
      setApplicantsIds(ids);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    const candidateIndex = applicantsIds.findIndex((id) => id === candidateId);
    setPrevious(applicantsIds[candidateIndex - 1]);
    setNext(applicantsIds[candidateIndex + 1]);
  }, [applicantsIds, candidateId]);

  useEffect(() => {
    getApplicantsList();
  }, []);

  return (
    <PageTemplate pageTitle={pageTitle}>
      <Wrapper>
        <Box width="100%" display="flex" justifyContent={'space-between'} padding={theme.spacing(1)}>
          <Button
            component={RouterLink}
            to={`/dashboard/gptrecruiter/recruitments/${recruitmentId}/applications`}
            variant="outlined"
          >
            {t('candidatePanel.goBack')}
          </Button>
          <Box display="flex" gap={1} alignSelf="flex-end">
            <Button
              component={RouterLink}
              disabled={!previous}
              to={`/dashboard/gptrecruiter/recruitments/${recruitmentId}/applications/${previous}`}
              variant="outlined"
            >
              {t('candidatePanel.previous')}
            </Button>
            <Button
              component={RouterLink}
              disabled={!next}
              to={`/dashboard/gptrecruiter/recruitments/${recruitmentId}/applications/${next}`}
              variant="outlined"
            >
              {t('candidatePanel.next')}
            </Button>
          </Box>
        </Box>
        {isPending ? (
          <SpinnerContainer sx={{ height: '100%' }}>
            <CircularProgress sx={{ margin: 'auto' }} />
          </SpinnerContainer>
        ) : (
          candidateId && recruitmentId && <ApplicantPanel candidateId={candidateId} recruitmentId={recruitmentId} />
        )}
      </Wrapper>
    </PageTemplate>
  );
};

export default ApplicantFullView;

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const SpinnerContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

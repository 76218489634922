import { Box, TextField, Typography, styled, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import contextService, { ScraperStatus } from '../../services/contextService';
import { urlRegexPattern } from '../../utils/regexes';
import DataBox from './DataBox';
import { UserContext } from '../../contexts/userContext';
import { Verbs } from '../../services/userService';
import useLongPolling from '../../hooks/useLongPooling';

interface ScrapWebsiteFormValues {
  startUrl: string;
}

interface ScrapWebsiteProps {
  contextId: string | undefined;
  scraperStatus: ScraperStatus | undefined;
  getContext: () => Promise<void>;
  siteUrl: string | undefined;
}

const ScrapWebsite = ({ contextId, scraperStatus, getContext, siteUrl }: ScrapWebsiteProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasAccess } = useContext(UserContext);
  const scraperStatusText = t('context.scraperStatus');
  const scraperWebisteText = t('context.scrappedWebiste');
  const isDisabled = scraperStatus === ScraperStatus.InProgress || scraperStatus === ScraperStatus.Completed;
  const isCompleted = scraperStatus === ScraperStatus.Completed;
  const userHasPermission = hasAccess([Verbs.ChatContextManage]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ScrapWebsiteFormValues>({
    defaultValues: {
      startUrl: siteUrl ?? '',
    },
  });

  const onSubmit = async ({ startUrl }: ScrapWebsiteFormValues) => {
    if (!contextId) return;
    try {
      await contextService.scrapWebsite({ startUrl, contextId });
      enqueueSnackbar({ variant: 'success', message: t('context.scrapWebsiteSuccess') });
      await getContext();
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  useLongPolling(getContext, 5000, isCompleted);

  return (
    <Wrapper>
      <Box>
        <Typography variant="h3">{t('context.scrapWebsiteHeading')}</Typography>
        <SubHeading>{t('context.scrapWebsiteSubHeading')}</SubHeading>
      </Box>
      <Box>
        <DataBox title={scraperStatusText} value={scraperStatus} status={scraperStatus} />
        <DataBox title={scraperWebisteText} value={siteUrl} />
      </Box>

      {userHasPermission && (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            disabled={isDisabled}
            fullWidth
            label={t('context.webisteUrlLabel')}
            {...register('startUrl', {
              required: { value: true, message: t('context.websiteUrlRequired') },
              pattern: {
                value: urlRegexPattern,
                message: t('context.websiteUrlInvalid'),
              },
            })}
            error={!!errors.startUrl}
            helperText={errors.startUrl?.message}
          />
          <Button type="submit" size="large" variant="contained" disabled={isDisabled}>
            {t('send')}
          </Button>
        </StyledForm>
      )}
    </Wrapper>
  );
};

export default ScrapWebsite;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  flex: 1,
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: theme.spacing(4),
}));

/* eslint-disable no-unused-vars */
import { TextField, styled, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../iconify';

const EditEmploymentDetailsItem = ({
  item,
  handleDeleteItem,
  handleEditItem,
}: {
  item: string;
  handleDeleteItem: (question: string) => void;
  handleEditItem: (question: string, newItem: string) => void;
}) => {
  const [newQuestion, setNewQuestion] = useState<string>(item);
  const { t } = useTranslation();
  const handleSaveEdditedQuestion = () => {
    if (newQuestion !== item) {
      handleEditItem(item, newQuestion);
    }
  };
  return (
    <StyledBox>
      <TextField
        label={t('edit')}
        defaultValue={item}
        onChange={(event) => setNewQuestion(event.target.value)}
        onBlur={handleSaveEdditedQuestion}
        sx={{ width: '100%' }}
        multiline
        maxRows={4}
      />

      <Tooltip title={t('delete')}>
        <IconButton onClick={() => handleDeleteItem(item)}>
          <Iconify icon="material-symbols:delete" />
        </IconButton>
      </Tooltip>
    </StyledBox>
  );
};

export default EditEmploymentDetailsItem;

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

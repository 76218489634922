import { Box, Grow, styled } from '@mui/material';
import { ReactNode } from 'react';

interface StepperProps {
  steps: Record<string, ReactNode>;
  activeStep: string;
}
export const Stepper = ({ steps, activeStep }: StepperProps) => {
  return (
    <>
      {Object.entries(steps).map(
        ([step, component]) =>
          activeStep === step && (
            <Grow key={step} in={activeStep === step} timeout={200}>
              <StyledBox>{component}</StyledBox>
            </Grow>
          )
      )}
    </>
  );
};
const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
}));

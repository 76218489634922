/* eslint-disable no-unused-vars */
import { Box, Button, Card, TextField, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import PageTemplate from './PageTemplate';
import emailService from '../services/emailService';
import EmailSelect, { SelectOption } from '../components/email/EmailSelect';
import FormattedEmailBox from '../components/email/FormattedEmailBox';
import { EmailSizeOptions, EmailStyleOptions } from '../types/email';
import { LanguageContext } from '../contexts/languagesContext';
import { RHFAutocompleteField } from '../components/hook-form/RHFAutocomplete';

export interface EmailEnhancerFormValues {
  email: string;
  style: SelectOption | '';
  size: SelectOption | '';
  language: string;
}

export interface EnhanceEmailData {
  email: string;
  style: string;
  size: string;
  language: string;
}

export default function EmailEnhancer() {
  const { t } = useTranslation();
  const [emailData, setEmailData] = useState<Array<EnhanceEmailData>>([]);
  const pageTitle = t('emailEnhancer.title');
  const inputPlaceholder = t('emailEnhancer.inputPlaceholder');
  const inputLabel = t('emailEnhancer.inputLabel');
  const inputRequiredMessage = t('emailEnhancer.inputRequiredMessage');
  const inputMinLengthMessage = t('emailEnhancer.inputMinLengthMessage');
  const chooseLanguagePlaceholder = t('chooseLanguage');
  const { defaultLanguage, languagesOptions } = useContext(LanguageContext);

  const { handleSubmit, register, formState, control } = useForm<EmailEnhancerFormValues>({
    defaultValues: {
      email: '',
      style: '',
      size: '',
      language: defaultLanguage,
    },
  });

  const { errors } = formState;

  const onSubmit = async (data: EmailEnhancerFormValues) => {
    if (!data.style || !data.size) return;

    const mappedData = {
      ...data,
      style: data.style.value,
      size: data.size.value,
    };

    setEmailData([mappedData, mappedData, mappedData]);
  };

  const fetchEnhancedEmail = async (data: EnhanceEmailData) => {
    const { result } = await emailService.enhanceEmail(data);
    return result;
  };

  const FormattedEnhancedEmails = emailData.map((data, i) => (
    <FormattedEmailBox key={`email=${i}`} data={data} fetchEmail={fetchEnhancedEmail} />
  ));

  return (
    <PageTemplate pageTitle={pageTitle}>
      <Container>
        <TextField
          {...register('email', {
            required: { value: true, message: inputRequiredMessage },
            minLength: { value: 50, message: inputMinLengthMessage },
          })}
          label={inputLabel}
          placeholder={inputPlaceholder}
          multiline
          minRows={10}
          maxRows={15}
          fullWidth
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />
        <SubmitSection>
          <SelectContainer>
            <EmailSelect
              control={control}
              name="style"
              label={t('emailEnhancer.selectEmailStyleLabel')}
              options={EmailStyleOptions}
              error={errors.style}
            />
            <EmailSelect
              control={control}
              name="size"
              label={t('emailEnhancer.selectEmailSizeLabel')}
              options={EmailSizeOptions}
              error={errors.size}
            />
          </SelectContainer>

          <SelectContainer>
            <RHFAutocompleteField
              name="language"
              control={control}
              placeholder={chooseLanguagePlaceholder}
              options={languagesOptions}
              disableClearable
            />
            <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
              {t('send')}
            </Button>
          </SelectContainer>
        </SubmitSection>

        <EnhancedEmailContainer>{FormattedEnhancedEmails}</EnhancedEmailContainer>
      </Container>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  overflow: 'unset',
}));

const SubmitSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
}));

const SelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  flex: 1,
}));

const EnhancedEmailContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-around',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    maxWidth: 800,
  },
}));

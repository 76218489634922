import { Box, Button, CircularProgress, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface RecruitmentsListHeadingProps {
  openModal: () => void;
  isCreateRecruitmentPending: boolean;
}

const RecruitmentsListHeading = ({ openModal, isCreateRecruitmentPending }: RecruitmentsListHeadingProps) => {
  const { t } = useTranslation();
  const heading = t('recruitments.heading');
  const subHeading = t('recruitments.subHeading');
  const createNewRecruitment = t('recruitments.createNewRecrutations');

  return (
    <HeadingSection>
      <Box>
        <Typography color="text" variant="h4">
          {heading}
        </Typography>
        <SubHeading>{subHeading}</SubHeading>
      </Box>
      <Button
        sx={{ alignSelf: 'flex-end' }}
        variant="contained"
        color="primary"
        onClick={openModal}
        size="small"
        disabled={isCreateRecruitmentPending}
      >
        {createNewRecruitment}
        {isCreateRecruitmentPending && <CircularProgress color="success" sx={{ marginLeft: 1 }} size={20} />}
      </Button>
    </HeadingSection>
  );
};

export default RecruitmentsListHeading;

const HeadingSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

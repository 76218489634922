import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import ReactGA from 'react-ga4';
import App from './App.tsx';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

ReactGA.initialize('G-DBQY4022N1'); // COMMENT OUT FOR LOCAL DEVELOPMENT

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

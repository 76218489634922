import { Box, CircularProgress, Typography } from '@mui/material';
import { IndexingStatus, ScraperStatus } from '../../services/contextService';
import Iconify from '../iconify';

type StatusType = IndexingStatus | ScraperStatus;

interface DataBoxProps {
  title: string;
  value: string | undefined;
  status?: StatusType;
}

const DataBox = ({ title, value, status }: DataBoxProps) => {
  const mapStatusToIcon = (status: StatusType) => {
    switch (status) {
      case ScraperStatus.InProgress:
        return 'ph:clock-fill';
      case ScraperStatus.Completed:
        return 'ph:check-circle-fill';
      case IndexingStatus.Indexed:
        return 'ph:check-circle-fill';
      case ScraperStatus.Failed:
        return 'ph:x-circle-fill';
      case IndexingStatus.Never:
        return 'ph:minus-circle-fill';
      default:
        return '';
    }
  };

  return (
    <Typography sx={{ fontSize: 20 }} gutterBottom>
      {title}:{' '}
      <Typography variant="h4" component="span" sx={{ fontWeight: 'bold', textWrap: 'wrap' }}>
        {value ?? '-'}
      </Typography>
      {status && status === ScraperStatus.InProgress ? (
        <CircularProgress size={20} sx={{ marginLeft: 1 }} />
      ) : (
        <Iconify sx={{ marginLeft: 1 }} icon={mapStatusToIcon(status)} />
      )}
    </Typography>
  );
};

export default DataBox;

// custom history object to allow navigation outside react components
import { useNavigate } from 'react-router-dom';

interface History {
  navigate: ReturnType<typeof useNavigate>;
}

export const history: History = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  navigate: () => {},
};

import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Recruitment } from '../../services/gptRecruiterService';
import { fDate } from '../../utils/formatTime';


interface Props {
    recruitment: Recruitment | undefined;
}

const RecruitmentOpenedAt: React.FC<Props> = ({ recruitment }) => {
    const { t } = useTranslation();

    if (!recruitment) return null;

    return (
        <StyledCard>
            <Box>
                <p>{t('recruitment.status.openedAt')}: {fDate(recruitment.createdAt)}</p>
            </Box>
        </StyledCard>
    );


};

const StyledCard = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    height: '2rem',
    alignItems: 'center'
}));


export default RecruitmentOpenedAt;
/* eslint-disable no-unused-vars */
import { Button, CircularProgress, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UploadedFileResponse } from '../../services/contextService';
import { UserContext } from '../../contexts/userContext';
import { Verbs } from '../../services/userService';

interface DocumentListElementProps {
  document: UploadedFileResponse;
  handleOpenModal: (fileId: string) => void;
  isPending: boolean;
}

const DocumentListElement = ({
  document: { fileName, size, indexingStatus, id },
  handleOpenModal,
  isPending,
}: DocumentListElementProps) => {
  const { t } = useTranslation();
  const { hasAccess } = useContext(UserContext);
  const userHasPermission = hasAccess([Verbs.ChatContextManage]);

  return (
    <TableRow>
      <TableCell>{fileName}</TableCell>
      <TableCell>{size}</TableCell>
      <TableCell>{indexingStatus}</TableCell>
      {userHasPermission && (
        <TableCell align="right">
          <Button
            disabled={isPending}
            variant="contained"
            color="error"
            size="small"
            onClick={() => handleOpenModal(id)}
          >
            {t('delete')}
            {isPending && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export default DocumentListElement;

/* eslint-disable no-unused-vars */
import { Box, Chip, Tab, Tabs, styled } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PrivateChatFormValues } from './PrivateChat';

interface ChoosePromptsProps {
  closePromptsModal: () => void;
}

enum PromptCategory {
  Creator,
  Thinker,
  SalesContent,
  TextEnhancer,
  Extractor,
  BusinessPlanningStrategy,
}

const ChoosePrompts = ({ closePromptsModal }: ChoosePromptsProps) => {
  const [currentCategory, setCurrentCategory] = useState(PromptCategory.Creator);
  const { t } = useTranslation();
  const { setValue } = useFormContext<PrivateChatFormValues>();

  const promptCategory = {
    [PromptCategory.Creator]: 'creator',
    [PromptCategory.Thinker]: 'thinker',
    [PromptCategory.SalesContent]: 'sales_content',
    [PromptCategory.TextEnhancer]: 'text_enhancer',
    [PromptCategory.Extractor]: 'extractor',
    [PromptCategory.BusinessPlanningStrategy]: 'business_planning_strategy',
    
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentCategory(newValue);
  };

  const handlePromptClick = (prompt: string) => {
    setValue('message', prompt);
    closePromptsModal();
  };

  const renderPromptContent = () => {
    const prompts = t(`privateChat.prompts.categories.${promptCategory[currentCategory]}`, {
      returnObjects: true,
    }) as unknown as string[];

    return prompts?.map((prompt) => (
      <StyledChip onClick={() => handlePromptClick(prompt)} key={prompt} label={prompt} />
    ));
  };

  return (
    <Box>
      <StyledTabs value={currentCategory} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile>
        <StyledTab label={t('privateChat.prompts.creator')} />
        <StyledTab label={t('privateChat.prompts.thinker')} />
        <StyledTab label={t('privateChat.prompts.sales_content')} />
        <StyledTab label={t('privateChat.prompts.text_enhancer')} />
        <StyledTab label={t('privateChat.prompts.extractor')} />
        <StyledTab label={t('privateChat.prompts.business_planning_strategy')} />
      </StyledTabs>
      <StyledContent>{renderPromptContent()}</StyledContent>
    </Box>
  );
};

export default ChoosePrompts;

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  marginRight: theme.spacing(1),
  '&:focus': {
    opacity: 1,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: 16,
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'flex-start',

  '&:hover': {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
  },
}));

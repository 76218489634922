import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, TextField, InputAdornment, IconButton, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import userService, { passwordPattern } from '../../services/userService';
import Iconify from '../iconify';
import { emailRegexPattern } from '../../utils/regexes';

const CreateOrganizationForm: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitButtonPending, setIsSubmitButtonPending] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const emailInputLabel = t('organizationUsers.emailInputLabel');
    const emailInputLabelPlaceholder = t('organizationUsers.emailInputPlaceholder');
    const emailInputRequired = t('organizationUsers.emailInputRequired');
    const passwordInputLabel = t('organizationUsers.passwordInputLabel');
    const passwordInputLabelPlaceholder = t('organizationUsers.passwordInputPlaceholder');
    const passwordInputRequired = t('organizationUsers.passwordInputRequired');
    const confirmPasswordInputLabel = t('createOrganization.confirmPasswordInputLabel');
    const confirmPasswordInputLabelPlaceholder = t('createOrganization.confirmPasswordInputPlaceholder');
    const confirmPasswordInputRequired = t('createOrganization.confirmPasswordInputRequired');
    const nameInputLabel = t('createOrganization.nameInputLabel');
    const nameInputLabelPlaceholder = t('createOrganization.nameInputPlaceholder');
    const nameInputRequired = t('createOrganization.nameInputRequired');

    const {
        register,
        watch,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        getValues,
    } = useForm<CreateOrganizationFormValue>({
        defaultValues: {
            email: '',
            password: '',
            confirmPassword: '',
            organizationName: '',
            termsAndConditions: false,
        },
        mode: "onBlur",
    });

    const onSubmit = async ({ organizationName, email, password }: CreateOrganizationFormValue) => {
        setIsSubmitButtonPending(true);
        try {
            const { token, refreshToken } = await userService.register(email, password, organizationName);
            userService.setUserTokensToLocalStorage(token, refreshToken);
            enqueueSnackbar(t('createOrganization.createOrganizationSuccess'), { variant: 'success' });
            navigate('/pricing-plans', { replace: true });
        } catch (err) {
            enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
        } finally {
            setIsSubmitButtonPending(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('createOrganization.title')}</title>
            </Helmet>

            <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    {...register('email', {
                        required: {
                            value: true,
                            message: emailInputRequired,
                        },
                        pattern: {
                            value: emailRegexPattern,
                            message: t('login.emailAddressInvalid'),
                        },
                    })}
                    label={emailInputLabel}
                    placeholder={emailInputLabelPlaceholder}
                    fullWidth
                    InputLabelProps={{ shrink: !!watch('email') }}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                />
                <TextField
                    {...register('organizationName', {
                        required: {
                            value: true,
                            message: nameInputRequired,
                        },
                    })}
                    label={nameInputLabel}
                    placeholder={nameInputLabelPlaceholder}
                    fullWidth
                    error={Boolean(errors.organizationName)}
                    helperText={errors.organizationName?.message}
                />
                <TextField
                    type={passwordVisible ? 'text' : 'password'}
                    {...register('password', {
                        required: {
                            value: true,
                            message: passwordInputRequired,
                        },
                        pattern: {
                            value: passwordPattern,
                            message: t('createOrganization.passwordInvalid'),
                        },
                    })}
                    label={passwordInputLabel}
                    placeholder={passwordInputLabelPlaceholder}
                    fullWidth
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setPasswordVisible(!passwordVisible)} edge="end">
                                    <Iconify icon={passwordVisible ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    disabled={isSubmitButtonPending}
                />
                <TextField
                    type={passwordVisible ? 'text' : 'password'}
                    {...register('confirmPassword', {
                        required: {
                            value: true,
                            message: confirmPasswordInputRequired,
                        },
                        validate: {
                            matchesPreviousPassword: (value) => {
                                const { password } = getValues();
                                return password === value || "Passwords should match!";
                            }
                        }
                    })}
                    label={confirmPasswordInputLabel}
                    placeholder={confirmPasswordInputLabelPlaceholder}
                    fullWidth
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setPasswordVisible(!passwordVisible)} edge="end">
                                    <Iconify icon={passwordVisible ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    disabled={isSubmitButtonPending}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            {...register('termsAndConditions', {
                                required: {
                                    value: true,
                                    message: t('createOrganization.termsAndConditionsRequired'),
                                },
                            })}
                            color="primary"
                        />
                    }
                    label={
                        <>
                            {t('createOrganization.termsAndConditionsTextIAgree')}{' '}
                            <a href="/assets/docs/Regulamin.pdf" target="_blank" rel="noopener noreferrer">{t('createOrganization.termsAndConditionsLink')}</a>
                            {' '}{t('createOrganization.termsAndConditionsTextAnd')}{' '}
                            <a href="/assets/docs/PP.pdf" target="_blank" rel="noopener noreferrer">{t('createOrganization.privacyPolicyLink')}</a>.
                        </>
                    }
                />

                {errors.termsAndConditions && (
                    <Typography variant="body2" color="error">
                        {errors.termsAndConditions?.message}
                    </Typography>
                )}
                <LoadingButton
                    fullWidth size="large" type="submit" variant="contained" sx={{ my: 1 }}
                    disabled={!isDirty || !isValid || isSubmitButtonPending}
                >
                    {t('createOrganization.submitButtonText')}
                    {isSubmitButtonPending && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                </LoadingButton>
            </StyledForm>
        </>
    );
};

export default CreateOrganizationForm;


export interface CreateOrganizationFormValue {
    email: string;
    password: string;
    confirmPassword: string;
    organizationName: string;
    termsAndConditions: boolean;
}

const StyledForm = styled('form')(({ theme }) => ({
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
    width: '100%',
}));
/* eslint-disable no-unused-vars */
import i18n from '../i18n';

export enum EmailStyle {
  businessCasual = 'Business Casual',
  professional = 'Professional',
  simple = 'Simple',
  polite = 'Polite',
  concise = 'Concise',
  formal = 'Formal',
}

export enum EmailSize {
  aboutTheSame = 'About the same',
  shorter = 'Shorter',
  concise = 'Concise',
  longer = 'Longer',
  asYouThinkIsRequired = 'As you think is required',
}

export const EmailStyleOptions = Object.entries(EmailStyle).map(([key, value]) => ({
  value,
  label: i18n.t(`emailEnhancer.styles.${key}`),
}));

export const EmailSizeOptions = Object.entries(EmailSize).map(([key, value]) => ({
  value,
  label: i18n.t(`emailEnhancer.sizes.${key}`),
}));

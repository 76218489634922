import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { bgBlur } from '../../../utils/cssStyles';
import Iconify from '../../../components/iconify';
import AccountPopover from './AccountPopover';

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
  isInstructionView: PropTypes.bool,
};

export default function Header({ onOpenNav, isInstructionView }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToInstruction = () => navigate('/instruction');

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {isInstructionView ? (
            <Button component={RouterLink} variant="contained" to="/dashboard">
              {t('instruction.goBack')}
            </Button>
          ) : (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Tooltip title={t('instruction.navigate')}>
                <Box>
                  <IconButton onClick={handleNavigateToInstruction} size="large">
                    <Iconify icon="ph:question-duotone" width={'100%'} />
                  </IconButton>
                </Box>
              </Tooltip>
              <AccountPopover />
            </Box>
          )}
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}

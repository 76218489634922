import { styled, Box, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import BlindStyledCardWrapper from './BlindStyledCardWrapperProps';
import { KeyInfo } from '../../../../services/blindCvService';
import StyledModal from '../../../modals/styled-modal';

interface BlindKeyInfoSectionComponentProps {
  title: string;
  copyText: string;
  name: string;
  lastName: string;
  email: string;
  submitEdit: ({ name, lastName, email }: KeyInfo) => Promise<void>;
}

const BlindKeyInfoSectionComponent = ({
  title,
  copyText,
  name,
  lastName,
  email,
  submitEdit,
}: BlindKeyInfoSectionComponentProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleUpdate = async (editingKeyInfo: KeyInfo) => {
    // spinner
    await submitEdit(editingKeyInfo);
    // spinner
    setIsEditing(false);
  };

  // on update with some fency spinner etc
  const { t } = useTranslation();
  const [editingKeyInfo, setEditingKeyInfo] = useState({ name, lastName, email });

  return (
    <BlindStyledCardWrapper title={title} copyText={copyText} handleEdit={handleEdit}>
      <>
        <StyledModal
          title={t('blindCvEdit.title')}
          onClose={() => setIsEditing(false)}
          onContinue={() => {
            handleUpdate(editingKeyInfo);
            setIsEditing(false);
          }}
          open={isEditing}
        >
          <FlexList>
            <TextField
              label={t('blindCvEdit.keyInfo.name')}
              defaultValue={name}
              onChange={(event) => setEditingKeyInfo({ ...editingKeyInfo, name: event.target.value })}
              fullWidth
              multiline
            />
            <TextField
              label={t('blindCvEdit.keyInfo.lastName')}
              defaultValue={lastName}
              onChange={(event) => setEditingKeyInfo({ ...editingKeyInfo, lastName: event.target.value })}
              fullWidth
              multiline
            />
            <TextField
              label={t('blindCvEdit.keyInfo.email')}
              defaultValue={email}
              onChange={(event) => setEditingKeyInfo({ ...editingKeyInfo, email: event.target.value })}
              fullWidth
            />
          </FlexList>
        </StyledModal>
        <KeyInformationsWrapper>
          <Typography variant="body1" component="div" fontWeight={1000} fontSize={'1.5rem'}>
            {`${name} ${lastName}`}
          </Typography>
          <Typography variant="body1" component="div">
            {email}
          </Typography>
        </KeyInformationsWrapper>
      </>
    </BlindStyledCardWrapper>
  );
};

export default BlindKeyInfoSectionComponent;
const FlexList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
  width: '100%',
  padding: 0,
}));
const KeyInformationsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

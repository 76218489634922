import { Box, FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CompanyNameDetailsOptions, PositionDetailsOptions } from '../../services/blindCvService';
import { RHFAutocompleteField } from '../hook-form/RHFAutocomplete';
import { BlindCVSettingsProps } from '../../services/organizationService';

interface BlindDetailsProps {
  control: Control<BlindCVSettingsProps, any>;
  blindCVSettings: BlindCVSettingsProps;
  languagesOptions: {
    id: string;
    label: string;
  }[];
}

const BlindDetailsForm = ({ control, blindCVSettings, languagesOptions }: BlindDetailsProps) => {
  const { t } = useTranslation();
  return (
    <BlindDetailsWrapper>
      <Controller
        name={'positionDetails'}
        control={control}
        defaultValue={blindCVSettings.positionDetails}
        render={({ field }) => (
          <FormControl variant="outlined" sx={{ width: '30%' }}>
            <InputLabel>{t('organizationSettings.blindCVSettings.blindGenerationSettings.positionDetails')}</InputLabel>
            <Select {...field} defaultValue={blindCVSettings.positionDetails}>
              {PositionDetailsOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name={'companyNameDetails'}
        control={control}
        defaultValue={blindCVSettings.companyNameDetails}
        render={({ field }) => (
          <FormControl variant="outlined" sx={{ width: '30%' }}>
            <InputLabel>
              {t('organizationSettings.blindCVSettings.blindGenerationSettings.companyNameDetails')}
            </InputLabel>
            <Select {...field} defaultValue={blindCVSettings.companyNameDetails}>
              {CompanyNameDetailsOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      <RHFAutocompleteField
        name="blindLanguage"
        control={control}
        placeholder={t('chooseLanguage') ?? 'choose language'}
        options={languagesOptions}
        disableClearable
      />
    </BlindDetailsWrapper>
  );
};

export default BlindDetailsForm;

const BlindDetailsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'stretch',
  padding: theme.spacing(2),
  flex: 1,
  flexWrap: 'wrap',
}));

/* eslint-disable no-unused-vars */
import { Box, Button, Card, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import OrganizationSearchResultApplicantsList from '../components/recruitment/organizationSearch/OrganizationSearchResultApplicantsList';
import RecruitmentSearchFrom from '../components/recruitment/search/recruitmentSearchFrom';
import PageTemplate from './PageTemplate';
import Iconify from '../components/iconify';
import gptRecruiterService, { ApplicantOrganizationSearch, MagicDescriptionPromptType, Recruitment } from '../services/gptRecruiterService';

export interface ApplicantSearchFormValues {
    MagicDescription: string;
    MagicPromptType: MagicDescriptionPromptType;
    SearchQuery: string;
    Keywords: string[];
    DaysSinceUpload: number;
}

export default function ApplicantsOrganizationSmartSearch() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [recruitments, setRecruitments] = useState<Array<Recruitment>>([]);
    const [applicants, setApplicants] = useState<Array<ApplicantOrganizationSearch>>();
    const [isPending, setIsPending] = useState<"GeneratingSearchQuery" | "Searching" | "Done" | null>(null);
    const [prevoiusMagicDescription, setPrevoiusMagicDescription] = useState<string>("");
    const [previousPromptType, setPreviousPromptType] = useState<MagicDescriptionPromptType>(MagicDescriptionPromptType.SkillSetList);
    const pageTitle = t('applicantSearch.title');

    const { handleSubmit, register, formState, control, setValue } = useForm<ApplicantSearchFormValues>({
        defaultValues: {
            MagicDescription: '',
            MagicPromptType: MagicDescriptionPromptType.SkillSetList,
            SearchQuery: '',
            Keywords: [],
            DaysSinceUpload: 30
        },
    });

    const { errors } = formState;

    const onSubmit = async (data: ApplicantSearchFormValues) => {
        if(!data.MagicDescription && !data.SearchQuery) return;
        
        setIsPending("GeneratingSearchQuery");
        try {
            if (prevoiusMagicDescription !== data.MagicDescription || previousPromptType !== data.MagicPromptType) {
                const generatedSearchQuery = await gptRecruiterService.getMagicDescription(data.MagicDescription, data.MagicPromptType);
                data.SearchQuery = generatedSearchQuery;
                setValue("SearchQuery", generatedSearchQuery);
            }

            setIsPending("Searching");
            await getApplicantsByCv(data.SearchQuery, data.Keywords, data.DaysSinceUpload);
        }
        finally {
            setPrevoiusMagicDescription(data.MagicDescription)
            setPreviousPromptType(data.MagicPromptType)
            setIsPending("Done");
        }

    };

    const getRecruitments = async () => {
        try {
            const recruitments = await gptRecruiterService.getActiveRecruitments();
            setRecruitments(recruitments);
        } catch (error) {
            enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
        }
    };

    const getApplicantsByCv = async (question: string, keywords: string[], DaysSinceUpload: number) => {
        try {
            const getrecruitmentsAwaiter = getRecruitments();
            const applicants = await gptRecruiterService.getSearchOrganizationApplicants({
                question,
                keywords,
                DaysSinceUpload
            });
            setApplicants(applicants);
            await getrecruitmentsAwaiter;
        } catch (error) {
            enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
        }
    };

    return (
        <PageTemplate pageTitle={pageTitle}>
            <>
                <Container>
                    <StyledCard>
                        <Box>
                            <Typography variant="h3">{t('applicantSearch.heading')}</Typography>
                            <SubHeading>{t('applicantSearch.subHeading')}</SubHeading>

                            <FormContainer>
                                <RecruitmentSearchFrom errors={errors} register={register} control={control} />
                            </FormContainer>

                        </Box>
                        <ButtonContainer>
                            <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
                                {t('applicantSearch.search')}
                                <Iconify icon="mdi:magnify" sx={{ ml: 1 }} />
                            </Button>
                        </ButtonContainer>

                    </StyledCard>
                    <Box>
                        <OrganizationSearchResultApplicantsList applicants={applicants} isPending={isPending} organizationReceruitments={recruitments} />
                    </Box>
                </Container>
            </>
        </PageTemplate>
    );
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: theme.spacing(3),
    padding: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
}));

const SubHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    flex: 1,
}));

const FormContainer = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2),
}));

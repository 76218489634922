import { Box, Button, Grid, styled, Typography, useTheme } from '@mui/material';
import { ChangeEvent } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

interface BlindBackgroundFormProps {
  croppedImageUrl: string | null;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOpenCropper: () => void;
  handleDeleteBackgroundImage: () => void;
  onDrop: (acceptedFiles: File[]) => void;
}

const BlindBackgroundForm = ({
  croppedImageUrl,
  handleFileChange,
  handleOpenCropper,
  handleDeleteBackgroundImage,
  onDrop,
}: BlindBackgroundFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
    onDrop,
  });
  return (
    <>
      {croppedImageUrl && croppedImageUrl.length > 0 ? (
        <Grid container>
          <Grid item xs={12} lg={6} container justifyContent={'center'}>
            <img src={croppedImageUrl} alt="" style={{ marginBottom: theme.spacing(2) }} />
          </Grid>
          <StyledButtonsGridItem item xs={12} lg={6}>
            <Button variant="outlined" component="label" sx={{ width: '70%' }}>
              {t('organizationSettings.blindCVSettings.blindApperanceSettings.reupload')}
              <input type="file" hidden accept=".png,.jpg" onChange={handleFileChange} />
            </Button>
            <Button variant="outlined" component="label" onClick={handleOpenCropper} sx={{ width: '70%' }}>
              {t('organizationSettings.blindCVSettings.blindApperanceSettings.crop')}
            </Button>
            <Button
              variant="outlined"
              color="error"
              component="label"
              onClick={handleDeleteBackgroundImage}
              sx={{ width: '70%' }}
            >
              {t('organizationSettings.blindCVSettings.blindApperanceSettings.delete')}
            </Button>
          </StyledButtonsGridItem>
        </Grid>
      ) : (
        <>
          <Typography>
            Lookup our <a href='https://www.canva.com/design/DAGNAwCn2Yc/ti8qUVMLvgJYK9p30wuHPA/edit?utm_content=DAGNAwCn2Yc&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton' rel="noreferrer" target='_blank'>Canva examples</a>
          </Typography>
          <UploadFileSection {...getRootProps()}>
            <input {...getInputProps()} multiple={false} />
            <WrapperButton style={{ width: '100%' }} variant="outlined">
              <Typography variant="h3">+</Typography>
              {isDragActive
                ? t('organizationSettings.blindCVSettings.blindApperanceSettings.drop')
                : t('organizationSettings.blindCVSettings.blindApperanceSettings.upload')}
            </WrapperButton>
          </UploadFileSection>

        </>
      )}
    </>
  );
};

export default BlindBackgroundForm;

const UploadFileSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '10em',
  width: '100%',
}));
const WrapperButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
const StyledButtonsGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'flex-end',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

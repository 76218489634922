import React, { useEffect, useState } from "react";
// import { Autocomplete } from '@mui/material'
import TextField from "@mui/material/TextField";

import { Controller, Control, Path, FieldValues } from 'react-hook-form';

import Typography from '@mui/material/Typography';
import { styled, Autocomplete } from '@mui/material';

interface RHFAutocompleteFieldProps<O extends { id: string; label: string }, TField extends FieldValues> {
    control: Control<TField>;
    name: Path<TField>;
    disableClearable?: boolean;
    placeholder?: string;
    label?: string;
    required?: boolean;
    requiredMessage?: string;
    initialKeywords?: string[];
}

export const ControlledAutocomplete = <O extends { id: string; label: string }, TField extends FieldValues>(
    props: RHFAutocompleteFieldProps<O, TField>
) => {
    const [value, setValue] = React.useState<string[]>([]);
    const [inputValue, setInputValue] = React.useState("");

    const { control, name, required, requiredMessage} = props;

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                validate: (value, formValues) => value.length <=10 || requiredMessage
            }}
            render={({ field, fieldState: { error } }) => {
                const { onChange, value, ref } = field;
                return (
                    <>
                        {error ? <ErrorLabel>{error.message}</ErrorLabel> : null}

                        <Autocomplete
                            multiple
                            freeSolo
                            id="tags-standard"
                            options={[]}
                            value={value}
                            inputValue={inputValue}
                            onChange={(event, newValue: any) => {
                                setValue(newValue);
                                onChange(newValue);
                            }}
                            onInputChange={(event, newInputValue: string) => {
                                const options = newInputValue.split(",");

                                if (options.length > 1) {
                                    setValue(
                                        value
                                            .concat(options)
                                            .map((x: string) => x.trim())
                                            .filter((x: string) => x)
                                    );
                                } else {
                                    setInputValue(newInputValue);
                                }
                            }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={props.label}
                                    placeholder={props.placeholder}
                                    inputRef={ref}
                                />
                            )}
                        />


                    </>
                );
            }}
        />
    );
};

const ErrorLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
}));
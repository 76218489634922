import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import Protected from './ProtectedRoute';
import userService, { Verbs } from './services/userService';
import PrivateChat from './pages/PrivateChat';
import EmailEnhancer from './pages/EmailEnhancer';
import Dashboard from './pages/Dashboard';
import EmailGenerator from './pages/EmailGenerator';
import Contexts from './pages/Contexts';
import Context from './pages/Context';
import ChatIdentities from './pages/ChatIdentites';
import ProductDescription from './pages/ProductDescription';
import SoonAvaliable from './pages/SoonAvailable';
import SocialMediaPost from './pages/SocialMediaPosts';
import CreateOrganization from './pages/CreateOrganization';
import PricingPlans from './pages/PricingPlans';
import { history } from './utils/history';
import SubscriptionExpired from './pages/SubscriptionExpired';
import GptRecruitments from './pages/GptRecruitments';
import Recruitment from './pages/Recruitment';
import Applicants from './pages/Applicants';
import HasAccessRoute from './HasAccessRoute';
import { UserProvider } from './contexts/userContext';
import Instruction from './pages/Instruction';
import ApplicantsRecruitmentSmartSearch from './pages/ApplicantsRecruitmentSmartSearch';
import ApplicantsOrganizationSmartSearch from './pages/ApplicantsOrganizationSmartSearch';
import BlindCvGenerator from './pages/BlindCvGenerator';
import BlindCvOrganizationList from './pages/BlindCvOrganizationList';
import BlindCvEdit from './pages/BlindCvEdit';
import ApplicantFullView from './pages/ApplicantFullView';
import OrganizationSettings from './pages/OrganizationSettings';
import { BlindSettingsProvider } from './contexts/blindSettingsContext';

export default function Router() {
  history.navigate = useNavigate();
  const isLoggedIn = userService.checkIfUserIsLoggedIn();
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <Protected isLoggedIn={isLoggedIn}>
          <UserProvider>
            <BlindSettingsProvider>
              <DashboardLayout />
            </BlindSettingsProvider>
          </UserProvider>
        </Protected>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <Dashboard /> },
        {
          path: 'private-chat',
          element: (
            <HasAccessRoute verbs={[Verbs.ChatChat]}>
              <PrivateChat />
            </HasAccessRoute>
          ),
        },
        {
          path: 'email-generator',
          element: (
            <HasAccessRoute verbs={[Verbs.EmailGenerate]}>
              <EmailGenerator />
            </HasAccessRoute>
          ),
        },
        {
          path: 'email-enhancer',
          element: (
            <HasAccessRoute verbs={[Verbs.EmailEnhance]}>
              <EmailEnhancer />
            </HasAccessRoute>
          ),
        },
        {
          path: 'contexts',
          element: (
            <HasAccessRoute verbs={[Verbs.ChatContextList]}>
              <Contexts />
            </HasAccessRoute>
          ),
        },
        {
          path: 'contexts/:contextId',
          element: (
            <HasAccessRoute verbs={[Verbs.ChatContextList]}>
              <Context />
            </HasAccessRoute>
          ),
        },
        {
          path: 'chat-identities',
          element: (
            <HasAccessRoute verbs={[Verbs.ChatIdentitiesManage]}>
              <ChatIdentities />
            </HasAccessRoute>
          ),
        },
        {
          path: 'generate-content/product-description',
          element: (
            <HasAccessRoute verbs={[Verbs.ProductDescriptionCreate]}>
              <ProductDescription />
            </HasAccessRoute>
          ),
        },
        {
          path: 'generate-content/social-media-post',
          element: (
            <HasAccessRoute verbs={[Verbs.SocialMediaPostsCreate]}>
              <SocialMediaPost />
            </HasAccessRoute>
          ),
        },
        { path: 'generate-content/blog-post', element: <SoonAvaliable /> },
        {
          path: 'gptrecruiter/recruitments',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentCreate]}>
              <GptRecruitments />
            </HasAccessRoute>
          ),
        },
        {
          path: 'gptrecruiter/applicants/search',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentCreate]}>
              <ApplicantsOrganizationSmartSearch />
            </HasAccessRoute>
          ),
        },
        {
          path: 'gptrecruiter/blindCv/create',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentBlindCvCreate]}>
              <BlindCvGenerator />
            </HasAccessRoute>
          ),
        },
        {
          path: 'gptrecruiter/blindCv/edit/:blindCvId',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentBlindCvCreate]}>
              <BlindCvEdit />
            </HasAccessRoute>
          ),
        },
        {
          path: 'gptrecruiter/blindCv/list',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentBlindCvCreate]}>
              <BlindCvOrganizationList />
            </HasAccessRoute>
          ),
        },
        {
          path: 'gptrecruiter/recruitments/:recruitmentId',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentCreate]}>
              <Recruitment />
            </HasAccessRoute>
          ),
        },
        {
          path: 'gptrecruiter/recruitments/:recruitmentId/applications',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentCreate]}>
              <Applicants />
            </HasAccessRoute>
          ),
        },
        {
          path: 'gptrecruiter/recruitments/:recruitmentId/applications/:candidateId',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentCreate]}>
              <ApplicantFullView />
            </HasAccessRoute>
          ),
        },
        {
          path: 'gptrecruiter/recruitments/:recruitmentId/applications/search',
          element: (
            <HasAccessRoute verbs={[Verbs.RecruitmentCreate]}>
              <ApplicantsRecruitmentSmartSearch />
            </HasAccessRoute>
          ),
        },

        { path: 'email-inbox-auto-respond', element: <SoonAvaliable /> },
        { path: 'public-chat', element: <SoonAvaliable /> },
        {
          path: 'organization-settings',
          element: (
            <HasAccessRoute verbs={[Verbs.OrganizationUserList]}>
              <OrganizationSettings />
            </HasAccessRoute>
          ),
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          path: 'login',
          element: <LoginPage />,
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'create-organization', element: <CreateOrganization /> },
        { path: 'pricing-plans', element: <PricingPlans /> },
        { path: 'subscription-expired', element: <SubscriptionExpired /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/instruction',
      element: <DashboardLayout isInstructionView />,
      children: [
        { element: <Navigate to="/instruction/main" />, index: true },
        {
          path: 'main',
          element: <Instruction fileName="main" />,
        },
        {
          path: 'contexts',
          element: <Instruction fileName="contexts" />,
        },
        {
          path: 'chat-identities',
          element: <Instruction fileName="chat_identities" />,
        },
        {
          path: 'private-chat',
          element: <Instruction fileName="private_chat" />,
        },
        {
          path: 'email-generator',
          element: <Instruction fileName="email_generator" />,
        },
        {
          path: 'email-enhancer',
          element: <Instruction fileName="email_enhancer" />,
        },
        {
          path: 'users',
          element: <Instruction fileName="users" />,
        },
        {
          path: 'language',
          element: <Instruction fileName="app_language" />,
        },
        {
          path: 'product-description',
          element: <Instruction fileName="product_description" />,
        },
        {
          path: 'social-media',
          element: <Instruction fileName="social_media_post" />,
        },
        {
          path: 'recruiter',
          element: <Instruction fileName="recruiter" />,
        },
        {
          path: 'blind-customization',
          element: <Instruction fileName="blind_customization" />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}

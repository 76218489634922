import { TextField, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RecruitmentsFormValues } from '../../pages/GptRecruitments';

const RecruitmentForm = ({ onContinue }: any) => {
  const { t } = useTranslation();
  const { register, formState } = useFormContext<RecruitmentsFormValues>();
  const { errors } = formState;

  return (
    <StyledForm onSubmit={onContinue}>
      <TextField
        label={t('recruitments.recruitmentName')}
        {...register('name', {
          required: { value: true, message: t('recruitments.recruimentNameRequired') },
        })}
        error={!!errors.name}
        helperText={errors.name?.message}
      />
    </StyledForm>
  );
};

export default RecruitmentForm;

const StyledForm = styled('form')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  minHeight: '150px',
}));

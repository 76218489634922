import { styled, Box, Button, CircularProgress, Typography } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import UploadCvDoc from './UploadCvDoc';
import { RHFAutocompleteField } from '../../hook-form/RHFAutocomplete';
import { LanguageContext } from '../../../contexts/languagesContext';
import blindCvService, {
  CompanyNameDetails,
  CompanyNameDetailsOptions,
  PositionDetails,
  PositionDetailsOptions,
} from '../../../services/blindCvService';
import PositionDetailsSelect from './generatorForm/positionDetailsSelect';
import CompanyNameDetailsSelect from './generatorForm/companyNameDetailsSelect';
import PDFIcon from './PDFIcon';
import gptRecruiterService from '../../../services/gptRecruiterService';
import { BlindSettingsContext } from '../../../contexts/blindSettingsContext';

interface RecruitmentDocumentsProps {
  setBlindCvId: (blindCvId: string) => void;
}

interface FileToUploadProps {
  file: File;
  uploadingStatus: 'Queued' | 'Uploading' | 'Uploaded' | 'Error';
}

export interface languageForm {
  language: string;
  positionDetails: PositionDetails;
  companyNameDetails: CompanyNameDetails;
}

const BlindGeneratorForm = ({ setBlindCvId }: RecruitmentDocumentsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const cvId = query.get('cvId');
  const recruitmentId = query.get('recruitmentId');
  const isBlindFromRecruitmentCV = cvId && recruitmentId;
  const [selectedFile, setSelectedFile] = useState<FileToUploadProps | null>(null);
  const [fileUploadPending, setFileUploadPending] = useState(false);
  const { languagesOptions } = useContext(LanguageContext);
  const { blindCVSettings } = useContext(BlindSettingsContext);
  const [applicantName, setApplicantName] = useState<string>('');
  const { control, getValues } = useForm<languageForm>({
    defaultValues: {
      language: blindCVSettings.blindLanguage,
      positionDetails: blindCVSettings.positionDetails,
      companyNameDetails: blindCVSettings.companyNameDetails,
    },
  });
  useEffect(() => {
    if (isBlindFromRecruitmentCV) {
      getNameAndLastName();
    }
  }, [isBlindFromRecruitmentCV]);

  const getNameAndLastName = async () => {
    if (!isBlindFromRecruitmentCV) return;
    try {
      const response = await gptRecruiterService.getCandidateData({
        recruitmentId,
        candidateId: cvId,
      });
      const name = response.applicantName;
      const lastName = response.applicantSurname;
      setApplicantName(`${name} ${lastName}`);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const uploadFile = async () => {
    if (!selectedFile?.file) return;

    setFileUploadPending(true);
    const language = getValues('language') ?? blindCVSettings.blindLanguage;
    const positionDetails = getValues('positionDetails') ?? blindCVSettings.positionDetails;
    const companyNameDetails = getValues('companyNameDetails') ?? blindCVSettings.companyNameDetails;

    try {
      const response = await blindCvService.generateBlindCv(
        selectedFile?.file,
        language,
        positionDetails,
        companyNameDetails
      );
      setBlindCvId(response.blindCvId);

      selectedFile.uploadingStatus = 'Uploaded';
      enqueueSnackbar({ variant: 'success', message: t('blindCvGenerator.cvUploaded'), autoHideDuration: 2000 });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setFileUploadPending(false);
    }
  };

  const createBlindFromRecruitmentCV = async () => {
    if (!isBlindFromRecruitmentCV) return;

    const language = getValues('language') ?? blindCVSettings.blindLanguage;
    const positionDetails = getValues('positionDetails') ?? blindCVSettings.positionDetails;
    const companyNameDetails = getValues('companyNameDetails') ?? blindCVSettings.companyNameDetails;

    try {
      const response = await blindCvService.generateBlindCvForRecruitmentCv(
        recruitmentId,
        cvId,
        language,
        positionDetails,
        companyNameDetails
      );
      setBlindCvId(response.blindCvId);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };
  return (
    <Wrapper>
      {!isBlindFromRecruitmentCV ? (
        <div>
          <UploadCvDoc setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
        </div>
      ) : (
        <PDFWrapper>
          <PDFIcon />
          <Typography fontWeight={600} fontSize={'1.5rem'}>
            {applicantName}
          </Typography>
        </PDFWrapper>
      )}

      <ButtonsWrapper1>
        <PositionDetailsSelect
          name="positionDetails"
          control={control}
          label={t('blindCvGenerator.positionDetails') ?? 'position details'}
          options={PositionDetailsOptions}
          defaultValue={blindCVSettings.positionDetails}
        />

        <CompanyNameDetailsSelect
          name="companyNameDetails"
          control={control}
          label={t('blindCvGenerator.companyNameDetails') ?? 'company name details'}
          options={CompanyNameDetailsOptions}
          defaultValue={blindCVSettings.companyNameDetails}
        />

        <RHFAutocompleteField
          name="language"
          control={control}
          placeholder={t('chooseLanguage') ?? 'choose language'}
          options={languagesOptions}
          disableClearable
        />
      </ButtonsWrapper1>

      <ButtonsWrapper>
        <Button
          disabled={(!isBlindFromRecruitmentCV && !selectedFile) || fileUploadPending}
          variant="contained"
          onClick={isBlindFromRecruitmentCV ? createBlindFromRecruitmentCV : uploadFile}
        >
          {t('blindCvGenerator.generateBlind')}
          {fileUploadPending && <CircularProgress sx={{ marginLeft: 1 }} size={20} />}
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default BlindGeneratorForm;

const PDFWrapper = styled(Box)(({ theme }) => ({
  height: '10em',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const ButtonsWrapper1 = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: theme.spacing(2),
  flex: 1,
  flexWrap: 'wrap',
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  flex: 1,
  flexWrap: 'wrap',
}));

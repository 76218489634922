import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header/index.js';
import Nav from './nav';
import NavConfig from './nav/config';

interface DashboardLayoutProps {
  isInstructionView?: boolean;
}

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout({ isInstructionView }: DashboardLayoutProps) {
  const { navConfig, navConfigInstructions } = NavConfig;
  const [open, setOpen] = useState(false);

  const navData = isInstructionView ? navConfigInstructions : navConfig;

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} isInstructionView={isInstructionView} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} navData={navData} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}

import { styled, Box, Typography, Card, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import BlindStyledCardWrapper from './BlindStyledCardWrapperProps';

interface BlindTextSectionComponentProps {
    title: string;
    copyText: string;
    textToEdit: string;
    submitEdit: (updatedText: string) => Promise<void>;
}

const TextSectionComponent = ({ text }: { text: string }) => (
    <>
        <Typography variant="body1" component="div">
            {text}
        </Typography>
    </>
)

const TextSectionEditComponent = ({ text, onCancel, onUpdate }: { text: string, onCancel: () => void, onUpdate: (updatedText: string) => Promise<void> }) => {
    const { t } = useTranslation();
    const [editingText, setEditingText] = useState(text);

    const handleUpdate = async () => {
        await onUpdate(editingText);
    }

    return(
    <>
        <TextField
            multiline
            maxRows={20}
            fullWidth
            variant="outlined"
            value={editingText}
            onChange={(e) => setEditingText(e.target.value)}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button variant="outlined" onClick={onCancel}>{t('blindCvEdit.cancel')}</Button>
            <Button variant="contained" onClick={handleUpdate} >{t('blindCvEdit.save')}</Button>
        </Box>
    </>
)}

const BlindTextSectionComponent = ({ title, copyText, submitEdit, textToEdit }: BlindTextSectionComponentProps) => {
    const { t } = useTranslation();

    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleUpdate = async (updatedText: string) => {
        // spinner
        await submitEdit(updatedText);
        // spinner
        setIsEditing(false);
    }

    // on update with some fency spinner etc

    return (
        <BlindStyledCardWrapper 
            title={title} 
            copyText={copyText} 
            handleEdit={handleEdit}>
            {isEditing
                ? <TextSectionEditComponent text={textToEdit} 
                    onUpdate={handleUpdate} 
                    onCancel={() => setIsEditing(false)} />
                : <TextSectionComponent text={textToEdit} />
            }
        </BlindStyledCardWrapper>
    );
};

export default BlindTextSectionComponent;
/* eslint-disable @typescript-eslint/no-explicit-any */

import axiosInstance from '../axiosInstance';
import { SocialMediaPostFormValues } from '../pages/SocialMediaPosts';

interface ProductDescriptionResponse {
  result: string;
}

interface SocialMediaPostResponse {
  result: string;
}

export interface ProductDescriptionDto {
  ProductName: string;
  ProductDescription:  string;
  ProductDescriptionStyle: string;
  DescriptionSize: string;
  language: string;
  useContexts: boolean | undefined;
}

export interface SocialMediaPostDto {
  PostTarget: string;
  PostInstructions:  string;
  Style: string;
  Size: string;
  language: string;
  useContexts: boolean | undefined;
}

async function productDescription({
  ProductName,
  ProductDescription,
  ProductDescriptionStyle,
  DescriptionSize,
  language,
  useContexts,
}: ProductDescriptionDto): Promise<ProductDescriptionResponse> {
  try {
    const response = await axiosInstance.post('/ContentGenerators/product-description', {
      ProductName,
      ProductDescription,
      ProductDescriptionStyle,
      DescriptionSize,
      language,
      useContexts
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function socialMediaPost(obj: SocialMediaPostDto): Promise<SocialMediaPostResponse> {
  try {
    const response = await axiosInstance.post('/ContentGenerators/social-media-post', obj);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export default {
  productDescription,
  socialMediaPost,
};

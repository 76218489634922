import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Stack, styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import React from 'react';
import { Source } from '../../services/chatService';

interface StyledMessageWrapperProps {
  'data-isusermessage': boolean;
}

interface ChatMessageBoxProps {
  isUserMessage: boolean;
  message: string;
  sources: Array<Source>;
}



const ChatMessageBox = ({ message, isUserMessage, sources }: ChatMessageBoxProps) => {
  const [open, setOpen] = React.useState(false);
  const [source, setSource] = React.useState<Source>({} as Source);

  const handleClickOpen = (source: Source) => {
    setOpen(true);
    setSource(source);
  };

  const handleClose = () => {
    setOpen(false);
    setSource({} as Source);
  };

  return (
    <StyledMessageWrapper data-isusermessage={isUserMessage}>

      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        children={message.replace(/\\\\n/g, "\n").replace(/\\n/g, "\n")}
        components={{
          code({ className, children }) {
            const language = className?.replace('language-', '');
            return (
              <SyntaxHighlighter wrapLongLines style={materialDark} language={language} children={children[0]} />
            );
          },
        }}
      />

      {source && (<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Document frament used to compose the message:"}
        </DialogTitle>
        <DialogContent>

          <DialogContentText>
            <h2>{source.name}</h2>
            <Divider sx={{ marginBottom: '0.5em' }} />
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              children={source.content?.replace(/\\\\n/g, "\n").replace(/\\n/g, "\n")}
              components={{
                code({ className, children }) {
                  const language = className?.replace('language-', '');
                  return (
                    <SyntaxHighlighter wrapLongLines style={materialDark} language={language} children={children[0]} />
                  );
                },
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
      )}



      {sources && (
        <div style={{ paddingBottom: "0.5em" }}>
          <Divider sx={{ marginBottom: '0.5em' }} />
          <StyledSourcesWrapper >
            {sources.map((source, index) => (
              <Chip
                key={index}
                sx={{
                  '& .MuiChip-label': {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "200px",
                  },
                  marginRight: "0.5em",
                }}
                label={source.name}
                variant='outlined'
                size="small"
                clickable
                onClick={() => handleClickOpen(source)}
              />
            ))
            }
          </StyledSourcesWrapper >
        </ div>
      )}



    </StyledMessageWrapper>
  )
}

const StyledMessageWrapper = styled(Box)<StyledMessageWrapperProps>(
  ({ theme, 'data-isusermessage': isUserMessage }) => ({
    padding: theme.spacing(0, 1.5),
    backgroundColor: isUserMessage ? theme.palette.primary.main : theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    maxWidth: '80%',
    alignSelf: isUserMessage ? 'flex-end' : 'flex-start',
    textAlign: 'left',
    color: isUserMessage ? theme.palette.common.white : theme.palette.text.primary,

    '& ul': {
      marginLeft: theme.spacing(4),
    },

    '& li': {
      marginBottom: theme.spacing(1),
    },
  })
);

const StyledSourcesWrapper = styled(Box)(({ theme }) => ({
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(1),
}));


export default ChatMessageBox;

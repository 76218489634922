import React, { createContext } from 'react';

export const LanguageContext = createContext<LanguageContext>({} as LanguageContext);

interface LanguageContext {
  topLanguages: string[];
  defaultLanguage: string;
  languagesOptions: { id: string; label: string }[];
}

interface LanguageProviderProps {
  children: React.ReactNode;
}

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const topLanguages = [
    'Polish',
    'French',
    'German',
    'English',
    'Spanish',
    'Serbian',
    'Italian',
    'Swedish',
    'Albanian',
    'Croatian',
    'Russian',
    'Dutch',
    'Bosnian',
    'Greek',
    'Slovak',
    'Romanian',
    'Norwegian',
    'Catalan',
    'Belarusian',
    'Bulgarian',
    'Turkish',
    'Armenian',
  ];

  const defaultLanguage = 'English';

  const languagesOptions = topLanguages.map((language) => ({ id: language, label: language }));

  return (
    <LanguageContext.Provider value={{ topLanguages, defaultLanguage, languagesOptions }}>
      {children}
    </LanguageContext.Provider>
  );
};

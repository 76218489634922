import { Box, Button, CircularProgress, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';


const BlindCvListHeading = () => {
  const { t } = useTranslation();
  const heading = t('blindCvList.heading');
  const subHeading = t('blindCvList.subHeading');
  const createNewRecruitment = t('blindCvList.generateBlind');

  return (
    <HeadingSection>
      <Box>
        <Typography color="text" variant="h4">
          {heading}
        </Typography>
        <SubHeading>{subHeading}</SubHeading>
      </Box>
      <Button
        sx={{ alignSelf: 'flex-end' }}
        variant="outlined"
        color="primary"
        size="small"
        component={RouterLink}
        to="/dashboard/gptrecruiter/blindCv/create"
      >
        {createNewRecruitment}
      </Button>
    </HeadingSection>
  );
};

export default BlindCvListHeading;

const HeadingSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

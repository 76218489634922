import React from 'react';
import { Box, TextField, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ProductDescriptionInputsProps {
    errors: any;
    register: any;
}

const ProductDescriptionInputs = ({ errors, register }: ProductDescriptionInputsProps) => {

    const { t } = useTranslation();

    return (
        <InputFieldsWrapper>
            <TextField
                {...register('ProductName', {
                    required: {
                        value: true,
                        message: t('productDescription.inputLabelRequired'),
                    },
                })}
                label={t('productDescription.inputLabel')}
                placeholder={t('productDescription.inputPlaceholder')}
                multiline
                minRows={1}
                maxRows={2}
                fullWidth
                error={Boolean(errors.ProductName)}
                helperText={errors.ProductName?.message}
            />
            <TextField
                {...register('ProductCategory')}
                label={t('productDescription.productCategoryLabel')}
                placeholder={t('productDescription.productCategoryLabelPlaceholder')}
                multiline
                minRows={1}
                maxRows={2}
                fullWidth
            />
            <ColumnContainer>
                <TextField
                    {...register('KeyFeatures')}
                    label={t('productDescription.keyFeaturesLabel')}
                    placeholder={t('productDescription.keyFeaturesLabelPlaceholder')}
                    multiline
                    minRows={5}
                    maxRows={5}
                    fullWidth
                />
                <TextField
                    {...register('ProductDetails')}
                    label={t('productDescription.productDetailsLabel')}
                    placeholder={t('productDescription.productDetailsLabelPlaceholder')}
                    multiline
                    minRows={5}
                    maxRows={5}
                    fullWidth
                />
            </ColumnContainer>
            <TextField
                {...register('BenefitsToCustomer')}
                label={t('productDescription.benefitsToTheCustomerLabel')}
                placeholder={t('productDescription.benefitsToTheCustomerLabelPlaceholder')}
                multiline
                minRows={3}
                maxRows={5}
                fullWidth
            />
        </InputFieldsWrapper>
    );
}

export default ProductDescriptionInputs;

const InputFieldsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const ColumnContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    flex: 1,
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column', // Change to 'column' on small screens
    },
}));
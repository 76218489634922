import { Box, Skeleton, styled } from '@mui/material';
import { useEffect, useRef } from 'react';
import ChatMessageBox from './ChatMessageBox';
import { ChatResponse, ChatRoles } from '../../services/chatService';

// TODO: [AD-97] Fix problem with skeleton when content is scrollable

interface ChatMessagesProps {
  chatMessages: Array<ChatResponse>;
  isPending: boolean;
}

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  flex: 8,
  gap: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
  minHeight: 200,
  transition: 'scroll 0.5s ease-in-out',

  [theme.breakpoints.up('xl')]: {
    maxHeight: 800,
  },
}));

const ChatMessages = ({ chatMessages, isPending }: ChatMessagesProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [chatMessages]);

  return (
    <Content ref={contentRef}>
      {chatMessages.map((message, id) => (
        <ChatMessageBox
          key={`${id}_${message.dateTime}`}
          isUserMessage={message.role === ChatRoles.USER}
          message={message.message}
          sources={message.sources}
        />
      ))}
      {isPending && (
        <Skeleton animation="wave" variant="rounded" width="50%" height="15%" sx={{ alignSelf: 'flex-start' }} />
      )}
    </Content>
  );
};

export default ChatMessages;

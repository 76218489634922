import { Box, IconButton, Modal as MuiModal, Typography, styled } from '@mui/material';
import Scrollbar from '../../scrollbar';
import Iconify from '../../iconify';

interface ModalProps {
  title: string;
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
}

const BasicModal = ({ open, onClose, title, children }: ModalProps) => (
  <MuiModal open={open} onClose={onClose} closeAfterTransition>
    <ModalContainer>
      <ModalContent>
        <ModalHeading>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <Iconify icon="material-symbols:close" />
          </IconButton>
        </ModalHeading>
        <Scrollbar sx={scrollbarStyles}>{children}</Scrollbar>
      </ModalContent>
    </ModalContainer>
  </MuiModal>
);

export default BasicModal;

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
}));

const ModalHeading = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: '100%',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
}));

const scrollbarStyles = {
  height: 1,
  maxHeight: 600,
  padding: '24px',
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
};

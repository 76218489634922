import { IconButton, List, ListItem, ListItemText, styled, Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../services/organizationService';
import Iconify from '../iconify';

interface UserMenagmentListProps {
  users: Array<User>;
  toggleModal: () => void;
  setSelectedUser: Dispatch<SetStateAction<User | undefined>>;
  setDeleteUserId: Dispatch<SetStateAction<string>>;
  scrollToUserMenagmentForm: () => void;
}

const UserMenagmentList = ({
  users,
  toggleModal,
  setDeleteUserId,
  setSelectedUser,
  scrollToUserMenagmentForm,
}: UserMenagmentListProps) => {
  const { t } = useTranslation();

  return (
    <List>
      {users.map((user) => (
        <StyledListItem key={user.id}>
          <Box>
            <ListItemText primary={user.email} />
            <ListItemText primary={t(`userRoles.${user.role}`)} />
          </Box>

          <Box>
            <IconButton
              onClick={() => {
                setSelectedUser(user);
                scrollToUserMenagmentForm();
              }}
            >
              <Iconify icon={'ic:baseline-edit'} />
            </IconButton>
            <IconButton
              onClick={() => {
                toggleModal();
                setDeleteUserId(user.id);
              }}
            >
              <Iconify icon={'ic:baseline-delete'} />
            </IconButton>
          </Box>
        </StyledListItem>
      ))}
    </List>
  );
};

export default UserMenagmentList;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const mockedData = {
  candidateName: 'Lorem',
  candidateLastName: 'Ipsum',
  candidateEmail: 'lorem@ipsum.com',
  candidateDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ut eros at massa vulputate imperdiet ut at eros. Mauris at mi leo. Mauris iaculis feugiat magna, vitae pulvinar turpis tristique sit amet. Aenean luctus leo sit amet ex congue laoreet. Integer consequat nisi in efficitur egestas. Etiam sit amet nunc eget erat rutrum pulvinar.',
  keySkills: ['Lorem', 'Ipsum', 'Dolor', 'Sit'],
  experience: '3 years',
  previousProjects: [
    {
      companyName: 'Lorem Ipsum Corp.',
      position: 'Lorem Engineer',
      time: 'Jan 2020 - Dec 2022',
      details: 'Worked on developing and maintaining lorem ipsum applications.',
      detailsList: [
        "Developed a new feature for the company's main product, increasing user lorem by 20%.",
        'Collaborated with the design team to improve the UI/UX of the lorem.',
        'Optimized the lorem performance, reducing load times by 30%.',
        'Wrote unit and integration tests to ensure lorem quality.',
      ],
    },
    {
      companyName: 'Ipsum Solutions Ltd.',
      position: 'Dolor Developer',
      time: 'Jun 2018 - Dec 2019',
      details: 'Focused on building responsive and interactive dolor interfaces.',
      detailsList: [
        'Implemented a new responsive layout for the ipsum website.',
        'Worked closely with dolor developers to integrate lorem.',
        'Led a small team to develop a custom dolor component library.',
        'Conducted code reviews and provided dolor to junior developers.',
      ],
    },
    {
      companyName: 'Global Lorem Corp.',
      position: 'Junior Ipsum',
      time: 'Jul 2016 - May 2018',
      details: 'Assisted in the development and maintenance of lorem tools.',
      detailsList: [
        'Developed and maintained lorem tools used by various ipsum.',
        'Wrote scripts to automate repetitive lorem, saving time and reducing errors.',
        'Participated in daily lorem and sprint planning ipsum.',
        'Provided technical ipsum to non-technical lorem.',
      ],
    },
  ],
  languages: ['lorem', 'ipsum', 'dolor'],
  educationAndQualification: [
    {
      name: 'Bachelor of Lorem in Ipsum Science',
      time: 'Sep 2012 - Jun 2016',
      detail: 'University of Lorem',
    },
    {
      name: 'Master of Lorem in Ipsum Engineering',
      time: 'Sep 2017 - Jun 2019',
      detail: 'National Ipsum of Technology',
    },
    {
      name: 'Full-Stack Lorem Ipsum Bootcamp',
      time: 'Jan 2020 - Apr 2020',
      detail: 'Dolor Academy',
    },
  ],
};

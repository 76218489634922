/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContextFormValues } from '../pages/Contexts';
import axiosInstance from '../axiosInstance';
import { EditFormValues } from '../components/context/ContextFormEdit';
import { User } from './organizationService';

export enum ContextType {
  Website = 'Website',
  Document = 'Documents',
}

export enum IndexingStatus {
  Never = 'Never',
  Indexed = 'Indexed',
  InProgress = 'InProgress',
  Failed = 'Failed',
}

export enum ScraperStatus {
  Never = 'Never',
  InProgress = 'InProgress',
  Failed = 'Failed',
  Completed = 'Completed',
}

export interface ContextResponse {
  id: string;
  organizationId: string;
  title: string;
  description: string;
  contextType: ContextType;
  isIndexed: IndexingStatus;
}

export interface ContextByIdResponse extends ContextResponse {
  siteUrl: string;
  scrapedAt: string;
  scraperStatus: ScraperStatus;
}

interface UpdateContextBody extends EditFormValues {
  id: string;
}

interface UploadedFileAddResponse {
  id: string;
  createdAt: string;
  fileName: string;
  contentType: string;
  size: number;
}

export interface UploadedFileResponse extends UploadedFileAddResponse {
  indexingStatus: IndexingStatus;
}

async function getContexts(): Promise<Array<ContextResponse>> {
  try {
    const response = await axiosInstance.get('/chatContext/list');
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getContextById({ id }: { id: string }): Promise<ContextByIdResponse> {
  try {
    const response = await axiosInstance.get(`/chatContext/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function createContext({ title, description, contextType }: ContextFormValues): Promise<ContextResponse> {
  try {
    const response = await axiosInstance.post('/chatContext', { title, description, contextType });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function deleteContext({ id }: { id: string }): Promise<void> {
  try {
    const response = await axiosInstance.delete(`/chatContext/${id}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getContextUsers({ id }: { id: string }): Promise<Array<User>> {
  try {
    const response = await axiosInstance.get(`/chatContext/${id}/users`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function updateContext({ id, title, description }: UpdateContextBody): Promise<ContextResponse> {
  try {
    const response = await axiosInstance.put(`/chatContext/${id}`, {
      title,
      description,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function assignUser({ contextId, userId }: { contextId: string; userId: string }): Promise<ContextResponse> {
  try {
    const response = await axiosInstance.post(`/chatContext/${contextId}/assign-user/${userId}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function unassignUser({ contextId, userId }: { contextId: string; userId: string }): Promise<void> {
  try {
    const response = await axiosInstance.delete(`/chatContext/${contextId}/assign-user/${userId}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function scrapWebsite({ contextId, startUrl }: { contextId: string; startUrl: string }): Promise<void> {
  try {
    const response = await axiosInstance.post(`/chatContext/${contextId}/scrap-website`, { startUrl });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getDocuments({ contextId }: { contextId: string }): Promise<Array<UploadedFileResponse>> {
  try {
    const response = await axiosInstance.get(`/chatContext/${contextId}/files`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function uploadDocument({ contextId, file }: { contextId: string; file: File }): Promise<UploadedFile> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('contextId', contextId);

    const headers = { 'Content-Type': 'multipart/form-data' };

    const response = await axiosInstance.post(`/chatContext/${contextId}/file`, formData, { headers });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function deleteDocument({ contextId, fileId }: { contextId: string; fileId: string }): Promise<void> {
  try {
    const response = await axiosInstance.delete(`/chatContext/${contextId}/file/${fileId}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export default {
  getContexts,
  createContext,
  getContextById,
  deleteContext,
  getContextUsers,
  updateContext,
  assignUser,
  unassignUser,
  scrapWebsite,
  getDocuments,
  uploadDocument,
  deleteDocument,
};

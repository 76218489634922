import { Slider, TextField, Typography, styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ChatIdentity } from '../../services/chatService';
import { ChatIdentityFormValues } from '../../pages/ChatIdentites';

const ChatIdentityForm = ({ chatIdentity }: { chatIdentity: ChatIdentity | undefined }) => {
  const { t } = useTranslation();
  const { register, formState: { errors }, setValue, watch } = useFormContext<ChatIdentityFormValues>();

  useEffect(() => {
    if (chatIdentity) {
      setValue('title', chatIdentity.title);
      setValue('temperature', chatIdentity.temperature);
      setValue('identity', chatIdentity.identity);
    }
  }, [chatIdentity, setValue]);


  return (
    <StyledForm>
      <TextField
        label={t('chatIdentities.form.title')}
        InputLabelProps={{ shrink: !!watch('title') }}
        {...register('title', {
          required: { value: true, message: t('chatIdentities.form.titleRequired') },
        })}
        error={!!errors.title}
        helperText={errors.title?.message}
      />
      <Typography id="input-slider" gutterBottom>
        {t('chatIdentities.form.temperature')}
      </Typography>
      <Slider
        aria-label="Temperature"
        value={watch('temperature') || 0}
        onChange={(e, value) => {
          setValue('temperature', Number(value));
        }}
        valueLabelDisplay="auto"
        step={0.05}
        marks
        min={0}
        max={1}
      />
      <TextField
        label={t('chatIdentities.form.identity')}
        InputLabelProps={{ shrink: !!watch('identity') }}
        {...register('identity', {
          required: { value: true, message: t('chatIdentities.form.identityRequired') },
        })}
        error={!!errors.identity}
        helperText={errors.identity?.message}
        multiline
        minRows={15}
        maxRows={20}
      />
    </StyledForm>
  );
};

export default ChatIdentityForm;

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  minHeight: '200px',
}));

import { Box, Divider, Grid, styled, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../iconify';
import { TrinaryAnswer } from '../../../services/gptRecruiterService';
import EditTrinaryQuestionsActions from './EditTrinaryQuestionsActions';
import { EditTrinaryAnswersProps } from './Summary';

interface TrinaryAnswersProps {
  trinaryAnswers: TrinaryAnswer[];
  isEditing: boolean;
  updateTrinaryAnswers: (props: EditTrinaryAnswersProps) => void;
}
export interface CategorizedAnswers {
  yesAnswers: string[];
  noAnswers: string[];
  maybeAnswers: string[];
}

const TrinaryAnswers = ({ trinaryAnswers, isEditing, updateTrinaryAnswers }: TrinaryAnswersProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const categorizedAnswers = trinaryAnswers.reduce(
    (acc: CategorizedAnswers, curr) => {
      if (curr.answer === true) {
        acc.yesAnswers.push(curr.question);
      } else if (curr.answer === null) {
        acc.maybeAnswers.push(curr.question);
      } else if (curr.answer === false) {
        acc.noAnswers.push(curr.question);
      }
      return acc;
    },
    {
      yesAnswers: [],
      maybeAnswers: [],
      noAnswers: [],
    }
  );
  const { yesAnswers, maybeAnswers, noAnswers } = categorizedAnswers;

  return (
    <Grid container padding={theme.spacing(1)}>
      <Grid item xs={12} marginBottom={theme.spacing(1)}>
        <Typography
          fontWeight={600}
          fontSize={'1.5rem'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {t('recruitment.binaryQuestions')}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={4} marginBottom={theme.spacing(1)}>
        <Typography fontWeight={600} color={theme.palette.success.main}>
          {t('recruitmentSearch.applicantCard.yes', {
            count: yesAnswers.length,
          })}
        </Typography>
        <Divider sx={{ borderColor: theme.palette.success.main, width: '80%' }} />
        {yesAnswers.map((question, id) => (
          <QuestionsContainer isEditing={isEditing}>
            <Typography fontWeight={400} width={'100%'} mb={theme.spacing(1)} key={id}>
              <Iconify icon={'mdi:check'} sx={{ paddingTop: 1, color: theme.palette.success.main }} />
              {question}
            </Typography>
            {isEditing && (
              <EditTrinaryQuestionsActions type question={question} updateTrinaryAnswers={updateTrinaryAnswers} />
            )}
          </QuestionsContainer>
        ))}
      </Grid>
      <Grid item xs={12} lg={4} marginBottom={theme.spacing(1)}>
        <Typography fontWeight={600} color={theme.palette.info.main}>
          {t('recruitmentSearch.applicantCard.maybe', {
            count: maybeAnswers.length,
          })}
        </Typography>
        <Divider sx={{ borderColor: theme.palette.info.main, width: '80%' }} />
        {maybeAnswers.map((question, id) => (
          <QuestionsContainer isEditing={isEditing}>
            <Typography fontWeight={400} width={'100%'} mb={theme.spacing(1)} key={id}>
              <Iconify icon={'mdi:help'} sx={{ paddingTop: 1, color: theme.palette.info.main }} />
              {question}
            </Typography>
            {isEditing && (
              <EditTrinaryQuestionsActions
                type={null}
                question={question}
                updateTrinaryAnswers={updateTrinaryAnswers}
              />
            )}
          </QuestionsContainer>
        ))}
      </Grid>
      <Grid item xs={12} lg={4} marginBottom={theme.spacing(1)}>
        <Typography fontWeight={600} color={theme.palette.error.main}>
          {t('recruitmentSearch.applicantCard.no', {
            count: noAnswers.length,
          })}
        </Typography>
        <Divider sx={{ borderColor: theme.palette.error.main, width: '80%' }} />
        {noAnswers.map((question, id) => (
          <QuestionsContainer isEditing={isEditing}>
            <Typography fontWeight={400} width={'100%'} mb={theme.spacing(1)} key={id}>
              <Iconify icon={'mdi:close'} sx={{ paddingTop: 1, color: theme.palette.error.main }} />
              {question}
            </Typography>
            {isEditing && (
              <EditTrinaryQuestionsActions
                type={false}
                question={question}
                updateTrinaryAnswers={updateTrinaryAnswers}
              />
            )}
          </QuestionsContainer>
        ))}
      </Grid>
    </Grid>
  );
};

export default TrinaryAnswers;

const QuestionsContainer = styled(Box)<{ isEditing: boolean }>(({ theme, isEditing }) => ({
  width: '80%',
  marginTop: isEditing ? theme.spacing(2) : 0,
  padding: isEditing ? theme.spacing(1) : 0,
  boxShadow: isEditing ? '0px 8px 20px rgba(0, 0, 0, 0.25)' : 'none',
}));

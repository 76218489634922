import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Tier } from '../../services/userService';

interface PricingTableProps {
  pricingPlans: Array<Tier>;
  adonType: string;
}

interface Feature {
  title: string;
  description: string;
  premium: string;
  plus: string;
  basic: string;
  free: string;
}

const PricingTable = ({ pricingPlans, adonType }: PricingTableProps) => {
  const { t } = useTranslation();

  // TODO: Change translation for pricingPlans.featuresTable
  const features = t(`pricingPlans.featuresTable.${adonType}`, { returnObjects: true }) as Array<Feature>;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            {pricingPlans.map(({ monthlyRateValue, name }, index) => (
              <TableCell key={index}>
                <Typography variant="h6" align='center'>{name}</Typography>
                <Typography variant="body1" align='center'>{`${monthlyRateValue} €`}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="body1">{feature.title}</Typography>
                <Typography variant="body2">{feature.description}</Typography>
              </TableCell>
              {pricingPlans.some(x => x.accessPlanId === 5) && (
                <TableCell>
                  <Typography variant="body1" align='center'>{feature.free}</Typography>
                </TableCell>
              )}

              <TableCell>
                <Typography variant="body1" align='center'>{feature.basic}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" align='center'>{feature.plus}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" align='center'>{feature.premium}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingTable;

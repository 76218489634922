/* eslint-disable no-unused-vars */
import { Box, Chip, CircularProgress, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import chatService, { ChatHistory } from '../services/chatService';
import DataBox from '../components/chooseChatHistory/databox';
import { fDateTime } from '../utils/formatTime';

interface ChooseChatHistoryProps {
  handleChangeChatHistory: (chatSessionId: string) => void;
  toggleChatHistoryModal: () => void;
}

const ChooseChatHistory = ({ handleChangeChatHistory, toggleChatHistoryModal }: ChooseChatHistoryProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [history, setHistory] = useState<ChatHistory[]>([]);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    const handleGetChatHistory = async () => {
      setIsPending(true);
      try {
        const response = await chatService.getChatHistory();
        setHistory(response);
      } catch (error) {
        enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      } finally {
        setIsPending(false);
      }
    };
    handleGetChatHistory();
  }, [t, enqueueSnackbar]);

  if (isPending)
    return (
      <Box sx={{ display: 'flex', placeContent: 'center' }}>
        <CircularProgress />
      </Box>
    );

  if (!history.length) return <Box>{t('privateChat.chatHistory.noHistory')}</Box>;

  return (
    <StyledContent>
      {history.map(({ chatStartTime, chatSessionId, chatIdentityTitle, firstChatMessage }) => (
        <StyledChip
          onClick={() => {
            handleChangeChatHistory(chatSessionId);
            toggleChatHistoryModal();
          }}
          key={chatSessionId}
          label={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <DataBox firstMessage={firstChatMessage} dateTime={fDateTime(chatStartTime)} identity={chatIdentityTitle}/>
            </Box>
          }
        />
      ))}
    </StyledContent>
  );
};

export default ChooseChatHistory;

const StyledChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: 16,
  padding: theme.spacing(1),
  justifyContent: 'flex-start',
  height: 'auto',

  '&:hover': {
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
  },
}));

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
  minHeight: 300,
}));

import * as React from 'react';
import { Controller, Control, Path, FieldValues } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

interface RHFAutocompleteFieldProps<O extends { id: string; label: string }, TField extends FieldValues> {
  control: Control<TField>;
  name: Path<TField>;
  options: O[];
  disableClearable?: boolean;
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  size?: 'small' | 'medium' | undefined;
  fullWidth?: boolean;
}

export const RHFAutocompleteField = <O extends { id: string; label: string }, TField extends FieldValues>(
  props: RHFAutocompleteFieldProps<O, TField>
) => {
  const { control, options, name, required, requiredMessage, disableClearable = false } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          value: Boolean(required),
          message: requiredMessage || 'This field is required',
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field;
        return (
          <>
            <Autocomplete
              disableClearable={disableClearable}
              sx={{ minWidth: '30%' }}
              fullWidth={props.fullWidth ?? false}
              size={props.size ?? undefined} 
              value={value ? options.find((option) => value === option.id) ?? null : null}
              getOptionLabel={(option) => option.label}
              onChange={(event: any, newValue) => {
                onChange(newValue ? newValue.id : null);
              }}
              id="controllable-states-demo"
              options={options}
              renderInput={(params) => <TextField {...params} label={props.placeholder} inputRef={ref} />}
            />
            {error ? <ErrorLabel>{error.message}</ErrorLabel> : null}
          </>
        );
      }}
    />
  );
};

const ErrorLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

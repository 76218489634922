import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import PageTemplate from './PageTemplate';

interface InstructionProps {
  fileName: string;
}

const Instruction = ({ fileName }: InstructionProps) => {
  const { t } = useTranslation();
  const pageTitle = t('instruction.title');
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(`/markdown/${fileName}.md`)
      .then((response) => response.text())
      .then((text) => setMarkdown(text))
      .catch((error) => console.log(error));
  }, [fileName]);

  return (
    <PageTemplate pageTitle={pageTitle}>
      <StyledCard>
        <ReactMarkdown
          children={markdown}
          components={{
            p: ({ node, children }) => {
              if (node.children[0].tagName === 'img') {
                const image: any = node.children[0];
                return (
                  <StyledImageBox>
                    <Box component="img" src={image.properties.src} alt={image.properties.alt} sx={{ marginY: 2 }} maxWidth='800px' />
                  </StyledImageBox>
                );
              }

              return children;
            },
            code({ className, children }) {
              const language = className?.replace('language-', '');
              return (
                <SyntaxHighlighter wrapLongLines style={materialDark} language={language} children={children[0]} />
              );
            },
          }}
        />
      </StyledCard>
    </PageTemplate>
  );
};

export default Instruction;

const StyledCard = styled(Card)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),

  '& ul': {
    marginLeft: theme.spacing(8),
  },

  '& li': {
    marginBottom: theme.spacing(1),
  },
}));

const StyledImageBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));


import { Typography, Button, Card, Box, styled, TextField, FormControl, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import contextService, { ContextType, IndexingStatus } from '../../services/contextService';
import StyledModal from '../modals/styled-modal';
import useModal from '../../hooks/useModal';
import AssignUserForm from './AssignUserForm';
import DataBox from './DataBox';
import { UserContext } from '../../contexts/userContext';
import { Verbs } from '../../services/userService';

interface ContextFormEditProps {
  contextType: ContextType | undefined;
  id: string | undefined;
  isIndexed: IndexingStatus | undefined;
  title: string | undefined;
  description: string | undefined;
  getContext: () => void;
}

export interface EditFormValues {
  title: string;
  description: string;
}

const ContextFormEdit = ({
  contextType,
  id,
  isIndexed,
  title: contextTitle,
  description: contextDescription,
  getContext,
}: ContextFormEditProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, toggleModal] = useModal();
  const navigate = useNavigate();
  const { hasAccess } = useContext(UserContext);
  const contextTypeText = t(`context.contextType`);
  const indexingStatusText = t(`context.indexingStatus`);

  const userHasPermission = hasAccess([Verbs.ChatContextManage]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<EditFormValues>({
    values: {
      title: contextTitle ?? '',
      description: contextDescription ?? '',
    },
  });

  const handleDelete = async () => {
    if (!id) return;
    try {
      await contextService.deleteContext({ id });
      enqueueSnackbar({ variant: 'success', message: t('context.deleteContextSuccess') });
      navigate('/dashboard/contexts');
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const onSubmit = async ({ title, description }: EditFormValues) => {
    if (!id) return;
    try {
      await contextService.updateContext({ title, description, id });
      await getContext();
      enqueueSnackbar({ variant: 'success', message: t('context.editContextSuccess') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  return (
    <ContextInfoContainer>
      <Box>
        <Typography variant="h3">{t('context.heading')}</Typography>
        <SubHeading>{t('context.subHeading')}</SubHeading>
      </Box>
      <Box>
        <DataBox title={contextTypeText} value={contextType} />
        <DataBox title={indexingStatusText} value={isIndexed} status={isIndexed} />
      </Box>

      <TextDescriptionWrapper>
        <FormControl fullWidth>
          <FormLabel>{t('contexts.form.title')}</FormLabel>
          <TextField
            {...register('title', {
              required: { value: true, message: t('contexts.form.titleRequired') },
            })}
            error={!!errors.title}
            helperText={errors.title?.message}
            disabled={!userHasPermission}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>{t('contexts.form.description')}</FormLabel>
          <TextField
            defaultValue={contextDescription}
            {...register('description', {
              required: { value: true, message: t('contexts.form.descriptionRequired') },
            })}
            error={!!errors.description}
            helperText={errors.description?.message}
            disabled={!userHasPermission}
          />
        </FormControl>
      </TextDescriptionWrapper>

      {userHasPermission && (
        <UsersAndButtonsSection>
          <AssignUserForm id={id} />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              {t('update')}
            </Button>
            <Button variant="contained" color="error" onClick={toggleModal}>
              {t('delete')}
            </Button>
          </Box>
        </UsersAndButtonsSection>
      )}

      <StyledModal
        title={t('context.deleteContext')}
        open={isModalOpen}
        onClose={toggleModal}
        onContinue={handleDelete}
      >
        <Typography>{t('context.confirmDelete')}</Typography>
      </StyledModal>
    </ContextInfoContainer>
  );
};

export default ContextFormEdit;

const ContextInfoContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(2),
}));

const TextDescriptionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const UsersAndButtonsSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
}));

import { Card, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import ChatTextField from '../components/chat/ChatTextField';
import PageTemplate from './PageTemplate';
import chatService, { ChatRoles, ChatResponse } from '../services/chatService';
import ChatHeading from '../components/chat/ChatHeading';
import ChatMessages from '../components/chat/ChatMessages';
import usePrivateChat from '../hooks/usePrivateChat';
import BasicModal from '../components/modals/basic-modal';
import ChoosePrompts from './ChoosePrompts';
import ChooseChatHistory from './ChooseChatHistory';

export interface PrivateChatFormValues {
  message: string;
  chatIdentityId: string;
}

export default function PrivateChat() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const pageTitle = t('privateChat.title');
  const {
    chatMessages,
    setChatMessages,
    chatSessionId,
    setChatSessionId,
    isPending,
    setIsPending,
    resetChat,
    selectedChatIdentity,
    setSelectedChatIdentity,
    isPromptsModalOpen,
    togglePromptsModal,
    isChatHistoryModalOpen,
    toggleChatHistoryModal,
  } = usePrivateChat();

  const form = useForm<PrivateChatFormValues>({
    defaultValues: {
      message: '',
      chatIdentityId: '',
    },
  });

  const { handleSubmit, clearErrors } = form;

  const handleResetChat = () => {
    resetChat();
    form.reset();
  };

  const onSubmit: SubmitHandler<PrivateChatFormValues> = async ({ message, chatIdentityId }) => {
    setIsPending(true);
    let tempChatSessionId = chatSessionId;
    try {
      if (!chatSessionId) {
        const { chatSessionId: newChatSessionId } = await chatService.createChat();
        await chatService.initChatSession(newChatSessionId, chatIdentityId);
        setChatSessionId(newChatSessionId);
        tempChatSessionId = newChatSessionId;
        enqueueSnackbar(t('privateChat.chatSessionEstablished'), { variant: 'success' });
      }

      const userMessage = { role: ChatRoles.USER, message } as ChatResponse;

      setChatMessages((prevMessages) => [...prevMessages, userMessage]);
      const chatResponse = await chatService.generateChatResponse(tempChatSessionId, message);
      setChatMessages((prevMessages) => [...prevMessages, chatResponse]);
    } catch (error) {
      const errorMessage = t('somethingWentWrong');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      form.reset();
      setIsPending(false);
    }
  };

  const handleChangeChatHistory = async (chatSessionId: string) => {
    try {
      const { conversationMessages, chatIdentityId } = await chatService.getChatMessages(chatSessionId);
      // const chatMessages = conversationMessages.map(({ message }) => message);
      setChatMessages([{role: ChatRoles.SYSTEM, message: t('privateChat.initialMessage')} as ChatResponse, ...conversationMessages]);
      setChatSessionId(chatSessionId);
      clearErrors();
      setSelectedChatIdentity(chatIdentityId);
      enqueueSnackbar(t('privateChat.chatHistory.chatHistoryChanged'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
    }
  };

  return (
    <PageTemplate pageTitle={pageTitle}>
      <FormProvider {...form}>
        <Container onSubmit={handleSubmit(onSubmit)}>
          <ChatHeading
            isChatSessionAlreadyCreated={Boolean(chatSessionId)}
            headingText={t('privateChat.heading')}
            handleResetChat={handleResetChat}
            selectedChatIdentity={selectedChatIdentity}
            setSelectedChatIdentity={setSelectedChatIdentity}
          />
          <ChatMessages chatMessages={chatMessages} isPending={isPending} />
          <ChatTextField
            isPending={isPending}
            onSubmit={onSubmit}
            togglePromptsModal={togglePromptsModal}
            toggleChatHistoryModal={toggleChatHistoryModal}
          />
        </Container>
        <BasicModal title={t('privateChat.prompts.choose')} open={isPromptsModalOpen} onClose={togglePromptsModal}>
          <ChoosePrompts closePromptsModal={togglePromptsModal} />
        </BasicModal>
        <BasicModal
          title={t('privateChat.chatHistory.heading')}
          open={isChatHistoryModalOpen}
          onClose={toggleChatHistoryModal}
        >
          <ChooseChatHistory
            handleChangeChatHistory={handleChangeChatHistory}
            toggleChatHistoryModal={toggleChatHistoryModal}
          />
        </BasicModal>
      </FormProvider>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexBasis: '50px',
  flexDirection: 'column',
  overflow: 'unset',
}));

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled, Tab, Tabs, Card } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Stepper } from '../components/shared/Stepper';
import PageTemplate from './PageTemplate';
import OrganizationUsers from '../components/organization-settings/OrganizationUsers';
import BlindCVSettings from '../components/organization-settings/BlindCVSettings';

export enum OrganizationSettingsMenu {
  Users = 'Users',
  BlindSettings = 'Blind CV Settings',
}
const OrganizationSettings = () => {
  const { t } = useTranslation();
  const pageTitle = t('recruitments.title');
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const initialTab = query.get('initialTab');
  const [tab, setTab] = useState<string>(initialTab || OrganizationSettingsMenu.Users);
  return (
    <PageTemplate pageTitle={pageTitle}>
      <Wrapper>
        <StyledTabs
          value={tab}
          onChange={(_, val) => setTab(val)}
          variant="fullWidth"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab value={OrganizationSettingsMenu.Users} label={OrganizationSettingsMenu.Users} />
          <Tab value={OrganizationSettingsMenu.BlindSettings} label={OrganizationSettingsMenu.BlindSettings} />
        </StyledTabs>
        <MainWrapper>
          <Stepper
            steps={{
              [OrganizationSettingsMenu.Users]: <OrganizationUsers />,
              [OrganizationSettingsMenu.BlindSettings]: <BlindCVSettings />,
            }}
            activeStep={tab}
          />
        </MainWrapper>
      </Wrapper>
    </PageTemplate>
  );
};

export default OrganizationSettings;

const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
}));
const MainWrapper = styled(Card)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));
const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

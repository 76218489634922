/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axiosInstance';

interface TierLimits {
  maxNumberOfOrganizationUsers: number;
  maxNumberOfChatIdentities: number;
  maxNumberOfContexts: number;
  maxNumberOfWebsiteTypeContexts: number;
  maxContextsDocumentsSizeLimit: number;
  maxNumberOfChatsMonthly: number;
  maxNumberOfGeneratedEmailsMonthly: number;
  maxNumberOfEmailEnhancementsMonthly: number;
  maxNumberOfProductDescriptionsMonthly: number;
  maxNumberOfSocialMediaPostsMonthly: number;
}

export interface Tier {
  accessPlanId: number;
  name: string;
  description: string;
  monthlyRateValue: number;
  currencyIsoCode: string;
  limits: TierLimits;
}

interface PricingPlansResponse {
  lowTier: Tier;
  mediumTier: Tier;
  highTier: Tier;
}

interface LoginResponse {
  token: string;
  refreshToken: string;
}

export enum Verbs {
  ChatContextList = 'ChatContext.List',
  ChatContextManage = 'ChatContext.Manage',
  ChatContextGet = 'ChatContext.Get',
  ChatContextUserList = 'ChatContext.User.List',
  ChatContextUserManage = 'ChatContext.User.Manage',
  ChatContextDataScrapWebsite = 'ChatContext.Data.ScrapWebsite',
  ChatContextDataFilesList = 'ChatContext.Data.Files.List',
  ChatContextDataFilesManage = 'ChatContext.Data.Files.Manage',
  ChatChat = 'Chat.Chat',
  ChatIdentitiesManage = 'Chat.Identities.Manage',
  EmailGenerate = 'Email.Generate',
  EmailEnhance = 'Email.Enhance',
  OrganizationUserList = 'Organization.User.List',
  OrganizationUserManage = 'Organization.User.Manage',
  ProductDescriptionCreate = 'ProductDescription.Create',
  SocialMediaPostsCreate = 'SocialMediaPosts.Create',
  OrganizationManage = 'Organization.Manage',
  RecruitmentCreate = 'Recruitment.Create',
  RecruitmentCvAdd = 'Recruitment.Cv.Add',
  RecruitmentCvStatusManage = 'Recruitment.Cv.StatusManage',
  RecruitmentCvDownload = 'Recruitment.CvDownload',
  RecruitmentCvDataGet = 'Recruitment.CvDataGet',
  RecruitmentBlindCvCreate = 'Recruitment.BlindCv.Create'
}

export enum UserRoles {
  OrganizationAdmin = 'OrganizationAdmin',
  NotDefined = 'NotDefined',
  User = 'User',
  Admin = 'Admin',
  UserWithRecruitment = 'UserWithRecruitment',
}

export enum SetableUserRoles {
  User = 'User',
  Admin = 'Admin',
  OrganizationAdmin = 'OrganizationAdmin',
  UserWithRecruitment = 'UserWithRecruitment',
}

export const passwordPattern = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&()^#<>;:'"{}[\]_=+-]{8,}$/;

async function loginUser(email: string, password: string): Promise<LoginResponse> {
  try {
    const response = await axiosInstance.post('/login', { username: email, password });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function refreshToken(token: string, refreshToken: string): Promise<LoginResponse> {
  try {
    const response = await axiosInstance.post('/login-refresh', { token, refreshToken });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

function setUserTokensToLocalStorage(token: string, refreshToken: string): void {
  localStorage.setItem('access-token', token);
  localStorage.setItem('refresh-token', refreshToken);
}

function getUserTokes() {
  const accessToken = localStorage.getItem('access-token');
  const refreshToken = localStorage.getItem('refresh-token');

  return { accessToken, refreshToken };
}

function checkIfUserIsLoggedIn(): boolean {
  const accessToken = localStorage.getItem('access-token');
  const refreshToken = localStorage.getItem('refresh-token');

  if (accessToken && refreshToken) return true;
  return false;
}

function logoutUserAndRedirect(): void {
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');
  window.location.href = '/login';
}

function getUserInfo() {
  const { accessToken } = getUserTokes();
  if (!accessToken) return null;
  const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
  const userEmail = decodedToken[`http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name`];
  return userEmail as string;
}

async function register(email: string, password: string, organizationName: string): Promise<LoginResponse> {
  try {
    const response = await axiosInstance.post('/register', { email, password, organizationName });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getPricingPlans(): Promise<object> {
  try {
    const response = await axiosInstance.get('/pricing-plans');
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function stripeCustomerPortal(): Promise<{ redirectUrl: string }> {
  try {
    const response = await axiosInstance.post('/stripe-customer-portal');
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getUserVerbs(): Promise<{ role: UserRoles; verbs: Verbs[] }> {
  try {
    const response = await axiosInstance.get('/authorization/user-verbs');
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

function checkIfUserIsOrganizationAdmin() {
  const { accessToken } = getUserTokes();
  if (!accessToken) return false;
  const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
  const userRole: UserRoles = decodedToken[`http://schemas.microsoft.com/ws/2008/06/identity/claims/role`];
  if (userRole === UserRoles.OrganizationAdmin) return true;
  return false;
}

export default {
  loginUser,
  refreshToken,
  setUserTokensToLocalStorage,
  logoutUserAndRedirect,
  checkIfUserIsLoggedIn,
  getUserTokes,
  getUserInfo,
  register,
  getPricingPlans,
  checkIfUserIsOrganizationAdmin,
  stripeCustomerPortal,
  getUserVerbs,
};

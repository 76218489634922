import { Box, Button, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import gptRecruiterService from '../../../services/gptRecruiterService';

interface NoteProps {
  recruitmentId: string | undefined;
  candidateId: string | undefined;
}
const Note = ({ recruitmentId, candidateId }: NoteProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [candidateNote, setCandidateNote] = useState<string>('');
  const getNotes = async () => {
    if (!recruitmentId || !candidateId) return;
    try {
      const candidate = await gptRecruiterService.getCandidateData({
        recruitmentId,
        candidateId,
      });
      setCandidateNote(candidate.notes || '');
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };
  const updateNotes = async (notes: string) => {
    if (!recruitmentId || !candidateId) return;
    try {
      await gptRecruiterService.updateNotes({ recruitmentId, candidateId, notes });
      enqueueSnackbar({ variant: 'success', message: t('candidatePanel.noteUpdated') });
    } catch (err) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      getNotes();
    }
  };
  useEffect(() => {
    getNotes();
  }, []);
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
      <Typography fontWeight={600} fontSize={'1.5rem'}>
        {t('candidatePanel.note')}
      </Typography>
      <EditorProvider>
        <Editor
          value={candidateNote}
          onChange={(e) => setCandidateNote(e.target.value)}
          onBlur={() => updateNotes(candidateNote)}
          containerProps={{ style: { minHeight: 300, width: '95%' } }}
        >
          <Toolbar>
            <BtnUndo />
            <BtnRedo />
            <Separator />
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <Separator />
            <BtnNumberedList />
            <BtnBulletList />
            <Separator />
            <BtnLink />
            <BtnClearFormatting />
            <Separator />
            <BtnStyles />
          </Toolbar>
        </Editor>
      </EditorProvider>
      <Box display={'flex'} justifyContent={'flex-end'} width={'95%'} paddingTop={theme.spacing(1)}>
        <Button variant="outlined" onClick={() => updateNotes(candidateNote)} disabled={candidateNote.length === 0}>
          {t('candidatePanel.saveNote')}
        </Button>
      </Box>
    </Box>
  );
};

export default Note;

/* eslint-disable no-unused-vars */
import { Box, Button, Card, TextField, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import emailService from '../services/emailService';
import PageTemplate from './PageTemplate';
import EmailSelect, { SelectOption } from '../components/email/EmailSelect';
import FormattedEmailBox from '../components/email/FormattedEmailBox';
import { EmailSizeOptions, EmailStyleOptions } from '../types/email';
import { LanguageContext } from '../contexts/languagesContext';
import { RHFAutocompleteField } from '../components/hook-form/RHFAutocomplete';

export interface EmailGeneratorFormValues {
  previousEmails: string;
  userRequests: string;
  style: SelectOption | '';
  size: SelectOption | '';
  language: string;
}

export interface GenerateEmailData {
  previousEmails: string;
  userRequests: string;
  style: string;
  size: string;
  language: string;
}

export default function EmailGenerator() {
  const { t } = useTranslation();
  const [emailData, setEmailData] = useState<Array<GenerateEmailData>>([]);
  const pageTitle = t('emailGenerator.title');
  const { defaultLanguage, languagesOptions } = useContext(LanguageContext);

  const previousEmailsLabel = t('emailGenerator.previousEmailsLabel');
  const previousEmailsPlaceholder = t('emailGenerator.previousEmailsPlaceholder');
  const userRequestsLabel = t('emailGenerator.userRequestsLabel');
  const userRequestsPlaceholder = t('emailGenerator.inputMinLengthMessage');
  const chooseLanguagePlaceholder = t('chooseLanguage');

  const { handleSubmit, register, formState, control } = useForm<EmailGeneratorFormValues>({
    defaultValues: {
      previousEmails: '',
      userRequests: '',
      style: '',
      size: '',
      language: defaultLanguage,
    },
  });

  const { errors } = formState;

  const onSubmit = async (data: EmailGeneratorFormValues) => {
    if (!data.style || !data.size) return;

    const mappedData = {
      ...data,
      style: data.style.value,
      size: data.size.value,
    };

    setEmailData([mappedData]);
  };

  const fetchGeneratedEmail = async (data: GenerateEmailData) => {
    const { result } = await emailService.generateEmail(data);
    return result;
  };

  const FormattedGeneratedEmails = emailData.map((data, i) => (
    <FormattedEmailBox key={`email=${i}`} data={data} fetchEmail={fetchGeneratedEmail} />
  ));

  return (
    <PageTemplate pageTitle={pageTitle}>
      <Container>
        <TextFieldsWrapper>
          <TextField
            {...register('previousEmails')}
            label={previousEmailsLabel}
            placeholder={previousEmailsPlaceholder}
            multiline
            minRows={10}
            maxRows={15}
            fullWidth
            error={Boolean(errors.previousEmails)}
            helperText={errors.previousEmails?.message}
          />
          <TextField
            {...register('userRequests', {
              required: { value: true, message: userRequestsPlaceholder },
            })}
            label={userRequestsLabel}
            placeholder={userRequestsLabel}
            multiline
            minRows={3}
            maxRows={15}
            fullWidth
            error={Boolean(errors.userRequests)}
            helperText={errors.userRequests?.message}
          />
        </TextFieldsWrapper>
        <SubmitSection>
          <SelectContainer>
            <EmailSelect
              control={control}
              name="style"
              label={t('emailGenerator.selectEmailStyleLabel')}
              options={EmailStyleOptions}
              error={errors.style}
            />
            <EmailSelect
              control={control}
              name="size"
              label={t('emailGenerator.selectEmailSizeLabel')}
              options={EmailSizeOptions}
              error={errors.size}
            />
          </SelectContainer>

          <SelectContainer>
            <RHFAutocompleteField
              name="language"
              control={control}
              placeholder={chooseLanguagePlaceholder}
              options={languagesOptions}
              disableClearable
            />
            <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
              {t('send')}
            </Button>
          </SelectContainer>
        </SubmitSection>

        <GeneratedEmailContainer>{FormattedGeneratedEmails}</GeneratedEmailContainer>
      </Container>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  overflow: 'unset',
}));

const TextFieldsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const SubmitSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
}));

const SelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  flex: 1,
}));

const GeneratedEmailContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-around',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    maxWidth: 800,
  },
}));

import { Box, Button, Card, Divider, styled, Switch, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RecruitmentProcessingStatusComponent from '../components/recruitment/RecruitmentProcessingStatus';
import PageTemplate from './PageTemplate';
import Iconify from '../components/iconify';
import gptRecruiterService, {
  Applicant,
  Recruitment,
  RecruitmentCvStatus,
  RecruitmentStatus,
} from '../services/gptRecruiterService';
import ApplicantsListKeywords from '../components/recruitment/ApplicantsListKeywords';
import ApplicantsList from '../components/recruitment/ApplicantsList';
import RecruitmentDocuments from '../components/recruitment/RecruitmentDocuments';

export default function Applications() {
  const { t } = useTranslation();
  const { recruitmentId } = useParams<{ recruitmentId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [applicants, setApplicants] = useState<Array<Applicant>>();
  const [recruitment, setRecruitment] = useState<Recruitment>();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [currentStatusIndex, setCurrentStatusIndex] = useState<RecruitmentCvStatus | null>(
    RecruitmentCvStatus.NotAssigned
  );
  const [keywords, setKeywords] = useState<string[]>([]);
  const [daysSinceUpload, setDaysSinceUpload] = useState<number | null>(null);
  const [statusStats, setStatusStats] = useState<{ [key in RecruitmentCvStatus]: number } | null>(null);
  const [useSmartMatchChecked, setUseSmartMatchChecked] = useState<boolean | null>(null);

  const pageTitle = t('recruitments.title');

  const getApplicantsByCv = async () => {
    await getApplicantsByCvWithParams(keywords, daysSinceUpload);
    await getApplicantsStatusStats();
  };

  const getApplicantsByCvWithParams = async (keywords: string[], daysSinceUpload: number | null) => {
    setIsPending(true);
    try {
      if (!recruitmentId) return;
      const applicants = await gptRecruiterService.getRecruitmentByIdCV({
        recruitmentId,
        status: currentStatusIndex,
        keywords,
        daysSinceUpload,
        useSmartMatch: useSmartMatchChecked ?? false,
      });
      setApplicants(applicants);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsPending(false);
    }
  };

  const getApplicantsStatusStats = async () => {
    try {
      if (!recruitmentId) return;
      const applicantsStatusStats = await gptRecruiterService.getApplicantStatusStats({
        recruitmentId,
      });

      setStatusStats(applicantsStatusStats);

      if (
        useSmartMatchChecked === null &&
        statusStats?.[RecruitmentCvStatus.Accepted] &&
        statusStats?.[RecruitmentCvStatus.Accepted] > 0
      ) {
        setUseSmartMatchChecked(true);
      }
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsPending(false);
    }
  };

  const CvsInStatus = (status: RecruitmentCvStatus) => statusStats?.[status] ?? null;

  const handleChange = (event: React.SyntheticEvent, newValue: RecruitmentCvStatus) => {
    setCurrentStatusIndex(newValue);
  };

  const filterUpdate = (keywords: string[], daysSinceUpload: number | null) => {
    setKeywords(keywords);
    setDaysSinceUpload(daysSinceUpload);

    getApplicantsByCvWithParams(keywords, daysSinceUpload);
  };

  const handleSmartMatchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseSmartMatchChecked(event.target.checked);
  };

  const handleDownloadCvs = async () => {
    if (!recruitmentId) return;
    try {
      const downloadFile = (fileURL: string) => {
        const anchorElement = document.createElement('a');

        document.body.appendChild(anchorElement);
        anchorElement.style.display = 'none';
        anchorElement.href = fileURL;
        anchorElement.download = response.fileName;
        anchorElement.click();

        window.URL.revokeObjectURL(fileURL);
      };

      const response = await gptRecruiterService.exportCvByStatus({ recruitmentId, status: currentStatusIndex });

      const blob = new Blob([response.binaryData], { type: response.fileType });
      const fileURL = URL.createObjectURL(blob);

      downloadFile(fileURL);
    } catch (error) {
      enqueueSnackbar(t('recruitment.downloadCvError'), { variant: 'error' });
    }
  };

  const getRecruitment = async () => {
    try {
      if (!recruitmentId) return;
      const recruitment = await gptRecruiterService.getRecruitmentById(recruitmentId);
      setRecruitment(recruitment);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const isAnyCvAccepted =
    statusStats?.[RecruitmentCvStatus.Accepted] && statusStats?.[RecruitmentCvStatus.Accepted] > 0;

  const backPath =
    recruitment?.status === RecruitmentStatus.InProgress
      ? '/dashboard/gptrecruiter/recruitments'
      : `/dashboard/gptrecruiter/recruitments/${recruitmentId}`;

  useEffect(() => {
    getApplicantsByCv();
    getRecruitment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatusIndex, useSmartMatchChecked]);

  return (
    <PageTemplate pageTitle={pageTitle}>
      <>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Box display={'flex'} flexDirection={'row'} gap={2}>
              <Box sx={{ flex: '1 1 auto', maxWidth: '400px', my: 1 }}>
                <Button
                  component={RouterLink}
                  to={`/dashboard/gptrecruiter/recruitments/${recruitmentId}`}
                  variant="contained"
                >
                  {t('recruitment.settings')}
                </Button>
              </Box>
              <Box sx={{ flex: '1 1 auto', maxWidth: '400px', my: 1 }}>
                <Button
                  component={RouterLink}
                  to={`/dashboard/gptrecruiter/recruitments/${recruitmentId}/applications/search`}
                  variant="contained"
                >
                  Rank and search applicants
                  <Iconify icon="mdi:magnify" sx={{ ml: 1 }} />
                </Button>
              </Box>
            </Box>
            <Box
              sx={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end', gap: 2, my: 1, 'flex-wrap': 'wrap' }}
            >
              <Button component={RouterLink} to={backPath} variant="contained">
                <Iconify icon="bi:arrow-left" sx={{ mr: 1 }} />
                {t('back')}
              </Button>
              <Button onClick={getApplicantsByCv}>
                <Iconify icon="material-symbols:refresh" sx={{ mr: 1 }} />
                {t('recruitment.refresh')}
              </Button>
            </Box>
          </Box>

          <StyledCard>
            <Typography variant="h5">{t('recruitment.uploadCvTitle')}</Typography>
            <RecruitmentDocuments
              recruitmentId={recruitmentId}
              disabled={recruitment?.status === RecruitmentStatus.Closed}
            />
          </StyledCard>
          <StyledCard>
            <ApplicantsListKeywords filterUpdate={filterUpdate} />
          </StyledCard>
          <StyledCard>
            <Typography variant="h5">
              {t('recruitment.applicantsHeading', { recruitmentName: recruitment?.name })}
            </Typography>
            <AbsoluteBox>
              <RecruitmentProcessingStatusComponent recruitmentId={recruitmentId} />
            </AbsoluteBox>
          </StyledCard>
          <Box>
            <StyledTabs
              value={currentStatusIndex}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              <StyledTab value={null} label={t('recruitment.applicantsStatus.all')} />
              <StyledTab
                value={RecruitmentCvStatus.NotAssigned}
                label={`${t('recruitment.applicantsStatus.notAssigned')} ${
                  CvsInStatus(RecruitmentCvStatus.NotAssigned)
                    ? `(${CvsInStatus(RecruitmentCvStatus.NotAssigned)})`
                    : ''
                }`}
              />
              <StyledTab
                value={RecruitmentCvStatus.Accepted}
                label={`${t('recruitment.applicantsStatus.accepted')} ${
                  CvsInStatus(RecruitmentCvStatus.Accepted) ? `(${CvsInStatus(RecruitmentCvStatus.Accepted)})` : ''
                }`}
              />
              <StyledTab
                value={RecruitmentCvStatus.Maybe}
                label={`${t('recruitment.applicantsStatus.maybe')} ${
                  CvsInStatus(RecruitmentCvStatus.Maybe) ? `(${CvsInStatus(RecruitmentCvStatus.Maybe)})` : ''
                }`}
              />
              <StyledTab
                value={RecruitmentCvStatus.Rejected}
                label={`${t('recruitment.applicantsStatus.rejected')} ${
                  CvsInStatus(RecruitmentCvStatus.Rejected) ? `(${CvsInStatus(RecruitmentCvStatus.Rejected)})` : ''
                }`}
              />
            </StyledTabs>

            <SmartMatchContainer>
              {currentStatusIndex !== RecruitmentCvStatus.Accepted && (
                <>
                  <Switch
                    checked={useSmartMatchChecked ?? false}
                    onChange={handleSmartMatchChange}
                    disabled={!isAnyCvAccepted}
                  />
                  <Box style={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
                    {isAnyCvAccepted && <p>{t('recruitment.useSmartMatch')}</p>}
                    {!isAnyCvAccepted && (
                      <Tooltip
                        title={
                          <>
                            <p>{t('recruitment.useSmartMatchTooltip')}</p>
                          </>
                        }
                      >
                        <p>{t('recruitment.useSmartMatch')}</p>
                      </Tooltip>
                    )}
                  </Box>
                </>
              )}
              <ExportButtonWrapper>
                <Button variant="outlined" disabled={!applicants?.length} onClick={handleDownloadCvs}>
                  <Iconify icon="bi:download" sx={{ marginRight: 1 }} />
                  {t('recruitment.exportCvs')}
                </Button>
              </ExportButtonWrapper>
            </SmartMatchContainer>

            <ApplicantsList
              applicants={applicants}
              params={{
                status: currentStatusIndex,
                keywords,
                daysSinceUpload,
                useSmartMatch: useSmartMatchChecked ?? false,
              }}
              isPending={isPending}
            />
            {/* not assigned should be the default status and include SUGGESTIONS */}
          </Box>
        </Container>
      </>
    </PageTemplate>
  );
}

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  marginRight: theme.spacing(1),
  '&:focus': {
    opacity: 1,
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const AbsoluteBox = styled(Box)(({ theme }) => ({
  top: 5,
  right: 10,
  position: 'absolute',
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    visibility: 'hidden',
  },
  [theme.breakpoints.up('sm')]: {
    visibility: 'visible',
  },
}));

const SmartMatchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  marginTop: theme.spacing(1),
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

const ExportButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
  },
}));

import { Box, Button, CircularProgress, Divider, styled, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { pdf } from '@react-pdf/renderer';
import { useContext, useState } from 'react';
import { CandidateBlindCvs } from '../../../services/gptRecruiterService';
import { BlindCvPdfDoc } from '../BlindCv/BlindCvPdf/BlindCvPdfDoc';
import { BlindSettingsContext } from '../../../contexts/blindSettingsContext';
import blindCvService from '../../../services/blindCvService';
import Iconify from '../../iconify';

interface BlindCvsProps {
  blindCvs: CandidateBlindCvs[];
  recruitmentId: string;
  candidateId: string;
}

const BlindCvs = ({ blindCvs, recruitmentId, candidateId }: BlindCvsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { blindCVSettings, organizationBackground } = useContext(BlindSettingsContext);
  const [loadingState, setLoadingState] = useState<{ [key: string]: boolean }>({});

  const handleGenerateBlindCV = () => {
    if (!recruitmentId || !candidateId) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      return;
    }
    navigate(`/dashboard/gptrecruiter/blindCv/create?cvId=${candidateId}&recruitmentId=${recruitmentId}`);
  };
  const handleGoBlindEdition = (id: string) => {
    navigate(`/dashboard/gptrecruiter/blindCv/edit/${id}?fromPath=${location.pathname}`);
  };

  const generatePdfBlob = async (id: string) => {
    const openPdf = (fileURL: string) => {
      window.open(fileURL, '_blank');
    };
    try {
      setLoadingState((prev) => ({ ...prev, [id]: true }));
      const response = await blindCvService.getBlindCv(id);

      const blob = await pdf(
        <BlindCvPdfDoc
          blindCvData={response.blindCvData}
          image={organizationBackground}
          padding={blindCVSettings.padding}
          includeInBlind={blindCVSettings.includeInBlind}
        />
      ).toBlob();
      const fileURL = URL.createObjectURL(blob);
      openPdf(fileURL);
    } catch (e) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setLoadingState((prev) => ({ ...prev, [id]: false }));
    }
  };

  return (
    <Wrapper>
      <Title>{t('candidatePanel.blindCvs.title')}</Title>
      <ActionsWrapper>
        <Button variant="outlined" onClick={handleGenerateBlindCV} sx={{ width: '20%' }}>
          {t('generateBlindCV')}
        </Button>
        <Divider sx={{ width: '100%' }} />
      </ActionsWrapper>
      {blindCvs.length > 0 ? (
        blindCvs.map(({ id, language, createdAt }) => (
          <BlindItem key={id}>
            <BlindItemDetails>
              <Typography fontWeight={600}>{t('candidatePanel.blindCvs.name', { id })}</Typography>
              <Typography fontWeight={400}>{t('candidatePanel.blindCvs.language', { language })}</Typography>
              <Typography fontWeight={400}>
                {t('candidatePanel.blindCvs.date', { date: format(new Date(createdAt), 'dd/MM/yyyy') })}
              </Typography>
            </BlindItemDetails>
            <BlindItemActions>
              <Button variant="outlined" onClick={() => handleGoBlindEdition(id)}>
                {t('edit')}
              </Button>
              <Button variant={'outlined'} onClick={() => generatePdfBlob(id)} sx={{ width: '20%' }}>
                {loadingState[id] ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    <Iconify icon="bi:download" sx={{ marginRight: 1 }} />
                    {t('blindCvEdit.ExportToPdf')}
                  </>
                )}
              </Button>
            </BlindItemActions>
            <Divider sx={{ width: '100%' }} />
          </BlindItem>
        ))
      ) : (
        <Typography width={'100%'} textAlign={'center'}>
          {t('candidatePanel.blindCvs.noBlindCvs')}
        </Typography>
      )}
    </Wrapper>
  );
};

export default BlindCvs;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  padding: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  fontSize: '1.5rem',
  fontWeight: 600,
}));

const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  width: '100%',
  marginBottom: theme.spacing(1),
  gap: theme.spacing(1),
  flexDirection: 'column',
}));

const BlindItem = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const BlindItemDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  width: '100%',
}));
const BlindItemActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  width: '100%',
  marginBottom: theme.spacing(1),
}));

import { FormControl, InputLabel, MenuItem, Select, TextField, styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContextFormValues } from '../../pages/Contexts';
import { ContextType } from '../../services/contextService';

const ContextForm = () => {
  const { t } = useTranslation();
  const { register, formState, control } = useFormContext<ContextFormValues>();
  const { errors } = formState;
  const contextTypes = Object.entries(ContextType).map(([key, value]) => ({
    value,
    label: t(`contexts.form.${key}`),
  }));

  return (
    <StyledForm>
      <TextField
        label={t('contexts.form.title')}
        {...register('title', {
          required: { value: true, message: t('contexts.form.titleRequired') },
        })}
        error={!!errors.title}
        helperText={errors.title?.message}
      />
      <TextField
        label={t('contexts.form.description')}
        {...register('description', {
          required: { value: true, message: t('contexts.form.descriptionRequired') },
        })}
        error={!!errors.description}
        helperText={errors.description?.message}
      />
      <Controller
        name="contextType"
        control={control}
        render={({ field }) => (
          <FormControl variant="outlined" fullWidth>
            <InputLabel>{t('contexts.form.contextType')}</InputLabel>
            <Select {...field} label={t('contexts.form.contextType')}>
              {contextTypes.map((contextType) => (
                <MenuItem key={contextType.value} value={contextType.value}>
                  {contextType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </StyledForm>
  );
};

export default ContextForm;

const StyledForm = styled('form')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  minHeight: '200px',
}));

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import chatService, { ChatIdentity } from '../../services/chatService';
import { PrivateChatFormValues } from '../../pages/PrivateChat';
import Iconify from '../iconify';

interface ChatHeadingProps {
  headingText: string;
  isChatSessionAlreadyCreated: boolean;
  handleResetChat: () => void;
  selectedChatIdentity: string;
  setSelectedChatIdentity: (chatIdentityId: string) => void;
}

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1 / 3,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1 / 2,
  gap: theme.spacing(2),
}));

const ChatHeading = ({
  headingText,
  isChatSessionAlreadyCreated,
  handleResetChat,
  selectedChatIdentity,
  setSelectedChatIdentity,
}: ChatHeadingProps) => {
  const [chatIdentities, setChatIdentities] = useState<Array<ChatIdentity>>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<PrivateChatFormValues>();

  useEffect(() => {
    const getChatIdentities = async () => {
      try {
        const { chatIdentities } = await chatService.getChatIdentities();
        setChatIdentities(chatIdentities);
      } catch (error) {
        enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      }
    };

    getChatIdentities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Add reusability for controlled select

  return (
    <Header>
      <Typography variant="subtitle1">{headingText}</Typography>
      <ButtonsContainer>
        <Tooltip title={t('privateChat.startNewChat')}>
          <Box>
            <IconButton sx={{ height: '80%' }} onClick={handleResetChat} disabled={!isChatSessionAlreadyCreated}>
              <Iconify icon="ic:baseline-library-add" />
            </IconButton>
          </Box>
        </Tooltip>
        <FormControl fullWidth>
          <InputLabel id="select-identity">{t('privateChat.selectChatIdentity')}</InputLabel>
          <Controller
            name="chatIdentityId"
            control={control}
            rules={{
              required: isChatSessionAlreadyCreated
                ? false
                : { value: true, message: t('privateChat.selectChatIdentityRequired') },
            }}
            render={({ field }) => (
              <Select
                disabled={isChatSessionAlreadyCreated}
                {...field}
                label={t('privateChat.selectChatIdentity')}
                value={field.value || selectedChatIdentity}
                error={!!errors.chatIdentityId}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  field.onChange(e.target.value);
                  setSelectedChatIdentity(e.target.value as string);
                }}
              >
                {chatIdentities?.map((identity) => (
                  <MenuItem key={identity.id} value={identity.id}>
                    {identity.title}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.chatIdentityId && (
            <Typography variant="caption" color="error">
              {errors.chatIdentityId.message}
            </Typography>
          )}
        </FormControl>
      </ButtonsContainer>
    </Header>
  );
};

export default ChatHeading;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { CompanyNameDetails, PositionDetails } from '../services/blindCvService';
import organizationService, { BlindCVSettingsProps } from '../services/organizationService';
import { UserContext } from './userContext';

interface BlindSettingsContext {
  blindCVSettings: BlindCVSettingsProps;
  setBlindCVSettings: (blindCVSettings: BlindCVSettingsProps) => void;
  organizationBackground: string;
  setOrganizationBackground: (organizationBackground: string) => void;
  getOrganizationSettings: () => void;
  getOrganizationBackground: () => void;
}

interface BlindSettingsContextProviderProps {
  children: React.ReactNode;
}

export const BlindSettingsContext = createContext<BlindSettingsContext>({} as BlindSettingsContext);

export const BlindSettingsProvider = ({ children }: BlindSettingsContextProviderProps) => {
  const defaultBlindCVSettings: BlindCVSettingsProps = {
    blindLanguage: 'English',
    positionDetails: PositionDetails.precise,
    companyNameDetails: CompanyNameDetails.exact,
    includeInBlind: { name: false, lastname: false, email: false },
    padding: { top: 40, sides: 40, bottom: 40 },
  };
  const [blindCVSettings, setBlindCVSettings] = useState<BlindCVSettingsProps>(defaultBlindCVSettings);
  const [organizationBackground, setOrganizationBackground] = useState<string>('');

  const { role } = useContext(UserContext);

  const getOrganizationSettings = async () => {
    try {
      const response = await organizationService.getOrganizationSettings();
      const settings = JSON.parse(response);
      if (settings.blindCVSettings) {
        setBlindCVSettings(settings.blindCVSettings);
      } else {
        setBlindCVSettings(defaultBlindCVSettings);
      }
    } catch (e) {
      setBlindCVSettings(defaultBlindCVSettings);
    }
  };

  const getOrganizationBackground = async () => {
    try {
      const response = await organizationService.getOrganizationBackground();
      const image = new Blob([response.data], { type: response.headers['content-type'] });
      const reader = new FileReader();
      reader.onloadend = () => {
        setOrganizationBackground(reader.result as string);
      };
      reader.readAsDataURL(image);
    } catch (err) {
      setOrganizationBackground('');
    }
  };

  useEffect(() => {
    if (!role)
      return;

    getOrganizationSettings();
    getOrganizationBackground();
  }, [role]);

  return (
    <BlindSettingsContext.Provider
      value={{
        blindCVSettings,
        setBlindCVSettings,
        organizationBackground,
        setOrganizationBackground,
        getOrganizationSettings,
        getOrganizationBackground,
      }}
    >
      {children}
    </BlindSettingsContext.Provider>
  );
};

import { Box, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Comment } from '../../../services/gptRecruiterService';

interface CommentsListProps {
  comments: Comment[];
}

export const CommentsList = ({ comments }: CommentsListProps) => {
  return (
    <CommentsListWrapper>
      {comments.map(({ comment, commentId, createdAt }) => {
        const formattedDate = format(new Date(createdAt), 'HH:mm dd.MM.yyyy');
        return (
          <StyledCommentWrapper key={commentId}>
            <Typography variant="caption" align="center" display="block">
              {formattedDate}
            </Typography>
            {comment}
          </StyledCommentWrapper>
        );
      })}
    </CommentsListWrapper>
  );
};

const StyledCommentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  width: '80%',
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.primary,
}));

const CommentsListWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  overflow: 'auto',
  gap: theme.spacing(1),
}));

import {
  Box,
  styled,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Divider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import gptRecruiterService, {
  Applicant,
  CandidateRecruitments,
  Recruitment,
  RecruitmentCvStatus,
  RecruitmentStatus,
} from '../../../services/gptRecruiterService';
import { useTranslationDictionary } from '../../../hooks/useTranslationDictionary';

interface RecruitmentsProps {
  candidate: Applicant;
  setCandidate: (candidate: Applicant) => void;
}

const Recruitments = ({ candidate, setCandidate }: RecruitmentsProps) => {
  const { recruitmentId, candidateId } = useParams<{ recruitmentId: string; candidateId: string }>();
  const [availableOrganizationRecruitments, setAvailableOrganizationRecruitments] = useState<Array<Recruitment>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const statusList = Object.entries(RecruitmentStatus);
  const cvStatusList = Object.entries(RecruitmentCvStatus);

  const { control, getValues } = useForm({
    defaultValues: {
      newRecruitmentId: '',
    },
  });
  const { statusListDictionary, cvStatusListDictionary } = useTranslationDictionary({ t });

  const handleNavigateToOtherRecruitment = (newRecruitmentId: string) => {
    navigate(`/dashboard/gptrecruiter/recruitments/${newRecruitmentId}/applications`);
  };

  const getRecruitments = async () => {
    try {
      const activeOrganizationRecruitments = await gptRecruiterService.getActiveRecruitments();
      const availableOrganizationRecruitments = activeOrganizationRecruitments.filter(({ id }) =>
        candidate.recruitments.every((recruitment) => recruitment.id !== id)
      );
      setAvailableOrganizationRecruitments(availableOrganizationRecruitments);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const addToRecruitment = async (newRecruitmentId: string) => {
    if (!candidateId || !recruitmentId) return;
    try {
      await gptRecruiterService.addCandidateToOtherRecruitment({
        recruitmentId,
        cvId: candidateId,
        newRecruitmentId,
      });
      enqueueSnackbar(t('recruitmentSearch.applicantCard.addedToRecruitment'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('recruitmentSearch.applicantCard.addedToRecruitmentError'), { variant: 'error' });
    }
  };

  const handleAddToOtherRecruitment = async () => {
    const newRecruitmentId = getValues().newRecruitmentId;
    const newRecruitment = availableOrganizationRecruitments.find(({ id }) => id === newRecruitmentId);
    setAvailableOrganizationRecruitments(
      availableOrganizationRecruitments.filter((recruitment) => recruitment !== newRecruitment)
    );
    const newRecruitmentStatus = Object.values(RecruitmentStatus).findIndex(
      (status) => status === newRecruitment!.status
    );
    setCandidate({
      ...candidate,
      recruitments: [
        ...candidate.recruitments,
        {
          id: newRecruitmentId,
          candidateStatus: 0,
          recruitmentStatus: newRecruitmentStatus,
          recruitmentName: newRecruitment!.name,
        },
      ],
    });
    addToRecruitment(newRecruitmentId);
  };

  useEffect(() => {
    getRecruitments();
  }, []);

  return (
    <Wrapper>
      <AddToRecruitmentSectionWrapper>
        <SectionTitle>{t('candidatePanel.recruitments.otherRecruitments')}</SectionTitle>
        <Controller
          name={'newRecruitmentId'}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <FormControl variant="outlined" sx={{ width: '40%' }}>
              <InputLabel>{t('candidatePanel.recruitments.inputLabel')}</InputLabel>
              <Select {...field} defaultValue={''}>
                {availableOrganizationRecruitments.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Button variant="outlined" onClick={handleAddToOtherRecruitment}>
          {t('candidatePanel.recruitments.addCandidateToOtherRecruitment')}
        </Button>
      </AddToRecruitmentSectionWrapper>
      <Divider sx={{ width: '100%' }} />
      <SectionTitle>{t('candidatePanel.recruitments.currentRecruitments')}</SectionTitle>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">{t('candidatePanel.recruitments.recruitmentName')}</StyledTableCell>
              <StyledTableCell align="center">{t('candidatePanel.recruitments.recruitmentStatus')}</StyledTableCell>
              <StyledTableCell align="center">{t('candidatePanel.recruitments.candidateStatus')}</StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {candidate.recruitments.map(({ id, recruitmentStatus, candidateStatus, recruitmentName }) => (
              <TableRow key={id}>
                <TableCell align="center">{recruitmentName}</TableCell>
                <TableCell align="center">{statusListDictionary[statusList[recruitmentStatus][1]]}</TableCell>
                <TableCell align="center">{cvStatusListDictionary[cvStatusList[candidateStatus][1]]}</TableCell>
                <TableCell align="center">
                  {id === recruitmentId ? (
                    <Tooltip title={t('candidatePanel.recruitments.currentRecruitmentTooltip')}>
                      <Box>
                        <Button variant="outlined" disabled>
                          {t('candidatePanel.recruitments.navigateToOtherRecruitments')}
                        </Button>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Button variant="outlined" onClick={() => handleNavigateToOtherRecruitment(id)}>
                      {t('candidatePanel.recruitments.navigateToOtherRecruitments')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default Recruitments;

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
const AddToRecruitmentSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
const SectionTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontWeight: 600,
  fontSize: '1.5rem',
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
}));

import { Card, Typography, styled, IconButton, CircularProgress, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Iconify from '../iconify';
import { useFormattedMessages } from '../../hooks/useFormattedMessage';
import { SocialMediaPostDto } from '../../services/contentGeneratorService';

type SocialMediaPostBoxBoxProps = {
  data: SocialMediaPostDto;
  fetchPost: (data: any) => Promise<string>;
};

const SocialMediaPostBox = ({ data, fetchPost }: SocialMediaPostBoxBoxProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const [socialMediaPost, serSocialMediaPost] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      setIsPending(true);
      try {
        const resposeData = await fetchPost(data);
        serSocialMediaPost(resposeData);
      } catch (error) {
        enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
      } finally {
        setIsPending(false);
      }
    };

    if (!data) return;

    loadData();
  }, [data]);

  const handleCopy = () => {
    navigator.clipboard.writeText(socialMediaPost);
    setIsCopied(true);
    enqueueSnackbar(t('coppiedToClipboard'), { variant: 'success' });
  };

  const formattedSocialMediaPost = useFormattedMessages(socialMediaPost);

  if (!data) {
    return null;
  }

  return (
    <Wrapper>
      {isPending ? (
        <CenterContent>
          <CircularProgress />
        </CenterContent>
      ) : (
        <>
          <Typography variant="body1" component="div" paragraph>
            {formattedSocialMediaPost}
          </Typography>
          <IconButton
            onClick={handleCopy}
            sx={{ position: 'absolute', top: 10, right: 10, color: isCopied ? 'gray' : 'black' }}
          >
            <Iconify icon="ph:copy" />
          </IconButton>
        </>
      )}
    </Wrapper>
  );
};

const CenterContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  height: '100%',
}));

const Wrapper = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  alignSelf: 'flex-start',
  position: 'relative',
  flex: 1,
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    maxWidth: 800,
    flex: '0 1 auto',
  },
}));

export default SocialMediaPostBox;

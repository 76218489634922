import { styled, Box, Typography, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import BlindStyledCardWrapper from '../BlindStyledCardWrapperProps';
import EditEducationHistoryItem from './EditEducationHistoryItem';
import { EducationAndQualification } from '../../../../../services/blindCvService';
import StyledModal from '../../../../modals/styled-modal';

interface BlindTextSectionComponentProps {
  title: string;
  copyText: string;
  values: EducationAndQualification[];
  submitEdit: (updatedValues: EducationAndQualification[]) => Promise<void>;
}

const ArraySectionEditComponent = ({
  array,
  onUpdate,
}: {
  array: EducationAndQualification[];
  onUpdate: (updatedValue: EducationAndQualification[]) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const [edittedArray, setEdditedArray] = useState(array);
  const [newItem, setNewItem] = useState<EducationAndQualification>({} as EducationAndQualification);
  const [isAddingNewItem, setIsAddingNewItem] = useState<boolean>(false);
  const handleAddItem = async () => {
    const newArr = [...edittedArray, newItem];
    setEdditedArray(newArr);
    await onUpdate(newArr);
    setNewItem({ name: '', time: '', detail: '' } as EducationAndQualification);
  };

  const handleDeleteItem = async (value: EducationAndQualification) => {
    const newArr = [...edittedArray.filter((q) => q !== value)];
    setEdditedArray(newArr);
    await onUpdate(newArr);
  };

  const handleEditItem = async (value: EducationAndQualification, newValue: EducationAndQualification) => {
    const newArr = [...edittedArray.map((q) => (q === value ? newValue : q))];
    setEdditedArray(newArr);
    await onUpdate(newArr);
  };

  return (
    <Box sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
      {edittedArray.map((item, id) => (
        <EditEducationHistoryItem
          handleDeleteItem={handleDeleteItem}
          handleEditItem={handleEditItem}
          item={item}
          key={id}
        />
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button variant="contained" onClick={() => setIsAddingNewItem(true)}>
          {t('blindCvEdit.addNewItem')}
        </Button>
      </Box>
      <StyledModal
        title={t('blindCvEdit.title')}
        onClose={() => {
          setIsAddingNewItem(false);
          setNewItem({ name: '', time: '', detail: '' } as EducationAndQualification);
        }}
        onContinue={() => {
          handleAddItem();
          setIsAddingNewItem(false);
        }}
        open={isAddingNewItem}
        continueButtonText={t('save')}
        disableBackdropClick
      >
        <FlexList>
          <TextField
            label={t('blindCvEdit.education.name')}
            value={newItem.name}
            onChange={(event) => setNewItem({ ...newItem, name: event.target.value })}
            fullWidth
            multiline
          />
          <TextField
            label={t('blindCvEdit.education.time')}
            value={newItem.time}
            onChange={(event) => setNewItem({ ...newItem, time: event.target.value })}
            fullWidth
          />
          <TextField
            label={t('blindCvEdit.education.description')}
            value={newItem.detail}
            onChange={(event) => setNewItem({ ...newItem, detail: event.target.value })}
            fullWidth
            multiline
          />
        </FlexList>
      </StyledModal>
    </Box>
  );
};

const BlindEditEducationHistory = ({ title, copyText, submitEdit, values }: BlindTextSectionComponentProps) => {
  const handleUpdate = async (updatedValues: EducationAndQualification[]) => {
    await submitEdit(updatedValues);
  };

  return (
    <BlindStyledCardWrapper title={title} copyText={copyText}>
      <ArraySectionEditComponent array={values} onUpdate={handleUpdate} />
    </BlindStyledCardWrapper>
  );
};

export default BlindEditEducationHistory;

const FlexList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
  width: '100%',
}));

import { Verbs } from '../../../services/userService';
import Iconify from '../../../components/iconify';
import i18n from '../../../i18n';

export interface NavConfig {
  title: string;
  label?: string;
  path: string;
  icon: JSX.Element;
  verbs?: Verbs[];
}

const icon = (name: string) => <Iconify icon={`${name}`} sx={{ width: 1, height: 1 }} />;

const navConfig: Array<NavConfig> = [
  {
    title: i18n.t('navbar.dashboard'),
    path: '/dashboard/app',
    icon: icon('ri:dashboard-line'),
  },
  {
    label: i18n.t('navbar.gptRecruiter') ?? undefined,
    title: i18n.t('navbar.recruitments'),
    path: '/dashboard/gptrecruiter/recruitments',
    icon: icon('fluent:people-team-20-regular'),
    verbs: [Verbs.RecruitmentCreate],
  },
  {
    title: i18n.t('navbar.recruiterApplicatnsSearch'),
    path: '/dashboard/gptrecruiter/applicants/search',
    icon: icon('fluent:people-search-20-regular'),
    verbs: [Verbs.RecruitmentCreate],
  },
  {
    label: i18n.t('navbar.blindCV') ?? undefined,
    title: i18n.t('navbar.blindCvGenerator'),
    path: '/dashboard/gptrecruiter/blindCv/create',
    icon: icon('solar:document-outline'),
    verbs: [Verbs.RecruitmentBlindCvCreate],
  },
  {
    title: i18n.t('navbar.blindCVList'),
    path: '/dashboard/gptrecruiter/blindCv/list',
    icon: icon('solar:document-outline'),
    verbs: [Verbs.RecruitmentBlindCvCreate],
  },
  {
    label: i18n.t('navbar.chat') ?? undefined,
    title: i18n.t('navbar.privateChat'),
    path: '/dashboard/private-chat',
    icon: icon('carbon:chat'),
    verbs: [Verbs.ChatChat],
  },
  {
    title: i18n.t('navbar.contexts'),
    path: '/dashboard/contexts',
    icon: icon('eos-icons:organization-outlined'),
    verbs: [Verbs.ChatContextList],
  },
  {
    title: i18n.t('navbar.chatIdentities'),
    path: '/dashboard/chat-identities',
    icon: icon('mdi-account-multiple-outline'),
    verbs: [Verbs.ChatIdentitiesManage],
  },
  {
    label: i18n.t('navbar.email') ?? undefined,
    title: i18n.t('navbar.emailEnhancer'),
    path: '/dashboard/email-enhancer',
    icon: icon('ic:mail-outline'),
    verbs: [Verbs.EmailEnhance],
  },
  {
    label: i18n.t('navbar.organization') ?? undefined,
    title: i18n.t('navbar.organizationSettings'),
    path: '/dashboard/organization-settings',
    icon: icon('mdi:users-outline'),
    verbs: [Verbs.OrganizationUserList],
  },
];

const navConfigInstructions: Array<NavConfig> = [
  {
    label: i18n.t('navbar.instructions') ?? undefined,
    title: i18n.t('navbar.tableOfContents'),
    path: '/instruction/main',
    icon: icon('mdi:book-open-page-variant-outline'),
  },
  {
    title: i18n.t('navbar.appLanguage'),
    path: '/instruction/language',
    icon: icon('mdi:book-open-page-variant-outline'),
  },
  {
    title: i18n.t('navbar.contexts'),
    path: '/instruction/contexts',
    icon: icon('eos-icons:organization-outlined'),
  },
  {
    title: i18n.t('navbar.chatIdentities'),
    path: '/instruction/chat-identities',
    icon: icon('mdi-account-multiple-outline'),
  },
  {
    title: i18n.t('navbar.privateChat'),
    path: '/instruction/private-chat',
    icon: icon('carbon:chat'),
  },
  {
    title: i18n.t('navbar.emailGenerator'),
    path: '/instruction/email-generator',
    icon: icon('ic:mail-outline'),
  },
  {
    title: i18n.t('navbar.emailEnhancer'),
    path: '/instruction/email-enhancer',
    icon: icon('ic:mail-outline'),
  },
  {
    title: i18n.t('navbar.users'),
    path: '/instruction/users',
    icon: icon('mdi:users-outline'),
  },
  {
    title: i18n.t('navbar.productDescriptionInstruction'),
    path: '/instruction/product-description',
    icon: icon('bi:text-paragraph'),
  },
  {
    title: i18n.t('navbar.socialMediaPostInstruction'),
    path: '/instruction/social-media',
    icon: icon('bi:text-paragraph'),
  },
  {
    title: i18n.t('navbar.recruiterInstruction'),
    path: '/instruction/recruiter',
    icon: icon('fluent:people-team-20-regular'),
  },
  {
    title: i18n.t('navbar.customizeBlindInstruction'),
    path: '/instruction/blind-customization',
    icon: icon('solar:document-outline'),
  },
];

export default { navConfig, navConfigInstructions };

/* eslint-disable no-unused-vars */
import React, { ReactNode } from 'react';
import { Box, styled, TextField, InputAdornment, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ControlledAutocomplete } from '../hook-form/ControlledAutocomplete';



export interface ApplicantsListKeywordsFormValues {
    SearchQuery: string;
    Keywords: string[];
    DaysSinceUpload: number | null;
}

export interface ApplicantsListKeywordsProps {
    filterUpdate: (keywords: string[], daysSinceUpload: number | null) => void;
}

export default function ApplicantsListKeywords({ filterUpdate }: ApplicantsListKeywordsProps) {
    const { t } = useTranslation();
    const { handleSubmit, register, formState, control, watch } = useForm<ApplicantsListKeywordsFormValues>({
        defaultValues: {
            Keywords: [],
            DaysSinceUpload: null
        },
    });

    // const watchKeywords = watch('Keywords');
    // const watchDaysSinceUpload = watch('DaysSinceUpload');

    // useEffect(() => {
    //     setKeywords(watchKeywords);
    // }, [watchKeywords]);

    // useEffect(() => {
    //     setDaysSinceUpload(watchDaysSinceUpload);
    // }, [watchDaysSinceUpload]);

    const onSubmit = async (data: ApplicantsListKeywordsFormValues) => {
        filterUpdate(data.Keywords, data.DaysSinceUpload);
    };


    const { errors } = formState;


    return (
        <>
            <FormContainer>
                <StyledTypography>{t('recruitment.applyFilters')}</StyledTypography>
                <ApplicantsListKeywordsFrom errors={errors} register={register} control={control} submitButton={(
                    <ButtonContainer>
                        <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
                            {t('recruitmentSearch.apply')}
                        </Button>
                    </ButtonContainer>
                )} />

            </FormContainer>
        </>
    );
}

interface ProductDescriptionInputsProps {
    errors: any;
    register: any;
    control: any;
    submitButton: ReactNode;
}

function ApplicantsListKeywordsFrom({ errors, register, control, submitButton }: ProductDescriptionInputsProps) {

    const { t } = useTranslation();

    return (
        <InputFieldsWrapper>
            <ControlledAutocomplete
                control={control}
                name="Keywords"
                label={t('recruitmentSearch.form.keywords.label') ?? "keywords"}
                placeholder={t('recruitmentSearch.form.keywords.placeholder') ?? "keywords"}
                requiredMessage={t('recruitmentSearch.form.keywords.exception') ?? "keywords"}
            />
            <InfoBox>
                <div>
                    <TextField
                        label={t('recruitmentSearch.form.daysSinceUpload.label')}
                        placeholder={t('recruitmentSearch.form.daysSinceUpload.placeholder')}
                        type='number'
                        {...register('DaysSinceUpload', {
                            min: 0,
                        })}
                        sx={{ width: '25ch' }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">days</InputAdornment>
                        }}
                        error={Boolean(errors.DaysSinceUpload)}
                        helperText={errors.DaysSinceUpload?.message}
                    />
                </div>

                {submitButton}
            </InfoBox>

        </InputFieldsWrapper>
    );
}


const InputFieldsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const FormContainer = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
}));

const InfoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.up("xs")]: {
        flexDirection: 'column'
    },
    [theme.breakpoints.up("sm")]: {
        flexDirection: 'row'
    },
    justifyContent: 'flex-end',
    width: '100%',
    gap: theme.spacing(4),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
}));

/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState } from 'react';
import userService, { UserRoles, Verbs } from '../services/userService';

export const UserContext = createContext<UserContext>({} as UserContext);
const allVerbs = Object.values(Verbs);

interface UserContext {
  role: UserRoles | undefined;
  userVerbs: Verbs[] | undefined;
  allVerbs: typeof allVerbs;
  hasAccess: (requiredVerbs: Verbs[]) => boolean;
}

interface UserProviderProps {
  children: React.ReactNode;
}

interface UserData {
  role: UserRoles;
  userVerbs: Verbs[];
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [userData, setUserData] = useState<UserData>();
  const { accessToken } = userService.getUserTokes();

  const hasAccess = (requiredVerbs: Verbs[]): boolean =>
    requiredVerbs.every((verb) => userData?.userVerbs.includes(verb));

  useEffect(() => {
    const handleGetUserVerbs = async () => {
      try {
        const { role, verbs } = await userService.getUserVerbs();
        setUserData({ role, userVerbs: verbs });
      } catch (error) {
        console.log(error);
      }
    };

    if (accessToken) {
      handleGetUserVerbs();
    }
  }, [accessToken]);

  return (
    <UserContext.Provider value={{ allVerbs, role: userData?.role, userVerbs: userData?.userVerbs, hasAccess }}>
      {children}
    </UserContext.Provider>
  );
};

/* eslint-disable no-unused-vars */
import { FormControl, Typography, styled } from '@mui/material';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailEnhancerFormValues } from '../../pages/EmailEnhancer';
import { EmailGeneratorFormValues } from '../../pages/EmailGenerator';

export type SelectOption = {
  value: string;
  label: string;
};

type SelectComponentProps = {
  name: keyof EmailEnhancerFormValues | keyof EmailGeneratorFormValues;
  control: Control<EmailEnhancerFormValues> | Control<EmailGeneratorFormValues>;
  label: string;
  options: SelectOption[];
  error?: DeepMap<Record<string, any>, FieldError>;
};

interface StyledLabelProps {
  'data-error': boolean;
}

// TODO: Fix types for this component

const EmailEnhancerSelect = ({ name, control, label, options, error }: SelectComponentProps) => {
  const [dynamicOptions, setDynamicOptions] = useState<SelectOption[]>(options);
  const { t } = useTranslation();
  const inputRequiredMessage =
    name === 'style' ? t('emailEnhancer.selectStyleRequiredMessage') : t('emailEnhancer.selectSizeRequiredMessage');

  const createOption = (label: string) => ({
    label,
    value: label.toLowerCase(),
  });

  const handleCreate = (inputValue: string, onChange: (newValue: SelectOption) => void) => {
    const newOption = createOption(inputValue);
    setDynamicOptions((prev) => [...prev, newOption]);
    onChange(newOption);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: { value: true, message: inputRequiredMessage } }}
      render={({ field }) => (
        <FormControl sx={{ zIndex: 2 }} variant="outlined" fullWidth error={!!error}>
          <StyledLabel data-error={error?.message}>{label}</StyledLabel>
          <CreatableSelect
            {...field}
            defaultInputValue={field.value}
            defaultValue={field.value}
            value={field.value}
            inputRef={field.ref}
            onCreateOption={(inputValue) => handleCreate(inputValue, field.onChange)}
            options={dynamicOptions}
            placeholder="Select an option"
            isClearable
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: error ? 'red' : baseStyles.borderColor,
              }),
            }}
          />
          {error && <StyledError>{error.message}</StyledError>}
        </FormControl>
      )}
    />
  );
};

export default EmailEnhancerSelect;

const StyledLabel = styled(Typography)<StyledLabelProps>(({ theme, 'data-error': isError }) => ({
  fontSize: 12,
  marginBottom: theme.spacing(0.5),
  color: isError ? theme.palette.error.main : theme.palette.text.secondary,
}));

const StyledError = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.error.main,
}));

/* eslint-disable no-unused-vars */
import { Box, Button, Card, Checkbox, FormControlLabel, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import ProductDescriptionInputs from '../components/productDescription/ProductDescriptionInputs';
import contentGeneratorService, { ProductDescriptionDto } from '../services/contentGeneratorService';
import PageTemplate from './PageTemplate';
import {
  ProductDescriptionSize,
  ProductDescriptionStyle,
  ProductDescriptionStyleOptions,
  ProductDescriptionSizeOptions,
} from '../types/product_description';
import FormattedProductDescriptionBox from '../components/productDescription/FormattedProductDescriptionBox';
import ProductDescriptionSelect from '../components/productDescription/ProductDescriptionSelect';
import { LanguageContext } from '../contexts/languagesContext';
import { RHFAutocompleteField } from '../components/hook-form/RHFAutocomplete';

export interface ProductDescriptionFormValues {
  ProductName: string;
  ProductCategory: string;
  KeyFeatures: string;
  ProductDetails: string;
  BenefitsToCustomer: string;
  ProductDescriptionStyle: ProductDescriptionStyle;
  DescriptionSize: ProductDescriptionSize;
  language: string;
  useAssignedContexts: boolean;
}

export default function ProductDescription() {
  const { t } = useTranslation();
  const [productDescription, setProductDescription] = useState<Array<ProductDescriptionDto>>([]);
  const [checkedUsedContexts, setCheckedUsedContexts] = useState(false);
  const { defaultLanguage, languagesOptions } = useContext(LanguageContext);

  const pageTitle = t('productDescription.title');
  const chooseLanguagePlaceholder = t('chooseLanguage');

  const { handleSubmit, register, formState, control } = useForm<ProductDescriptionFormValues>({
    defaultValues: {
      ProductName: '',
      ProductCategory: '',
      KeyFeatures: '',
      ProductDetails: '',
      BenefitsToCustomer: '',
      ProductDescriptionStyle: ProductDescriptionStyle.minimalistAndElegant,
      DescriptionSize: ProductDescriptionSize.asYouThinkIsRequired,
      language: defaultLanguage,
      useAssignedContexts: false,
    },
  });

  const { errors } = formState;

  const onSubmit = async (data: ProductDescriptionFormValues) => {
    const composeProductDescriptionDto = (data: ProductDescriptionFormValues): string => 
       `${data.ProductName} is a ${data.ProductCategory} that ${data.KeyFeatures}. ${data.ProductDetails}. ${data.BenefitsToCustomer}.`
      
      // const objValues = {
      //   'Product category': data.ProductCategory,
      //   'Key features': data.KeyFeatures,
      //   'Product details': data.ProductDetails,
      //   'Benefits to customer': data.BenefitsToCustomer,
      // };
      // const entries = Object.entries(objValues).filter(([key, value]) => value !== null && value !== '');
      // const combinedString = entries.map(([key, value]) => `${key}: ${value}`).join('; ');
      // return combinedString;
    ;

    const productionDescriptionDto: ProductDescriptionDto = {
      ...data,
      ProductDescription: composeProductDescriptionDto(data),
      useContexts: checkedUsedContexts,
    };

    setProductDescription([productionDescriptionDto, productionDescriptionDto, productionDescriptionDto]);
  };

  const fetchGeneratedProductDescription = async (data: ProductDescriptionDto) => {
    const { result } = await contentGeneratorService.productDescription(data);
    return result;
  };

  const FormattedGeneratedDescriptions = productDescription.map((data, i) => (
    <FormattedProductDescriptionBox
      key={`description=${i}`}
      data={data}
      fetchDescription={fetchGeneratedProductDescription}
    />
  ));

  return (
    <PageTemplate pageTitle={pageTitle}>
      <Container>
        <ProductDescriptionInputs errors={errors} register={register} />
        <SubmitSection>
          <SelectContainer>
            <ProductDescriptionSelect
              control={control}
              name="ProductDescriptionStyle"
              label={t('productDescription.selectStyleLabel')}
              options={ProductDescriptionStyleOptions}
            />
            <ProductDescriptionSelect
              control={control}
              name="DescriptionSize"
              label={t('productDescription.selectSizeLabel')}
              options={ProductDescriptionSizeOptions}
            />
          </SelectContainer>

          <SelectContainer>
            <RHFAutocompleteField
              name="language"
              control={control}
              placeholder={chooseLanguagePlaceholder}
              options={languagesOptions}
              disableClearable
            />
            <Controller
              name="useAssignedContexts"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value || checkedUsedContexts}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        setCheckedUsedContexts(e.target.checked);
                      }}
                    />
                  }
                  label={t('privateChat.useAssignedContexts')}
                />
              )}
            />
            <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
              {t('send')}
            </Button>
          </SelectContainer>
        </SubmitSection>

        <GeneratedDescriptionContainer>{FormattedGeneratedDescriptions}</GeneratedDescriptionContainer>
      </Container>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const SubmitSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
}));

const SelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  flex: 1,
}));

const GeneratedDescriptionContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-around',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    maxWidth: 800,
  },
}));

/* eslint-disable no-unused-vars */
import i18n from '../i18n';

export enum ProductDescriptionStyle {
  conciseAndInformative = 'Concise and Informative',
  descriptiveAndImaginative = 'Descriptive and Imaginative',
  storytelling = 'Storytelling',
  humorousAndLightHearted = 'Humorous and Light-hearted',
  minimalistAndElegant = 'Minimalist and Elegant',
  formal = 'Formal',
}

export enum ProductDescriptionSize {
  shortAndSnappy = 'Short and Snappy',
  mediumLength = 'Medium-Length',
  longAndInformative = 'Long and Informative',
  briefBulletedPoints = 'Brief Bulleted Points',
  asYouThinkIsRequired = 'As you think is required',
}

export const ProductDescriptionStyleOptions = Object.entries(ProductDescriptionStyle).map(([key, value]) => ({
  value,
  label: i18n.t(`productDescription.styles.${key}`),
}));

export const ProductDescriptionSizeOptions = Object.entries(ProductDescriptionSize).map(([key, value]) => ({
  value,
  label: i18n.t(`productDescription.sizes.${key}`),
}));

import React from 'react';

type Message = string;

export function useFormattedMessages(message: string): JSX.Element[] {
  const messages: Message[] = message.split('\n');

  return messages.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
}

/* eslint-disable no-unused-vars */
import { TextField, styled, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledModal from '../../../../modals/styled-modal';
import Iconify from '../../../../iconify';
import { PreviousProject } from '../../../../../services/blindCvService';
import { EmploymentArraySectionEditComponent } from './EmploymentArraySectionEditComponent';

const EditEmploymentHistoryItem = ({
  item,
  handleDeleteItem,
  handleEditItem,
}: {
  item: PreviousProject;
  handleDeleteItem: (question: PreviousProject) => void;
  handleEditItem: (question: PreviousProject, newItem: PreviousProject) => void;
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [confirmDeleteItem, setConfirmDeleteItem] = useState<boolean>(false);
  const [newPosition, setNewPosition] = useState<string>(item.position);
  const [newCompanyName, setNewCompanyName] = useState<string>(item.companyName);
  const [newTime, setNewTime] = useState<string>(item.time);
  const [newDetails, setNewDetails] = useState<string>(item.details);
  const [newDetailsList, setNewDetailsList] = useState<string[]>(item.detailsList);

  const [newItem, setNewItem] = useState<PreviousProject>({
    position: newPosition,
    companyName: newCompanyName,
    time: newTime,
    details: newDetails,
    detailsList: newDetailsList,
  } as PreviousProject);

  const { t } = useTranslation();
  const handleUpdate = (updatedValues: string[]) => {
    setNewDetailsList(updatedValues);
  };

  useEffect(() => {
    setNewItem({
      position: newPosition,
      companyName: newCompanyName,
      time: newTime,
      details: newDetails,
      detailsList: newDetailsList,
    } as PreviousProject);
  }, [newPosition, newCompanyName, newTime, newDetails, newDetailsList]);

  return (
    <StyledBox>
      <StyledModal
        title={t('blindCvEdit.title')}
        onClose={() => setIsEditing(false)}
        onContinue={() => {
          handleEditItem(item, newItem);
          setIsEditing(false);
        }}
        open={isEditing}
        continueButtonText={t('save')}
        disableBackdropClick
      >
        <FlexList>
          <TextField
            label={t('blindCvEdit.workExperience.position')}
            defaultValue={newPosition}
            onChange={(event) => setNewPosition(event.target.value)}
            fullWidth
            multiline
          />
          <TextField
            label={t('blindCvEdit.workExperience.companyName')}
            defaultValue={newCompanyName}
            onChange={(event) => setNewCompanyName(event.target.value)}
            fullWidth
            multiline
          />
          <TextField
            label={t('blindCvEdit.workExperience.time')}
            defaultValue={newTime}
            onChange={(event) => setNewTime(event.target.value)}
            fullWidth
          />
          <TextField
            label={t('blindCvEdit.workExperience.description')}
            defaultValue={newDetails}
            onChange={(event) => setNewDetails(event.target.value)}
            fullWidth
            multiline
          />
          <EmploymentArraySectionEditComponent
            array={newDetailsList || []}
            onUpdate={handleUpdate}
            onCancel={() => setIsEditing(false)}
          />
        </FlexList>
      </StyledModal>
      <StyledModal
        title={t('blindCvEdit.title')}
        onClose={() => setConfirmDeleteItem(false)}
        onContinue={() => {
          setConfirmDeleteItem(false);
          handleDeleteItem(item);
        }}
        open={confirmDeleteItem}
        continueButtonText={t('yes')}
        disableBackdropClick
      >
        <Typography width={'100%'} textAlign={'center'} fontWeight={600}>
          {t('blindCvEdit.deleteItemConfirmation')}
        </Typography>
      </StyledModal>
      <FlexList>
        <Typography variant="body1" style={{ fontWeight: 'bolder' }} component="div">
          {item.position}
        </Typography>
        <Typography variant="body2" component="div">
          {item.companyName}
        </Typography>
        <Typography variant="body2" style={{ fontWeight: 'lighter' }} component="div">
          {item.time}
        </Typography>
        {item.details && (
          <Typography variant="body2" component="div">
            {item.details}
          </Typography>
        )}
        {item.detailsList && (
          <Typography variant="body2" component="div">
            {item.detailsList.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </Typography>
        )}
      </FlexList>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Tooltip title={t('edit')}>
          <IconButton onClick={() => setIsEditing(true)}>
            <Iconify icon="material-symbols:edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('delete')}>
          <IconButton onClick={() => setConfirmDeleteItem(true)}>
            <Iconify icon="material-symbols:delete" />
          </IconButton>
        </Tooltip>
      </Box>
    </StyledBox>
  );
};

export default EditEmploymentHistoryItem;

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  // border: `1px solid ${theme.palette.grey[300]}`,
}));

const FlexList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  rowGap: theme.spacing(1),
  width: '100%',
  padding: 0,
}));

/* eslint-disable no-unused-vars */
import { Box, Button, Card, Checkbox, FormControlLabel, TextField, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import contentGeneratorService, { SocialMediaPostDto } from '../services/contentGeneratorService';
import PageTemplate from './PageTemplate';
import { LanguageContext } from '../contexts/languagesContext';
import {
  SocialMediaPostSize,
  SocialMediaPostStyle,
  SocialMediaPostStyleOptions,
  SocialMediaPostSizeOptions,
  SocialMediaPostPurpose,
  SocialMediaPostPurposeOptions,
} from '../types/socialmedia_posts';
import SocialMediaPostSelect from '../components/SocialMediaPost/SocialMediaPostSelect';
import SocialMediaPostBox from '../components/SocialMediaPost/SocialMediaPostBox';
import SocialMediaToggle from '../components/SocialMediaPost/SocialMediaTypeSelect';
import { RHFAutocompleteField } from '../components/hook-form/RHFAutocomplete';

export interface SocialMediaPostFormValues {
  TargetPlatform: string;
  TargetAudience: string;
  CallToAction: string;
  Takeaway: string;
  PostInstructions: string;
  PostPurpose: SocialMediaPostPurpose;
  Style: SocialMediaPostStyle;
  Size: SocialMediaPostSize;
  language: string;
  useAssignedContexts: boolean;
}

export default function SocialMediaPosts() {
  const { t } = useTranslation();
  const [socialMediaPosts, setSocialMediaPosts] = useState<Array<SocialMediaPostDto>>([]);
  const [selectedPostTarget, setSelectedPostTarget] = useState<string | null>('Facebook');
  const { defaultLanguage, languagesOptions } = useContext(LanguageContext);
  const [checkedUsedContexts, setCheckedUsedContexts] = useState(false);

  const pageTitle = t('socialMediaPost.title');
  const descriptionLabel = t('socialMediaPost.productDescriptionLabel');
  const descriptionLabelPlaceholder = t('socialMediaPost.productDescriptionLabelPlaceholder');
  const chooseLanguagePlaceholder = t('chooseLanguage');

  const { handleSubmit, register, formState, control } = useForm<SocialMediaPostFormValues>({
    defaultValues: {
      PostInstructions: '',
      PostPurpose: SocialMediaPostPurpose.inform,
      Style: SocialMediaPostStyle.funnyAndHumorous,
      Size: SocialMediaPostSize.asYouThinkIsRequired,
      language: defaultLanguage,
      useAssignedContexts: false,
    },
  });

  const { errors } = formState;

  const onSubmit = async (data: SocialMediaPostFormValues) => {
    const composePostInstructions = (data: SocialMediaPostFormValues): string => {
      const objValues = {
        'Target Audience': data.TargetAudience,
        'Call to action': data.CallToAction,
        Takeaway: data.Takeaway,
        'Post purpose': data.PostPurpose,
        'Write about': data.PostInstructions,
      };
      const entries = Object.entries(objValues).filter(([key, value]) => value !== null && value !== '');
      const combinedString = entries.map(([key, value]) => `${key}: ${value}`).join('; ');
      return combinedString;
    };

    const socialMediaPostDto: SocialMediaPostDto = {
      Style: data.Style,
      Size: data.Size,
      language: data.language,
      PostTarget: selectedPostTarget || 'Facebook',
      PostInstructions: composePostInstructions(data),
      useContexts: checkedUsedContexts,
    };

    setSocialMediaPosts([socialMediaPostDto, socialMediaPostDto, socialMediaPostDto]);
  };

  const fetchGeneratedProductDescription = async (data: SocialMediaPostDto) => {
    const { result } = await contentGeneratorService.socialMediaPost(data);
    return result;
  };

  const FormattedPosts = socialMediaPosts.map((data, i) => (
    <SocialMediaPostBox key={`socialmediapost=${i}`} data={data} fetchPost={fetchGeneratedProductDescription} />
  ));

  return (
    <PageTemplate pageTitle={pageTitle}>
      <Container>
        <SocialMediaToggle selected={selectedPostTarget} setSelected={setSelectedPostTarget} />
        <ColumnContainer>
          <SocialMediaPostSelect
            control={control}
            name="PostPurpose"
            label={t('socialMediaPost.postPurposesLabel')}
            options={SocialMediaPostPurposeOptions}
          />
          <TextField
            {...register('TargetAudience')}
            label={t('socialMediaPost.targetAudience')}
            placeholder={t('socialMediaPost.targetAudiencePlaceholder') ?? undefined}
            multiline
            rows={1}
            fullWidth
            error={Boolean(errors.PostInstructions)}
            helperText={errors.PostInstructions?.message}
          />
        </ColumnContainer>
        <ColumnContainer>
          <TextField
            {...register('CallToAction')}
            label={t('socialMediaPost.callToAction')}
            placeholder={t('socialMediaPost.callToActionPlaceholder') ?? undefined}
            multiline
            rows={1}
            fullWidth
            error={Boolean(errors.PostInstructions)}
            helperText={errors.PostInstructions?.message}
          />
          <TextField
            {...register('Takeaway')}
            label={t('socialMediaPost.takeaway')}
            placeholder={t('socialMediaPost.takeawayPlaceholder') ?? undefined}
            multiline
            rows={1}
            fullWidth
            error={Boolean(errors.PostInstructions)}
            helperText={errors.PostInstructions?.message}
          />
        </ColumnContainer>
        <TextField
          {...register('PostInstructions')}
          label={descriptionLabel}
          placeholder={descriptionLabelPlaceholder}
          multiline
          rows={5}
          fullWidth
          error={Boolean(errors.PostInstructions)}
          helperText={errors.PostInstructions?.message}
        />
        <SubmitSection>
          <SelectContainer>
            <SocialMediaPostSelect
              control={control}
              name="Style"
              label={t('socialMediaPost.selectStyleLabel')}
              options={SocialMediaPostStyleOptions}
            />
            <SocialMediaPostSelect
              control={control}
              name="Size"
              label={t('socialMediaPost.selectSizeLabel')}
              options={SocialMediaPostSizeOptions}
            />
          </SelectContainer>
        </SubmitSection>
        <SelectContainer>
          <RHFAutocompleteField
            name="language"
            control={control}
            placeholder={chooseLanguagePlaceholder}
            options={languagesOptions}
            disableClearable
          />
          <Controller
            name="useAssignedContexts"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value || checkedUsedContexts}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      setCheckedUsedContexts(e.target.checked);
                    }}
                  />
                }
                label={t('privateChat.useAssignedContexts')}
              />
            )}
          />
          <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            {t('send')}
          </Button>
        </SelectContainer>
        <GeneratedPostContainer>{FormattedPosts}</GeneratedPostContainer>
      </Container>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const SubmitSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

const SelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  flex: 1,
}));

const GeneratedPostContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-around',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    maxWidth: 800,
  },
}));

const ColumnContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axiosInstance';
import { UserRoles } from './userService';

interface CreateChatResponse {
  chatSessionId: string;
}

export interface ChatResponse {
  role: ChatRoles;
  dateTime: string;
  message: string;
  sources: Array<Source>;
}

export interface ChatIdentity {
  id: string;
  title: string;
  identity: string;
  temperature: number;
}

interface ChatIdentityResponse {
  chatIdentities: Array<ChatIdentity>;
}

export interface ChatHistory {
  chatSessionId: string;
  chatStartTime: string;
  firstChatMessage: string;
  chatIdentityTitle: string;
}

export interface Source {
  content: string;
  name: string;
  score: number;
}

export enum ChatRoles {
  USER = 'User',
  ASSISTANT = 'ASSISTANT',
  SYSTEM = 'SYSTEM',
}

export interface ChatMessage {
  chatSessionId: string;
  createdAt: string;
  chatIdentityId: string;
  conversationMessages: ChatResponse[];
}

async function createChat(): Promise<CreateChatResponse> {
  try {
    const response = await axiosInstance.post('/Chat/create/sessionId');
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function initChatSession(chatSessionId: string, chatIdentityId: string): Promise<null> {
  try {
    const response = await axiosInstance.post('/Chat/create/initializeChatSession', {
      chatSessionId,
      chatIdentityId,
      useContexts: true,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function generateChatResponse(chatSessionId: string, message: string): Promise<ChatResponse> {
  try {
    const response = await axiosInstance.post(`/Chat/${chatSessionId}/generateChatResponse`, {
      message,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getChatIdentities(): Promise<ChatIdentityResponse> {
  try {
    const response = await axiosInstance.get('/Chat/identities');
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getChatHistory(): Promise<Array<ChatHistory>> {
  try {
    const response = await axiosInstance.get('/Chat/user/history');
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getChatMessages(chatSessionId: string): Promise<ChatMessage> {
  try {
    const response = await axiosInstance.get(`/Chat/${chatSessionId}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export default {
  createChat,
  initChatSession,
  generateChatResponse,
  getChatIdentities,
  getChatHistory,
  getChatMessages,
};

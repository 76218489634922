import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@mui/material';
import ReactPDF, { BlobProvider, pdf } from '@react-pdf/renderer';
import { enqueueSnackbar } from 'notistack';
import Iconify from '../../../iconify';
import { BlindCvDataResponse } from '../../../../services/blindCvService';

interface PdfLinkButtonProps {
  Pdfdocument: React.ReactElement<ReactPDF.DocumentProps, string | React.JSXElementConstructor<any>>;
  buttonType?: 'contained' | 'outlined';
}

export default function PdfLinkButton({ Pdfdocument, buttonType }: PdfLinkButtonProps) {
  const { t } = useTranslation();
  const filename = `blind-cv.pdf`;

  const handleDownloadBlindCv = async () => {
    try {
      const openPdf = (fileURL: string) => {
        window.open(fileURL, '_blank');
      };

      const downloadFile = (fileURL: string) => {
        const anchorElement = document.createElement('a');

        document.body.appendChild(anchorElement);
        anchorElement.style.display = 'none';
        anchorElement.href = fileURL;
        anchorElement.download = filename;
        anchorElement.click();

        window.URL.revokeObjectURL(fileURL);
      };
      const blob = await pdf(Pdfdocument).toBlob();
      const fileURL = URL.createObjectURL(blob);

      openPdf(fileURL);

      // downloadFile(fileURL);
    } catch (error) {
      enqueueSnackbar(t('recruitment.downloadCvError'), { variant: 'error' });
    }
  };

  return (
    <Button variant={buttonType ?? 'contained'} onClick={() => handleDownloadBlindCv()}>
      <Iconify icon="bi:download" sx={{ marginRight: 1 }} />
      {t('blindCvEdit.ExportToPdf')}
    </Button>
  );
}

import { Box, Autocomplete, TextField, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SkillsSectionEditComponent = ({
  array,
  onCancel,
  onUpdate,
}: {
  array: string[];
  onCancel: () => void;
  onUpdate: (updatedValue: string[]) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const [edittedArray, setEdditedArray] = useState(array);

  const handleUpdate = async () => {
    await onUpdate(edittedArray);
  };

  return (
    <>
      <Box sx={{ gap: 0.5, display: 'flex', flexDirection: 'column' }}>
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={edittedArray}
          onChange={(_, val: string[]) => {
            setEdditedArray(val);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button variant="outlined" onClick={onCancel}>
          {t('blindCvEdit.cancel')}
        </Button>
        <Button variant="contained" onClick={handleUpdate}>
          {t('blindCvEdit.save')}
        </Button>
      </Box>
    </>
  );
};

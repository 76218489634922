import { styled, Typography, Card, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Iconify from '../../../iconify';

interface BlindStyledCardWrapperProps {
  title: string;
  children: JSX.Element;
  copyText: string;
  handleEdit?: () => void;
}

const BlindStyledCardWrapper = ({ title, children, copyText, handleEdit }: BlindStyledCardWrapperProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    enqueueSnackbar(t('coppiedToClipboard'), { variant: 'success', autoHideDuration: 2000 });
  };

  const ClipboardButton = ({ text }: { text: string }) => (
    <Tooltip title={t('copy')}>
      <IconButton
        onClick={() => handleCopy(text)}
        sx={{ position: 'absolute', top: 10, right: 10, color: isCopied ? 'gray' : 'black' }}
      >
        <Iconify icon="ph:copy" />
      </IconButton>
    </Tooltip>
  );

  const CardTitle = ({ title }: { title: string }) => (
    <Typography variant="h5" component="div" paragraph>
      {title}
    </Typography>
  );

  return (
    <StyledCard>
      <CardTitle title={title} />
      {children}

      {handleEdit && (
        <Tooltip title={t('edit')}>
          <IconButton onClick={handleEdit} sx={{ position: 'absolute', top: 10, right: 50 }}>
            <Iconify icon="eva:edit-fill" />
          </IconButton>
        </Tooltip>
      )}

      <ClipboardButton text={copyText} />
    </StyledCard>
  );
};

export default BlindStyledCardWrapper;

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

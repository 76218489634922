import axios, { AxiosError } from 'axios';
import userService from './services/userService';
import { history } from './utils/history';
import organizationService from './services/organizationService';
import snackbar from './utils/snackbar';
import i18n from './i18n';

export interface ErrorResponse {
  type: string;
  title: string;
  status: number;
  traceId: string;
}

const loginPath = '/login';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 240000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const refreshAccessToken = async () => {
  const { refreshToken, accessToken } = userService.getUserTokes();

  if (refreshToken && accessToken) {
    try {
      const { token: newAccessToken, refreshToken: newRefreshToken } = await userService.refreshToken(
        accessToken,
        refreshToken
      );

      userService.setUserTokensToLocalStorage(newAccessToken, newRefreshToken);

      return newAccessToken;
    } catch (error) {
      userService.logoutUserAndRedirect();
    }
  } else {
    userService.logoutUserAndRedirect();
  }

  return null;
};

axiosInstance.interceptors.request.use((config) => {
  const { accessToken } = userService.getUserTokes();

  if (accessToken && config.url !== loginPath) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<ErrorResponse>) => {
    const { config } = error;
    const originalRequest = config;
    const originalRequestUrl = config?.url;

    if (!error.response) return Promise.reject(error);

    if (error.response.status === 401) {
      if (originalRequestUrl !== loginPath) {
        if(originalRequestUrl === "/organization/subscribe") {
          throw new Error('RedirectToCreateOrganization');
        }
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken && originalRequest) {
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        }
      }
    }

    if (error.response.status === 402) {
      const handleRedirect = () => {
        const isOrganizationAdmin = userService.checkIfUserIsOrganizationAdmin();
        history.navigate(isOrganizationAdmin ? '/pricing-plans' : '/subscription-expired');
      };

      if (originalRequestUrl !== '/organization/isSubscriptionPaid') {
        return organizationService
          .getIsSubscriptionPaid()
          .then(() => snackbar.info(i18n.t('pricingPlans.limitReached')))
          .catch(() => {
            handleRedirect();
          });
      }

      handleRedirect();
    }

    return Promise.reject(error.response.data);
  }
);

export default axiosInstance;

import { useState } from 'react';
import BlindStyledCardWrapper from '../BlindStyledCardWrapperProps';
import { SkillsSectionEditComponent } from './SkillsSectionEditComponent';
import { SkillsSectionComponent } from './SkillsSectionComponent';

interface BlindSkillsSectionComponentProps {
  title: string;
  copyText: string;
  values: string[];
  submitEdit: (updatedValues: string[]) => Promise<void>;
}

const BlindSkillsSectionComponent = ({ title, copyText, submitEdit, values }: BlindSkillsSectionComponentProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleUpdate = async (updatedValues: string[]) => {
    await submitEdit(updatedValues);
    setIsEditing(false);
  };

  return (
    <BlindStyledCardWrapper title={title} copyText={copyText} handleEdit={handleEdit}>
      {isEditing ? (
        <SkillsSectionEditComponent array={values} onUpdate={handleUpdate} onCancel={() => setIsEditing(false)} />
      ) : (
        <SkillsSectionComponent array={values} />
      )}
    </BlindStyledCardWrapper>
  );
};

export default BlindSkillsSectionComponent;

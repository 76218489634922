import { Card, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import PageTemplate from './PageTemplate';
import ContextListHeading from '../components/context/ContextListHeading';
import ContextList from '../components/context/ContextList';
import contextService, { ContextResponse, ContextType } from '../services/contextService';
import useModal from '../hooks/useModal';
import StyledModal from '../components/modals/styled-modal';
import ContextForm from '../components/context/ContextForm';

export interface ContextFormValues {
  title: string;
  description: string;
  contextType: ContextType;
}

export default function Contexts() {
  const [isPending, setIsPending] = useState(false);
  const [isCreateContextPending, setIsCreateContextPending] = useState(false);
  const [contexts, setContexts] = useState<Array<ContextResponse>>([]);
  const [isModalOpen, toggleModal] = useModal();
  const { t } = useTranslation();
  const pageTitle = t('contexts.title');
  const { enqueueSnackbar } = useSnackbar();

  const form = useForm<ContextFormValues>({
    defaultValues: {
      title: '',
      description: '',
      contextType: ContextType.Document,
    },
  });

  const { handleSubmit } = form;

  const openModal = () => toggleModal();
  const closeModal = () => {
    toggleModal();
    form.reset();
  };

  const getContexts = async () => {
    setIsPending(true);
    try {
      const contexts = await contextService.getContexts();
      setContexts(contexts);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsPending(false);
    }
  };

  const onSubmit: SubmitHandler<ContextFormValues> = async ({ title, description, contextType }) => {
    setIsCreateContextPending(true);

    try {
      closeModal();
      await contextService.createContext({ title, description, contextType });
      await getContexts();
      enqueueSnackbar(t('contexts.contextsCreated'), { variant: 'success' });
    } catch (error) {
      const errorMessage = t('somethingWentWrong');
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      form.reset();
      setIsCreateContextPending(false);
    }
  };

  return (
    <PageTemplate pageTitle={pageTitle}>
      <FormProvider {...form}>
        <Container>
          <ContextListHeading openModal={openModal} isCreateContextPending={isCreateContextPending} />
          <ContextList contexts={contexts} getContexts={getContexts} isPending={isPending} />
        </Container>
        <StyledModal
          title={t('contexts.createNewContext')}
          open={isModalOpen}
          onClose={closeModal}
          onContinue={handleSubmit(onSubmit)}
        >
          <ContextForm />
        </StyledModal>
      </FormProvider>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axiosInstance';
import i18n from '../i18n';

// Define interfaces for Response models
interface RequestBlindCvResponse {
  blindCvId: string;
}

export enum BlindCvStatus {
  Undefined = 0,
  Initiated = 1,
  Started = 2,
  Finished = 3,
  Failed = 4,
  Queued = 5,
}

interface GetBlindCvRequestResponse {
  status: BlindCvStatus;
  blindCvData?: BlindCvDataResponse;
}

export interface BlindCvDataResponse {
  candidateName: string;
  candidateLastName: string;
  candidateEmail: string;
  candidateDescription: string;
  keySkills: string[];
  experience: string;
  previousProjects: PreviousProject[];
  languages: string[];
  educationAndQualification: EducationAndQualification[];
}

export interface PreviousProject {
  companyName: string;
  position: string;
  time: string;
  details: string;
  detailsList: string[];
}

export interface EducationAndQualification {
  name: string;
  time: string;
  detail: string;
}

export interface KeyInfo {
  name: string;
  lastName: string;
  email: string;
}
export interface BlindCvListResponse {
  id: string;
  createdAt: string;
  status: BlindCvStatus;
  insertedFileName: string;
  language: string;
  candidateName: string;
}

export enum PositionDetails {
  regular = 'Regular',
  generalized = 'Generalized',
  precise = 'Precise',
  bulletPoints = 'BulletPoints',
}

export enum CompanyNameDetails {
  exact = 'Exact',
  industry = 'Industry',
  brief = 'Brief',
}

export const PositionDetailsOptions = Object.entries(PositionDetails).map(([key, value]) => ({
  value,
  label: i18n.t(`blindCvGenerator.PositionDetails.${key}`),
}));

export const CompanyNameDetailsOptions = Object.entries(CompanyNameDetails).map(([key, value]) => ({
  value,
  label: i18n.t(`blindCvGenerator.CompanyNameDetails.${key}`),
}));

// Service methods
async function generateBlindCv(
  file: File,
  language: string,
  positionDetails: PositionDetails,
  companyNameDetails: CompanyNameDetails
): Promise<RequestBlindCvResponse> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('language', language);
  formData.append('positionDetails', positionDetails);
  formData.append('companyNameDetails', companyNameDetails);
  try {
    const response = await axiosInstance.post('/recruitment/blind', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function generateBlindCvForRecruitmentCv(
  recruitmentId: string,
  cvId: string,
  language: string,
  positionDetails: PositionDetails,
  companyNameDetails: CompanyNameDetails
): Promise<RequestBlindCvResponse> {
  try {
    const response = await axiosInstance.post(`/recruitment/${recruitmentId}/cv/${cvId}/blind`, {
      language,
      positionDetails,
      companyNameDetails,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getBlindCv(blindCvId: string): Promise<GetBlindCvRequestResponse> {
  try {
    const response = await axiosInstance.get(`/recruitment/blind/${blindCvId}`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function getOrganizationBlindCvs(): Promise<Array<BlindCvListResponse>> {
  try {
    const response = await axiosInstance.get(`/recruitment/blind/list`);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

async function updateBlindCv(blindCvId: string, blindCvData: BlindCvDataResponse): Promise<GetBlindCvRequestResponse> {
  try {
    const response = await axiosInstance.put(`/recruitment/blind/${blindCvId}`, { ...blindCvData });
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export default {
  generateBlindCv,
  generateBlindCvForRecruitmentCv,
  getBlindCv,
  getOrganizationBlindCvs,
  updateBlindCv,
};

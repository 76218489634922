import { Button, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Recruitment, RecruitmentStatus } from '../../services/gptRecruiterService';

interface RecruitmentsListElementProps {
  recruitment: Recruitment;
}

const RecruitmentsListElement = ({
  recruitment: { name, id, numberOfUploadedCvs, status },
}: RecruitmentsListElementProps) => {
  const { t } = useTranslation();
  const path = status === RecruitmentStatus.Opened ? id : `${id}/applications`;
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{numberOfUploadedCvs}</TableCell>
      <TableCell>{t(`recruitment.tableRecruitmentStatus.${status}`)}</TableCell>

      <TableCell align="right">
        <Button
          to={path}
          component={RouterLink}
          variant={status === RecruitmentStatus.Closed ? 'outlined' : 'contained'}
          color="secondary"
          size="small"
        >
          {t('manage')}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default RecruitmentsListElement;

/* eslint-disable no-unused-vars */
import { TextField, styled, IconButton, Tooltip, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../iconify';

const Question = ({
  questionType,
  question,
  handleDeleteQuestion,
  handleEditQuestion,
  isAddingNewQuestionDisabled,
}: {
  questionType: number;
  question: string;
  handleDeleteQuestion: (question: string, questionType: number) => Promise<void>;
  handleEditQuestion: (question: string, newQuestion: string, questionType: number) => Promise<void>;
  isAddingNewQuestionDisabled: boolean;
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newQuestion, setNewQuestion] = useState<string>(question);
  const { t } = useTranslation();

  return (
    <StyledBox>
      {isEditing ? (
        <TextField
          label={t('recruitment.editQuestion')}
          defaultValue={question}
          onChange={(event) => setNewQuestion(event.target.value)}
          sx={{ width: '80%' }}
        />
      ) : (
        <Typography>{question}</Typography>
      )}

      <Box sx={{ display: 'flex', gap: 2 }}>
        {isEditing && (
          <>
            <Tooltip title={t('edit')}>
              <IconButton
                onClick={() => {
                  handleEditQuestion(question, newQuestion, questionType);
                  setIsEditing(false);
                }}
              >
                <Iconify icon="material-symbols:check" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('undo')}>
              <IconButton onClick={() => setIsEditing(false)}>
                <Iconify icon="material-symbols:undo" />
              </IconButton>
            </Tooltip>
          </>
        )}

        {!isEditing && !isAddingNewQuestionDisabled && (
          <>
            <Tooltip title={t('recruitment.editQuestion')}>
              <IconButton onClick={() => setIsEditing(true)}>
                <Iconify icon="material-symbols:edit" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('recruitment.deleteQuestion')}>
              <IconButton onClick={() => handleDeleteQuestion(question, questionType)}>
                <Iconify icon="material-symbols:delete" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    </StyledBox>
  );
};

export default Question;

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
}));

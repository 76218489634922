/* eslint-disable no-unused-vars */
import i18n from '../i18n';

export enum SocialMediaPostPurpose {
  inform = 'Inform',
  promote = 'Promote a product or service',
  entertain = 'Entertain',
  inspire = 'Inspire',
  educational = 'Educational',
  lifestyle = 'Lifestyle',
  general = 'General'
}

export enum SocialMediaPostStyle {
  conciseAndInformative = 'Concise and Informative',
  funnyAndHumorous = 'Funny and Humorous',
  storytelling = 'Storytelling',
  NewFeatureOrProductAnnouncement = 'New Feature or Product Announcement',
  minimalistAndElegant = 'Minimalist and Elegant',
  behindTheScenes = 'Behind the Scenes',
  inspirationalAndMotivational = 'Inspirational and Motivational',
  educationalAndHowto = 'Educational and How-to',
  professional = 'Professional',
}

export enum SocialMediaPostSize {
  shortAndSnappy = 'Short and Snappy',
  mediumLength = 'Medium-Length',
  longAndInformative = 'Long and Informative',
  microContent = 'Micro-content',
  asYouThinkIsRequired = 'As you think is required',
}

export const SocialMediaPostStyleOptions = Object.entries(SocialMediaPostStyle).map(([key, value]) => ({
  value,
  label: i18n.t(`socialMediaPost.styles.${key}`),
}));

export const SocialMediaPostSizeOptions = Object.entries(SocialMediaPostSize).map(([key, value]) => ({
  value,
  label: i18n.t(`socialMediaPost.sizes.${key}`),
}));

export const SocialMediaPostPurposeOptions = Object.entries(SocialMediaPostPurpose).map(([key, value]) => ({
  value,
  label: i18n.t(`socialMediaPost.postPurposes.${key}`),
}));


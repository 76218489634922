import { Box, Button, Card, Divider, Grid, styled, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import PageTemplate from './PageTemplate';
import Iconify from '../components/iconify';
import gptRecruiterService, {
  RecruitmentStatus,
  Recruitment as RecruitmentType,
} from '../services/gptRecruiterService';
import RecruitmentQuestions, { QuestionTypes } from '../components/recruitment/RecruitmentQuestions';
import RecruitmentDocuments from '../components/recruitment/RecruitmentDocuments';
import RecruitmentStuatus from '../components/recruitment/SetRecruitmentStatus';
import RecruitmentOpenedAt from '../components/recruitment/RecruitmentOpenedAt';

export default function Recruitment() {
  const [recruitment, setRecruitment] = useState<RecruitmentType>();
  const { t } = useTranslation();
  const pageTitle = t('recruitments.title');
  const { recruitmentId } = useParams<{ recruitmentId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [question, setQuestion] = useState<string>('');
  const [binaryQuestion, setBinaryQuestion] = useState<string>('');
  const [isGoToApplicantsDisabled, setIsGoToApplicantsDisabled] = useState<boolean>(true);
  const [hasQuestions, setHasQuestions] = useState<boolean>(false);
  const [hasBinaryQuestions, setHasBinaryQuestions] = useState<boolean>(false);
  const path = useMemo(
    () =>
      recruitment?.status === RecruitmentStatus.Opened
        ? '/dashboard/gptrecruiter/recruitments'
        : `/dashboard/gptrecruiter/recruitments/${recruitmentId}/applications`,
    [recruitment]
  );
  const getRecruitment = async () => {
    try {
      if (!recruitmentId) return;
      const recruitment = await gptRecruiterService.getRecruitmentById(recruitmentId);
      setRecruitment(recruitment);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const handleAddQuestion = async (questionsType: number) => {
    try {
      const name = recruitment?.name;
      if (!recruitmentId || !name) return;
      if (questionsType === QuestionTypes.BinaryQuestion) {
        const questions = recruitment?.binaryQuestions;
        const newQuestions = questions?.length ? [binaryQuestion, ...questions] : [binaryQuestion];
        await gptRecruiterService.updateRecruitmentQuestions(recruitmentId, recruitment?.questions, newQuestions, name);
        setBinaryQuestion('');
      } else {
        const questions = recruitment?.questions;
        const newQuestions = questions?.length ? [question, ...questions] : [question];
        await gptRecruiterService.updateRecruitmentQuestions(
          recruitmentId,
          newQuestions,
          recruitment?.binaryQuestions,
          name
        );
        if (question.length > 0) {
          const lastChar = question[question.length - 1];
          if (lastChar !== '?') enqueueSnackbar({ variant: 'warning', message: t('recruitment.questionFormat') });
        }
        setQuestion('');
      }

      getRecruitment();

      enqueueSnackbar({ variant: 'success', message: t('recruitment.questionAdded') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const handleDeleteQuestion = async (question: string, questionsType: number) => {
    try {
      const questions =
        questionsType === QuestionTypes.BinaryQuestion ? recruitment?.binaryQuestions : recruitment?.questions;
      const name = recruitment?.name;

      if (!recruitmentId || !name || !questions?.length) return;

      const newQuestions = questions?.filter((q) => q !== question);
      if (questionsType === QuestionTypes.BinaryQuestion) {
        await gptRecruiterService.updateRecruitmentQuestions(recruitmentId, recruitment?.questions, newQuestions, name);
      } else {
        await gptRecruiterService.updateRecruitmentQuestions(
          recruitmentId,
          newQuestions,
          recruitment?.binaryQuestions,
          name
        );
      }

      getRecruitment();

      enqueueSnackbar({ variant: 'success', message: t('recruitment.questionDeleted') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const handleEditQuestion = async (question: string, newQuestion: string, questionsType: number) => {
    try {
      const questions =
        questionsType === QuestionTypes.BinaryQuestion ? recruitment?.binaryQuestions : recruitment?.questions;
      const name = recruitment?.name;

      if (!recruitmentId || !name || !questions?.length || !newQuestion.length) return;

      const newQuestions = questions?.map((q) => (q === question ? newQuestion : q));
      if (questionsType === QuestionTypes.BinaryQuestion) {
        await gptRecruiterService.updateRecruitmentQuestions(recruitmentId, recruitment?.questions, newQuestions, name);
      } else {
        await gptRecruiterService.updateRecruitmentQuestions(
          recruitmentId,
          newQuestions,
          recruitment?.binaryQuestions,
          name
        );
      }

      if (newQuestion.length > 0) {
        const lastChar = newQuestion[newQuestion.length - 1];
        if (lastChar !== '?') enqueueSnackbar({ variant: 'warning', message: t('recruitment.questionFormat') });
      }

      getRecruitment();

      enqueueSnackbar({ variant: 'success', message: t('recruitment.questionEdited') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const handleRecruitmentStatusChange = async (status: RecruitmentStatus) => {
    if (!recruitmentId) return;
    try {
      await gptRecruiterService.updateRecruitmentStatus(recruitmentId, status);

      getRecruitment();

      enqueueSnackbar({ variant: 'success', message: t('recruitment.statusChanged') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  useEffect(() => {
    getRecruitment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsGoToApplicantsDisabled(recruitment?.status === RecruitmentStatus.Opened);
    setHasQuestions(!!recruitment?.questions?.length);
    setHasBinaryQuestions(!!recruitment?.binaryQuestions?.length);
  }, [recruitment]);

  return (
    <PageTemplate pageTitle={pageTitle}>
      <>
        <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
          <Button
            component={RouterLink}
            to={path}
            variant="contained"
            sx={{ maxWidth: '150px', marginX: 3, marginLeft: 'auto' }}
          >
            <Iconify icon="bi:arrow-left" sx={{ marginRight: 1 }} />
            {t('back')}
          </Button>
          <Tooltip title={t('recruitment.enableApplicantsView')} disableHoverListener={!isGoToApplicantsDisabled}>
            <Box>
              <Button
                disabled={isGoToApplicantsDisabled}
                component={RouterLink}
                to={`/dashboard/gptrecruiter/recruitments/${recruitmentId}/applications`}
                variant="contained"
                sx={{ marginX: 3, marginLeft: 'auto' }}
              >
                <Iconify icon="bi:file-earmark-person" sx={{ marginRight: 1 }} />
                {t('recruitment.browseApplicants')}
              </Button>
            </Box>
          </Tooltip>
        </Box>
        <Container>
          <StyledCard>
            <StyledBoxContainerColumn>
              <DataRow title={t('recruitment.recruitment')} value={recruitment?.name} />
              <RecruitmentOpenedAt recruitment={recruitment} />
              {recruitment?.status !== RecruitmentStatus.Opened && (
                <RecruitmentStuatus
                  recruitment={recruitment}
                  handleRecruitmentStatusChange={handleRecruitmentStatusChange}
                />
              )}
            </StyledBoxContainerColumn>
            <Grid container>
              <Grid item md={5.5} xs={12} padding={1}>
                <RecruitmentQuestions
                  questionType={QuestionTypes.NormalQuestion}
                  handleAddQuestion={handleAddQuestion}
                  question={question}
                  recruitment={recruitment}
                  setQuestion={setQuestion}
                  handleDeleteQuestion={handleDeleteQuestion}
                  handleEditQuestion={handleEditQuestion}
                />
              </Grid>
              <Grid item md={1} xs={0} container display={'flex'} justifyContent={'center'}>
                <Divider orientation={'vertical'} />
              </Grid>
              <Grid item md={5.5} xs={12} padding={1}>
                <RecruitmentQuestions
                  questionType={QuestionTypes.BinaryQuestion}
                  handleAddQuestion={handleAddQuestion}
                  question={binaryQuestion}
                  recruitment={recruitment}
                  setQuestion={setBinaryQuestion}
                  handleDeleteQuestion={handleDeleteQuestion}
                  handleEditQuestion={handleEditQuestion}
                />
              </Grid>
            </Grid>
          </StyledCard>
          {isGoToApplicantsDisabled && (
            <StyledCard>
              <Box>
                <Typography variant="h3">{t('recruitment.recruitmentCv')}</Typography>
                <SubHeading>{t('recruitment.uploadCv')}</SubHeading>

                <RecruitmentDocuments
                  recruitmentId={recruitmentId}
                  onFileUploaded={() => setIsGoToApplicantsDisabled(false)}
                  disabled={
                    (!hasQuestions && !hasBinaryQuestions && recruitment?.status !== RecruitmentStatus.InProgress) ||
                    recruitment?.status === RecruitmentStatus.Closed
                  }
                />
              </Box>
            </StyledCard>
          )}
        </Container>
      </>
    </PageTemplate>
  );
}

const DataRow = ({ title, value }: { title: string; value: string | undefined }) => (
  <Typography sx={{ fontSize: 20 }} gutterBottom>
    {title}:{' '}
    <Typography variant="h4" component="span" sx={{ fontWeight: 'bold' }}>
      {value ?? '-'}
    </Typography>
  </Typography>
);

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));

const StyledBoxContainerColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  justifyContent: 'center',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

import { Card, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import BlindsOrganizationList from '../components/recruitment/BlindCvList/BlindsOrganizationList';
import PageTemplate from './PageTemplate';

import blindCvService, { BlindCvListResponse } from '../services/blindCvService';
import BlindCvListHeading from '../components/recruitment/BlindCvList/BlindCvListHeading';



export default function BlindCvOrganizationList() {
  const [isPending, setIsPending] = useState(false);
  const [blindCvs, setBlindCvs] = useState<Array<BlindCvListResponse>>([]);
  const { t } = useTranslation();
  const pageTitle = t('recruitments.title');
  const { enqueueSnackbar } = useSnackbar();


  const getOrganizationBlindCvs = async () => {
    setIsPending(true);
    try {
      const blinds = await blindCvService.getOrganizationBlindCvs();
      setBlindCvs(blinds);
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    getOrganizationBlindCvs();
  }, []);

  return (
    <PageTemplate pageTitle={pageTitle}>
        <Container>
        <BlindCvListHeading />
          <BlindsOrganizationList blindCVs={blindCvs} isPending={isPending} />
        </Container>
    </PageTemplate>
  );
}

const Container = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(5),
  padding: theme.spacing(3),
}));

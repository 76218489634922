import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { CommentsList } from './CommentsList';
import gptRecruiterService, { Comment } from '../../../services/gptRecruiterService';
import Iconify from '../../iconify';

interface CommentsProprs {
  recruitmentId: string | undefined;
  candidateId: string | undefined;
}

const Comments = ({ recruitmentId, candidateId }: CommentsProprs) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [comments, setComments] = useState<Array<Comment>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm<{ comment: string }>({
    defaultValues: {
      comment: '',
    },
  });
  const { handleSubmit, reset, register } = form;
  const getComments = async () => {
    if (!recruitmentId || !candidateId) return;
    setIsLoading(true);
    try {
      const response = await gptRecruiterService.getComments({ recruitmentId, candidateId });
      setComments(response.comments);
    } catch (err) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      setIsLoading(false);
    }
  };

  const addComment = async (data: { comment: string }) => {
    if (!recruitmentId || !candidateId) return;
    setIsLoading(true);
    try {
      await gptRecruiterService.addComment({ recruitmentId, candidateId, comment: data.comment });
      enqueueSnackbar({ variant: 'success', message: t('candidatePanel.commentSaved') });
      reset();
    } catch (err) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    } finally {
      getComments();
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <form {...form} onSubmit={handleSubmit(addComment)} style={{ height: '100%', maxHeight: 380 }}>
      <CommentsWrapper>
        <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography fontWeight={600} fontSize={'1.5rem'}>
            {t('candidatePanel.comments')}
          </Typography>
          <Divider sx={{ width: '100%' }} />
        </Box>

        {isLoading ? (
          <SpinnerContainer>
            <CircularProgress sx={{ margin: 'auto' }} />
          </SpinnerContainer>
        ) : (
          <CommentsList comments={comments} />
        )}

        <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Divider sx={{ width: '100%', mb: theme.spacing(1) }} />
          <TextField
            {...register('comment', { required: true })}
            fullWidth
            variant="outlined"
            placeholder={`${t('candidatePanel.addAComment')}`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ marginRight: 1 }}>
                  <IconButton edge="end" onClick={handleSubmit(addComment)} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} /> : <Iconify icon="mdi:send" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </CommentsWrapper>
    </form>
  );
};

export default Comments;

const SpinnerContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const CommentsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

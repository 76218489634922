import { styled, Box, Button, CircularProgress, Typography, LinearProgress } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
// import gptRecruiterService from '../../services/gptRecruiterService';

interface RecruitmentDocumentsProps {
    setSelectedFile: (file: FileToUploadProps | null) => void;
    selectedFile: FileToUploadProps | null;
}

interface FileToUploadProps {
    file: File;
    uploadingStatus: 'Queued' | 'Uploading' | 'Uploaded' | 'Error';
}


const UploadCvDoc = ({ setSelectedFile, selectedFile }: RecruitmentDocumentsProps) => {
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const addAcceptedFiles = { file: acceptedFiles[0], uploadingStatus: 'Queued' } as FileToUploadProps;

        setSelectedFile(addAcceptedFiles);
    }, []);


    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: { "docs/": ['.doc', '.docx', '.pdf', '.txt'] },
        onDrop,
    });

    const fileUploadPending = selectedFile?.uploadingStatus === 'Uploading' ?? false;

    const handleDeleteFile = () => {
        setSelectedFile(null);
    };

    if (selectedFile) {
        return (
            <Wrapper>
                <FilesWrapper>
                    {!!selectedFile &&
                        <StyledFile>
                            <Typography style={{overflow: 'hidden'}}>{selectedFile?.file?.name}</Typography>
                            <div >
                                <Button variant="outlined" color="error" size="small" onClick={() => handleDeleteFile()}>
                                    {t('recruitment.removeFile')}
                                </Button>
                            </div>
                        </StyledFile>
                    }
                </FilesWrapper>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <UploadFileSection {...getRootProps()}>
                <input {...getInputProps()} multiple={false} />
                <Button style={{ width: '100%' }} variant="outlined" disabled={fileUploadPending}>
                    <WrapperSmall>
                        <Typography variant="h3">+</Typography>
                        {isDragActive ? t('blindCvGenerator.dropCvHere') : t('blindCvGenerator.uploadCV')}
                    </WrapperSmall>
                </Button>

            </UploadFileSection>
        </Wrapper>
    )

};

export default UploadCvDoc;

const UploadFileSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    minHeight: '10em',
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
}));

const Wrapper = styled(Box)(({ theme }) => ({
    minHeight: '10em',
    display: 'flex',
    flex: '0 0 100%',
    flexDirection: 'column',
    gap: theme.spacing(3),
}));

const WrapperSmall = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const FilesWrapper = styled(Box)(({ theme }) => ({
    height: '100%',
    minHeight: '10em',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
    alignItems: 'center',
}));

const StyledFile = styled(Box)(({ theme }) => ({
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
}));

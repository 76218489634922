import { FormControl, InputLabel, Select, FormHelperText, MenuItem } from '@mui/material';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { PositionDetails } from '../../../../services/blindCvService';
import { languageForm } from '../BlindGeneratorForm';

type SelectOption = {
  value: PositionDetails;
  label: string;
};

type SelectComponentProps = {
  name: keyof languageForm;
  control: Control<languageForm>;
  label: string;
  options: SelectOption[];
  defaultValue?: string;
  error?: DeepMap<Record<string, any>, FieldError>;
};

const PositionDetailsSelect = ({ name, control, label, options, defaultValue, error }: SelectComponentProps) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field }) => (
      <FormControl variant="outlined" error={!!error} sx={{ minWidth: '30%' }}>
        <InputLabel>{label}</InputLabel>
        <Select {...field} label={label} defaultValue={defaultValue}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    )}
  />
);

export default PositionDetailsSelect;

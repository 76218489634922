import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import organizationService, { User } from '../../services/organizationService';
import contextService from '../../services/contextService';

interface AssignUserFormProps {
  id: string | undefined;
}

const AssignUserForm = ({ id }: AssignUserFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [users, setUsers] = useState<Array<User>>([]);
  const [usersToAssign, setUsersToAssign] = useState<Array<string>>([]);

  useEffect(() => {
    const handleGetUsers = async () => {
      if (!id) return;
      try {
        const { users } = await organizationService.getOrganizationUsers();
        setUsers(users);
      } catch (error) {
        enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      }
    };
    handleGetUsers();
  }, [enqueueSnackbar, id, t]);

  useEffect(() => {
    const handleGetAssignedUsers = async () => {
      if (!id) return;
      try {
        const response = await contextService.getContextUsers({ id });
        const nextUsersToAssign = response.map(({ id }) => id);
        setUsersToAssign(nextUsersToAssign);
      } catch (error) {
        enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
      }
    };
    handleGetAssignedUsers();
  }, [enqueueSnackbar, id, t]);

  const handleChange = (event: SelectChangeEvent<Array<string>>) => {
    const { value } = event.target;
    const nextUsersToAssign = typeof value === 'string' ? [value] : value;

    setUsersToAssign(nextUsersToAssign);
    onSubmit(nextUsersToAssign);
  };

  const onSubmit = async (data: string[]) => {
    // TODO: Refactor this, make it more generic and reusable
    if (!id) return;

    try {
      const promisesAssign = data.map((userId) => {
        const isAllreadyAssigned = usersToAssign.find((id) => id === userId);
        if (isAllreadyAssigned) return null;
        return contextService.assignUser({ contextId: id, userId });
      });

      const promisesDelete = usersToAssign.map((userId) => {
        const isToUnassign = !data.includes(userId);
        if (isToUnassign) return contextService.unassignUser({ contextId: id, userId });
        return null;
      });

      await Promise.resolve([promisesAssign, promisesDelete]);
      enqueueSnackbar({ variant: 'success', message: t('context.editContextSuccess') });
    } catch (error) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>{t('context.assignedUsers')}</InputLabel>
        <Select
          value={usersToAssign}
          onChange={handleChange}
          multiple
          renderValue={(selected: any) =>
            (selected as string[]).map((value) => users.find((user) => user.id === value)?.email).join(', ')
          }
          label={t('context.assignedUsers')}
        >
          {users?.map(({ email, id }) => (
            <MenuItem key={id} value={id}>
              {email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default AssignUserForm;

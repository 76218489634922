import { Box, TextField, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Answer } from '../../../services/gptRecruiterService';
import { EditAnswersProps } from './Summary';

interface AnswersProps {
  answers: Answer[];
  isEditing: boolean;
  updateAnswers: (props: EditAnswersProps) => void;
}
const Answers = ({ answers, isEditing, updateAnswers }: AnswersProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [newAnswears, setNewAnswears] = useState(answers);
  const handleChangeAnswer = (q: string, a: string) => {
    const updatedAnswers = newAnswears.map(({ question, answer }) =>
      question === q ? { question, answer: a } : { question, answer }
    );
    setNewAnswears(updatedAnswers);
  };

  return (
    <>
      <Typography
        fontWeight={600}
        fontSize={'1.5rem'}
        padding={theme.spacing(1)}
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {t('recruitment.questions')}
      </Typography>
      {newAnswears.map(({ question, answer }, index) => (
        <Box key={index} width={'100%'} mb={theme.spacing(2)}>
          <Typography fontWeight={600} sx={{ marginBottom: 1 }}>
            {question}
          </Typography>

          {isEditing ? (
            <TextField
              fullWidth
              defaultValue={answer}
              onBlur={() => updateAnswers({ answers: newAnswears })}
              onChange={(e) => handleChangeAnswer(question, e.target.value)}
            />
          ) : (
            <Typography fontWeight={500} color={theme.palette.grey[600]}>
              {answer}
            </Typography>
          )}
        </Box>
      ))}
    </>
  );
};

export default Answers;

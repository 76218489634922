/* eslint-disable no-unused-vars */
import { Box, Button, FormControl, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Iconify from '../../iconify';
import gptRecruiterService, { Recruitment, RecruitmentMetadata } from '../../../services/gptRecruiterService';
import RecruitmentsSelect from './RecruitmentsSelect';

interface ApplicantCardActionsProps {
    applicantRecrutations: Array<RecruitmentMetadata>;
    organizationReceruitments: Array<Recruitment>;
    applicantCvEmbeddedId: string;
}

const ApplicantCardActions = ({ organizationReceruitments, applicantRecrutations, applicantCvEmbeddedId }: ApplicantCardActionsProps) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();


    const handleDownloadCv = async () => {
        const cvId = applicantRecrutations[0]?.cvDataId
        const recruitmentId = applicantRecrutations[0]?.recruitmentId
        if (!recruitmentId || !cvId) return;
        try {
            const openPdf = (fileURL: string) => {
                window.open(fileURL, '_blank');
            };

            const downloadFile = (fileURL: string) => {
                const anchorElement = document.createElement('a');

                document.body.appendChild(anchorElement);
                anchorElement.style.display = 'none';
                anchorElement.href = fileURL;
                anchorElement.download = response.fileName;
                anchorElement.click();

                window.URL.revokeObjectURL(fileURL);
            };

            const response = await gptRecruiterService.downloadCvById({ recruitmentId, cvId });

            const blob = new Blob([response.binaryData], { type: response.fileType });
            const fileURL = URL.createObjectURL(blob);

            if (response.fileType === 'application/pdf') {
                openPdf(fileURL);
            }
            else {
                downloadFile(fileURL);
            }

        } catch (error) {
            enqueueSnackbar(t('recruitment.downloadCvError'), { variant: 'error' });
        }
    };

    return (
        <ButtonWrapper>
            <ApplicantStatusButtonsWrapper>
                <FormControl fullWidth size="small" sx={{ display: 'flex', width: '100%' }}>
                    <RecruitmentsSelect
                        organizationReceruitments={organizationReceruitments}
                        applicantRecrutations={applicantRecrutations}
                        applicantCvEmbeddedId={applicantCvEmbeddedId}
                    />
                </FormControl>
            </ApplicantStatusButtonsWrapper>
            <Box>

                <Button variant="outlined" onClick={handleDownloadCv}>
                    <Iconify icon="bi:download" sx={{ marginRight: 1 }} />
                    {t('downloadCv')}
                </Button>
            </Box>
        </ButtonWrapper>
    );
};

export default ApplicantCardActions;


const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(2),
    },
}));

const ApplicantStatusButtonsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    width: '100%',
    overflow: 'wrap',
    justifySelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));
import { Box, Divider, Grid, IconButton, styled, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import gptRecruiterService, { Answer, Applicant, TrinaryAnswer } from '../../../services/gptRecruiterService';
import Note from './Note';
import Answers from './Answers';
import TrinaryAnswers from './TrinaryAnswers';
import Comments from './Comments';
import Iconify from '../../iconify';

export interface EditAnswersProps {
  answers: Array<Answer>;
}

export interface EditTrinaryAnswersProps {
  question: string;
  newType: boolean | null;
}
interface SummaryProps {
  candidate: Applicant;
  setCandidate: (candidate: Applicant) => void;
  recruitmentId: string | undefined;
  candidateId: string | undefined;
}

export const Summary = ({ candidate, setCandidate, recruitmentId, candidateId }: SummaryProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const updateTrinaryAnswers = async ({ question, newType }: EditTrinaryAnswersProps) => {
    if (!recruitmentId || !candidateId) {
      return;
    }
    try {
      const updatedTrinaryAnswers = candidate.trinaryAnswers!.map((trinaryAnswer: TrinaryAnswer) =>
        trinaryAnswer.question === question ? { ...trinaryAnswer, answer: newType } : trinaryAnswer
      );
      await gptRecruiterService.updateCandidateData({
        recruitmentId,
        cvId: candidateId,
        applicantName: candidate.applicantName || '',
        applicantSurname: candidate.applicantSurname || '',
        applicantContact: candidate.applicantContact || '',
        answers: candidate.answers || [],
        trinaryAnswers: updatedTrinaryAnswers,
      });
      setCandidate({ ...candidate, trinaryAnswers: updatedTrinaryAnswers });
      enqueueSnackbar({ variant: 'success', message: t('candidatePanel.answerUpdated') });
    } catch (e) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  const updateAnswers = async ({ answers }: EditAnswersProps) => {
    if (!recruitmentId || !candidateId) {
      return;
    }
    try {
      const updatedAnswers = answers || candidate.answers || [];
      await gptRecruiterService.updateCandidateData({
        recruitmentId,
        cvId: candidateId,
        applicantName: candidate.applicantName || '',
        applicantSurname: candidate.applicantSurname || '',
        applicantContact: candidate.applicantContact || '',
        answers: updatedAnswers,
        trinaryAnswers: candidate.trinaryAnswers || [],
      });
      setCandidate({ ...candidate, answers: updatedAnswers });
      enqueueSnackbar({ variant: 'success', message: t('candidatePanel.answerUpdated') });
    } catch (e) {
      enqueueSnackbar({ variant: 'error', message: t('somethingWentWrong') });
    }
  };

  return (
    <SectionWrapper>
      <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
        <Tooltip title={t('edit')}>
          <IconButton onClick={() => setIsEditing(!isEditing)}>
            <Iconify icon="material-symbols:edit" />
          </IconButton>
        </Tooltip>
      </Box>
      {candidate.trinaryAnswers?.length && (
        <TrinaryAnswers
          trinaryAnswers={candidate.trinaryAnswers}
          isEditing={isEditing}
          updateTrinaryAnswers={updateTrinaryAnswers}
        />
      )}
      <Divider sx={{ width: '100%' }} />
      {candidate.answers?.length && (
        <Answers answers={candidate.answers} isEditing={isEditing} updateAnswers={updateAnswers} />
      )}
      <Divider sx={{ width: '100%' }} />
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Note recruitmentId={recruitmentId} candidateId={candidateId} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Comments recruitmentId={recruitmentId} candidateId={candidateId} />
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};
const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '100%',
}));

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatRoles, ChatResponse } from '../services/chatService';
import useModal from './useModal';

const usePrivateChat = () => {
  const { t } = useTranslation();
  const [chatSessionId, setChatSessionId] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [selectedChatIdentity, setSelectedChatIdentity] = useState<string>('');
  const [chatMessages, setChatMessages] = useState<ChatResponse[]>(new Array({role: ChatRoles.SYSTEM, message: t('privateChat.initialMessage')} as ChatResponse));
  const [isPromptsModalOpen, togglePromptsModal] = useModal();
  const [isChatHistoryModalOpen, toggleChatHistoryModal] = useModal();

  const resetChat = () => {
    setChatSessionId('');
    setChatMessages(new Array({role: ChatRoles.SYSTEM, message: t('privateChat.initialMessage')} as ChatResponse));
    setSelectedChatIdentity('');
  };

  return {
    chatSessionId,
    setChatSessionId,
    isPending,
    setIsPending,
    chatMessages,
    setChatMessages,
    resetChat,
    setSelectedChatIdentity,
    selectedChatIdentity,
    isPromptsModalOpen,
    togglePromptsModal,
    isChatHistoryModalOpen,
    toggleChatHistoryModal,
  };
};

export default usePrivateChat;

import { Typography } from '@mui/material';

export const SkillsSectionComponent = ({ array }: { array: string[] }) => (
  <>
    {array.map((item, index) => (
      <Typography key={index} variant="body1" component="div">
        <li>{item}</li>
      </Typography>
    ))}
  </>
);
